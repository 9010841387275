import React, { Fragment, useState, useEffect } from 'react';
import * as _ from 'lodash';
import { Steps, Form, Typography, Card, notification, Row, Col, message, Dropdown } from 'antd';

import { GetStartedCss, greetingCard, GetStartedLayoutCss, exitDropdown } from './GetStarted.style';
import { ArrowLeftOutlined, CheckCircleFilled } from '@ant-design/icons';
import LegalEntityForm from './LegalEntityForm';
import { useHistory } from 'react-router-dom';
import {
  Personas,
  PARTNER_EXISTS_409,
  BROKER_USER_TIN_CONFLICT_409,
  INVALID_USER_DETAILS_404,
  SOMETHING_WENT_WRONG,
  CANCEL_MODAL_DESCRIPTION,
} from './../../common/constants/AppConstants';
import { getApiList, registerPartner } from './../../services/registration.service';
import ApiSelection from './ApiSelection';
import ModalWrapper from './../../components/ModalWrapper/ModalWrapper';
import WarningNotification from 'Client/components/WarningNotification/WarningNotification';
import Overview from './Overview';
import { array } from 'prop-types';
import PartnerTypeSelection from './PartnerTypeSelection';
import { footerSection } from '../RecommendedApiList/QuestionCard.style';
import Button from 'Client/components/Buttons/Button';
import { getPublicSiteImageSrc } from 'Client/common/utils/AppUtils';
import { showError } from 'Client/components/ErrorModal/ErrorModal';

const { Step } = Steps;

const GetStarted = (props) => {
  const history = useHistory();
  const [resetFlag, setResetFlag] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [openExitDropdown, setOpenExitDropdown] = useState(false);
  const [legalInfoForm] = Form.useForm();
  const [selectApiForm] = Form.useForm();
  const [apiList, setApiList] = useState({ status: 'pending', data: [], error: null });
  let {partnerType = '', preselectApiFlow = false } = history?.location?.state?.preselectApiFlow ? history.location.state : {};
  const [personaType , setPersonaType] = useState(null);
  const legalEntityInitialValue = {
    type: partnerType != null && partnerType != '' ? partnerType : Personas.BROKER,
  };
  const { registrationFlow = 'getStarted', persona = {} } = history?.location?.state || {};
  const [apiFormData, setApiFormData] = useState([]);
  const [ currentPage, setCurrentPage ] = useState(0);
  const agencysAPIArray = [];
  const commercialAPIArray = [];

  // if(props?.registrationFlow) {
  //   registrationFlow = props?.registrationFlow;
  // } else if (history?.location?.state?.registrationFlow) {
  //   registrationFlow = history?.location?.state?.registrationFlow;
  // }

  // reform this code
  const agencyList = [
    'COMM_API',
    'BOB_API', 
    'APP_DETAILS_API',
    'OPTY_MGMT_API',
    'BENEFIT_DETAILS_API',
    'BROKER_NEWS_FEED_API',
    'RENEWAL_API',
  ];
  const commercialList = [
    'APP_SUBMISSION_API',
    'APP_STATUS_API',
    'DOC_UPLOAD_API',
    'GRP_STRUCT_API',
    'MBR_ENROLL_API',
    'MBR_MNTNC_API',
    'RENEW_PKG_API',
    'GEN_UPLOAD_API',
    'QUOTE_API',
    'CENSUS_API',
    'RENEWAL_API',
  ];

  apiFormData.map(api => {
    const apiId = api.apiId;
    if (agencyList.includes(apiId)) {
      agencysAPIArray.push(api.apiName);
    }
    if (commercialList.includes(apiId)) {
      commercialAPIArray.push(api.apiName);
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getAPIList();
  }, []);

  const getAPIList = () => {
    getApiList()
      .then(res => {
       
        if(registrationFlow == "qna") {
          const { interestedLOBs, interestedApis } = persona;
          
          // Rank APIs based on businessCase and useCase
          const rankedData = res?.data?.results?.data.map(api => {
            let rank = 0;

            // Increase rank if businessCase matches interestedLOBs
            if (interestedLOBs.includes(api.businessCase)) {
              rank += 1;
            }

            // Increase rank if useCase matches interestedApis
            if (api.useCase?.some(useCase => interestedApis?.includes(useCase))) {
              rank += 1;
            }

            return { ...api, rank };
          });
          setApiList({ data: rankedData, status: 'success', error: null });
       } else {
        setApiList({ data: res?.data?.results?.data, status: 'success', error: null });
       }
      })
      .catch(err => {
        setApiList({ data: [], status: 'error', error: err });
        if(registrationFlow == "qna" || registrationFlow == "preselectApis") {
          showError({err: err, backToHome: backToHome});
        }
      });
  };

  const getLegalEntityInitialValue = () => {
    return {
      type: getPartnerType(),
      };
   }

  const buildRegistrationRequest = (legalEntityData, selectApiFormData) => {
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const personaObj = _.omit(persona, ['allApis']);

    return {
      companyName: legalEntityData?.companyName,
      // agreementAccepted: true,
      persona: {
        type: getPartnerType(),
        bpUserId: legalEntityData.type === Personas.BROKER ? legalEntityData?.bpUserId || undefined : undefined,
        bpTaxId: legalEntityData.type === Personas.BROKER ? legalEntityData?.bpTaxId || undefined : undefined,
        firstName: legalEntityData?.firstName,
        lastName: legalEntityData?.lastName,
        emailAddress: legalEntityData?.email,
        phoneNumber: legalEntityData?.phoneNumber?.replace(phoneRegex, '$1$2$3'),
        address1: legalEntityData?.addressLine1,
        address2: legalEntityData?.addressLine2,
        zipCode: legalEntityData?.zipCode,
        city: legalEntityData?.city,
        state: legalEntityData?.state,
        ...personaObj
      },
      apis: selectApiFormData?.map(api => {
        return { apiId: api?.apiId };
      }),
    };
  };

  const finalSubmission = selectApiFormData => {
    registerPartner(buildRegistrationRequest(legalInfoForm?.getFieldValue(), selectApiFormData))
      .then(res => {
        if (res?.data?.status == 'SUCCESS') {
          history.push('/register/registered', { update: legalInfoForm?.getFieldValue().email });
        } else {
          triggerErrorNotification('Registration', SOMETHING_WENT_WRONG);
        }
      })
      .catch(err => {
        handleException(err);
      });
  };

  const backToHome = () => {
    history.push('/');
  }

  const handleException = err => {
    if (err?.response?.status === 409) {
      if (
        'partner_exists_409' === err?.response?.data?.data?.code ||
        'email_already_exists_409' === err?.response?.data?.data?.code
      ) {
        triggerErrorNotification('Registration', PARTNER_EXISTS_409);
      } else if ('broker_user_tin_conflict_409' === err?.response?.data?.data?.code) {
        triggerErrorNotification('Registration', BROKER_USER_TIN_CONFLICT_409);
      }
    } else if (err?.response?.status === 404) {
      if ('broker_not_found_404' === err?.response?.data?.data?.code) {
        triggerErrorNotification('Registration', INVALID_USER_DETAILS_404);
      }
    } else {
      triggerErrorNotification('Registration', SOMETHING_WENT_WRONG);
    }
  };

  const triggerErrorNotification = (message, description) => {
    document.getElementById('registration').scrollIntoView({ behavior: 'smooth', block: 'start' });
    notification.error({
      message,
      description,
      style: {
        width: '60vw',
      },
    });
  };

  const onClickExitDropdownItem = (action) => {
    setOpenExitDropdown(false);
    if(action === 'yes') {
      history.push('/explore');
    } 
  }

  const modalOnCancelButtonHandler = () => {
    setResetFlag(false);
  };

  const openWarningModal = () => {
    legalInfoForm?.isFieldsTouched() ? history.push('/explore') : setResetFlag(true);
  };

  const modalOnOkButtonHandler = () => {
    history.push('/explore');
  };

  const onFinish = () => {
    const formData = legalInfoForm?.getFieldsValue();
    if (validateForm(formData, legalInfoForm.getFieldValue('type'))) {
      window.scrollTo(0, 0);
      setCurrentStep(2);
    }
  };

  const selectPersona = event => {
    const { target } = event;
    setPersonaType(target.value);
  };

  const getPartnerType = () => {
    let partner;
    if (preselectApiFlow) {
      partner =  partnerType?.toUpperCase() ??  Personas.BROKER;
    } else if (registrationFlow == "getStarted") {
      partner = personaType?.toUpperCase() ?? Personas.BROKER;
    } else if (registrationFlow == "qna") {
      partner = persona?.type?.toUpperCase() ?? Personas.BROKER;
    } else {
      partner = legalInfoForm.getFieldValue('type') || Personas.BROKER;
    }
    return partner;
  }

  const validateForm = (values, type) => {
    if (type === Personas.BROKER) {
      if (values?.bpUserId || values?.bpTaxId) {
        return true;
      } else {
        notification.error({
          message: 'LegalEntity',
          description: 'Please enter your Producer Toolbox Username / Tax ID',
        });
        return false;
      }
    }
    return true;
  };

  const handleNextPage = () => {
    if (personaType) {
      setCurrentPage(1);
    } else {
      message.error('Please select Partner Type');
    }
  }

  const onSelectApis = values => {
    const data = [
      ...values?.selectApi,
      ...(values?.selectApiTwo || [])
    ] || [];
    if (validateApiForm(data)) {
      const selectedApi = data?.filter(api => api?.isSelected);
      setApiFormData(selectedApi);
      setCurrentStep(1);
      window.scrollTo(0, 0);
    } else {
      notification.error({
        message: 'Select API',
        description: 'Please select atleast 1 API before submitting the request form',
        style: {
          width: '60vw',
        },
      });
    }
  };

  const goToApiSelection = () => {
    setCurrentStep(0);
  }

  const validateApiForm = data => {
    return data?.some(api => api?.isSelected);
  };

  const showErrorModal = () => {
    showError({backToHome: backToHome});
  }

  const goToPreviousScreen = () => {
    if (registrationFlow == "getStarted") {
      setCurrentPage(0);
    } else if (registrationFlow == 'qna') {
      history.push('/register/guided-flow');
    } 
    else {
      history.push('/explore')
    }
  }

  const getStepIcon = (step) => {
    let iconSrc;
    if (currentStep == step) {
      iconSrc = getPublicSiteImageSrc("empty_blue_circle.svg");
    } else if (currentStep > step) {
      iconSrc = getPublicSiteImageSrc("checked_circle.svg");
    } else {
      iconSrc = getPublicSiteImageSrc("empty_circle.svg");
    }
  
    return (
      <img
        alt="current step"
        src={iconSrc}
      />
    );
  }

  return (
    <Fragment>
      {
        (registrationFlow == "getStarted" && currentPage == 0) && (
          <section id="registration" css={GetStartedLayoutCss}>
            <section id="main-section" css={GetStartedCss}>
              <PartnerTypeSelection
                handleRadioSelection={selectPersona}
                partnerType={personaType}
                handleNext={handleNextPage}
              />
            </section>
          </section>
        )
      }
      {
        (currentPage == 1 || (currentPage == 0 && registrationFlow != "getStarted")) &&
        <section id="registration" css={GetStartedLayoutCss}>
          <section id="main-section" css={GetStartedCss}>
            <Row 
              gutter={[
                { xs: 8, sm: 16, md: 24, lg: 32 },
                { xs: 8, sm: 16, md: 24, lg: 32 }
              ]}
              className="responsive-row"
              justify="space-between" 
            >
              <Col xs={22} sm={22} md={15} className="responsive-col">
                <Steps 
                  size="small" 
                  current={currentStep} 
                  labelPlacement="vertical" 
                  className="progress" 
                  responsive={false}
                  items={[
                    {
                      title: 'Select API',
                      icon: getStepIcon(0)
                    },
                    {
                      title: 'Legal Entity',
                      icon: getStepIcon(1)
                    },
                    {
                      title: 'Review and Confirm',
                      icon: getStepIcon(2)
                    }
                  ]}
                />
              </Col>
              <Col xs={24} sm={24} md={5} className="align-right responsive-col exitButton-col">
                <Dropdown 
                  placement="bottom" 
                  arrow={{ pointAtCenter: true }}
                  trigger={['click']}
                  open={openExitDropdown}
                  dropdownRender={(menu) => {
                    return (
                      <Card className="exit-dropdown-card" css={exitDropdown}>
                        <Row gutter={[16,24]} justify="center">
                          <Col span={24} className="exit-dropdown-title-col">
                            <Typography className="exit-dropdown-title">
                              Do you want to Exit from the Registration?
                            </Typography>
                          </Col>
                          <Col span={18}>
                            <Button type="app-primary"
                             onClick={ () => onClickExitDropdownItem('yes')}
                             className="exit-dropdown-button exit-dropdown-button-primary"
                            >
                              Yes
                            </Button>
                          </Col>
                          <Col span={18}>
                            <Button
                              type="app-secondary" 
                              onClick={ () => onClickExitDropdownItem('no')}
                              className="exit-dropdown-button"
                            >
                              No
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                    )
                  }}
                >
                  <Typography className="exitButton" onClick={() => setOpenExitDropdown((true))}>
                    <a>Exit Registration</a>
                  </Typography>
                </Dropdown>
                {/* <Typography onClick={openWarningModal} className="exitButton">
                  <a>Exit Registration</a>
                </Typography> */}
              </Col>
            </Row>
            <Card bordered={false} className="registration__card">
              <div>
              {
                currentStep === 0 && (
                  <ApiSelection
                      selectApiForm={selectApiForm}
                      apiList={apiList}
                      partnerType={ getPartnerType() }
                      onSelectApis={onSelectApis}
                      setCurrentPage={setCurrentPage}
                      preselectApiFlow={preselectApiFlow}
                      goToPreviousScreen={goToPreviousScreen}
                      registrationFlow={registrationFlow}
                      persona={persona}
                      showError={showErrorModal}
                    />
                )}
                {
                  currentStep === 1 && (
                  <Card
                    bordered={false}
                    style={{ display: currentStep === 1 ? 'block' : 'none' }}
                    title={
                      <Typography.Title level={3} className="legal__heading">
                        We just need a few more details to finish setting things up for you.
                      </Typography.Title>
                    }
                  >
                    <Form
                      name="getStartedForm"
                      form={legalInfoForm}
                      initialValues={getLegalEntityInitialValue()}
                      layout="vertical"
                      onFinish={onFinish}
                      hideRequiredMark={true}
                      scrollToFirstError
                    >
                      <LegalEntityForm 
                        legalInfoForm={legalInfoForm}
                        selectApiForm={selectApiForm}
                        personaType={getPartnerType()}
                        goToApiSelection={goToApiSelection}
                      />
                    </Form>
                  </Card>
                )}
                {currentStep === 2 && (
                  <Overview
                    agency={agencysAPIArray}
                    commercial={commercialAPIArray}
                    initialValues={legalInfoForm?.getFieldValue()}
                    apiFormDataLength={apiFormData?.length}
                    backToApiSelection={() => {
                      setCurrentStep(0);
                    }}
                    backToLegalEntity={() => {
                      setCurrentStep(1);
                    }}
                    finalSubmission={finalSubmission}
                    selectApiForm={apiFormData}
                  ></Overview>
                )}
                {currentStep === 3 && <Form form={selectApiForm}></Form>}
              </div>
            </Card>
          </section>
          {
            (currentPage == 0 && registrationFlow == "getStarted"  ) &&
            <div css={footerSection} className="getstarted-footer">
              <Card bordered={false} className="nextButton-card">
                  <Row gutter={16}>
                    <Col span={12}>
                      <Button  type="app-secondary" className="nextButton" onClick={() => goToPreviousScreen()}>
                        Previous
                      </Button>
                    </Col>
                  </Row>
              </Card>
              
            </div>
            
          }
          <ModalWrapper visible={resetFlag}>
            <WarningNotification
              okBtnText={'Yes'}
              cancelBtnText={'Cancel'}
              cancelButtonHandler={modalOnCancelButtonHandler}
              okButtonHandler={modalOnOkButtonHandler}
              description={CANCEL_MODAL_DESCRIPTION}
            />
          </ModalWrapper>
        </section>
      }
    </Fragment>
  );
};

export default GetStarted;

Overview.propTypes = {
  agencysAPIArray: array,
  commercialAPIArray: array,
};
