import { css } from '@emotion/core';
export const OverviewCss = css`
  @media(max-width: 768px) {
    padding: 1.25rem;

    .agencyCard {
      padding: 5px;
    }

    .commercialCard {
      padding: 5px;
    }
  }

  .apicard {
    box-shadow: 2px 2px 4px #00000014;
  }

  .apicard .ant-card-body {
    padding: 24px !important;
  }
  
  .h1_heading {
    font-size: 1.2em;
    font-size: 1.6em;
    font-weight: 700;
    margin-top: 20px;
    text-align: center;
    margin: 20px 0px;
    text-align: left;
  }
  
  .divider_css {
    margin: 15px 0px;
  }
  
  .divide {
    margin: 20px 0px;
  }

  .legal_entity {
    padding-left: 15px;
  }

  .legal_Entity {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px; 
    height: 62px;
    background: #F4F4F466;
    font-family: Elevance Sans;
    font-size: 24px;
    font-weight: 600;
    line-height: 27.36px;
    color: #333333;

    @media (max-width: 768px) {
      .ant-card-head {
        padding: 10px;
      }
    }
  }

  .ant-card-body {
    padding: 5px 24px;
  }

  .ant-card-head-title {
    font-family: Elevance Sans;
    font-size: 22px;
    font-weight: 600;
    line-height: 27.36px;
    color: #333333;
  }

  .ant-card-extra {
    cursor: pointer;
  }

  .emailValues {
    word-break: break-all;
  }

  .overview-title {
    font-family: Elevance Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.24px;
    text-align: left;
    color: #333333;
  }

  .overview-value {
    font-family: Elevance Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 27.36px;
    text-align: left;
    color: #333333;
  }

  .edit_button {
    min-width: 0px;
    min-height: 0px;
    border-radius: 8px;
    color: #286ce1;
    font-size: 14px !important;
  }

  .note_text {
    font-weight: 600;
    margin-left: 20px;
    font-size: 16px;
    margin-top: 12px;
  }

  .info-container {
    display: flex;
    align-items: center;
    background-color: #f4f5fc;
    padding: 15px;
  }

  .info-icon {
    background-color: transparent;
    color: black;
    border: 1px solid black;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-right: 10px;
    padding: 5px;
  }

  .info-text {
    font-family: Elevance Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #333333;
  }

  .agency-management {
    padding : 15px;
    font-family: Elevance Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.24px;
    text-align: left;
    color: #666666;
  }

  .commercial {
    font-family: Elevance Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.24px;
    text-align: left;
    color: #666666;
    padding : 15px
  }

  .list-items {
    font-family: Elevance Sans;
    font-size: 22px;
    font-weight: 500;
    color: #333333;
  }

  .list {
    display: flex;
    align-items: center;
  }

  .tick_icon {
    width: 26px;
    margin-right: 10px;
  }

  .review-primary-button {
    border: none;
    background-color: #286ce2;
    background: #286ce2;
    color: white;
  }

  .review-secondary-button {
    border: 1px solid #286ce2;
    color: #286ce2;
    background-color: #fff;
  }
  
  .exit-reg {
    margin: 10px 0px;
    text-align: right;
    color: #286ce2;
    text-decoration: underline;
    cursor: pointer;
  }

  .exit-reg-text {
    font-size: 13px;
    font-weight: 700;
    color: #286ce2;
  }

  .ant-card-head {
    font-weight: 700;
    margin-bottom: -44px;

    @media (max-width: 768px) {
      padding: 10px;
    }
  }

  .button-container {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .form-primary-button {
    border: none;
    background-color: #286ce1;
    color: white;
  }

  .form-secondary-button {
    border: 1px solid #286ce2;
    color: #286ce2;
    background-color: #fff;
  }

  .button-container button {
    width: 200px;
    padding: 10px 20px;
    margin: 0 10px !important;
    cursor: pointer;
    border-radius: 100px;
  }

  .apicard {
    box-shadow: 2px 2px 4px #00000014;
    ${'' /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */}
    
    .ant-card-body {
      padding: 20px !important;
    }
  }

  .space-btw-note {
    margin: 35px;
  }
  
  .separator-hr {
    width: 100%;
    margin: 20px 0;
    border-top: 0.5px solid #C2C2C2;
  }

 
  .pat-app-ob6ce2-GetStartedCss .ant-btn {
    min-width: 165px;
    min-height: 45px;
    border-radius: 25px;
    margin: 0 20px;
  }
`;
