import React, { Fragment, useEffect, useContext, useState } from 'react';
import { css } from '@emotion/core';
import { Divider } from 'antd';
import { AppContext } from './../../Contexts/AppContext';
import {WelcomeBanner, WelcomeBannerMobile} from './WelcomeBanner';
import {PartnerTypeSection, PartnerTypeSectionMobile} from './PartnerTypeSection';
import {PartnerTestimonialSection, PartnerTestimonialSectionMobile} from './PartnerTestimonialSection';
import { wellcomeArea, partnerPageCss } from './PartnerPage.style';
import { useHistory } from 'react-router-dom';

const PartnerPageComponent = () => {
  const [state, setState] = useState({ data: {}, status: 'pending', error: null });
  const { wraSlotList, clientConfig } = useContext(AppContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    getOwcsContent();
  }, []);

  const getOwcsContent = () => {
    if (wraSlotList) {
      const partner_tab_details = wraSlotList?.find(data => data?.name === 'partner_tab_details') || [];
      setState({
        data: {
          partner_tab_details,
        },
        status: 'success',
      });
    } else {
      setState({
        data: {},
        status: 'error',
      });
    }
  };

  const history = useHistory();

  const backToHome = value => {
    history.push(value);
  };

  const onChange = currentSlide => {
    // console.log(currentSlide);
  };

  const partnerDetails = state?.data?.partner_tab_details;
  const testimonialDataDetails = partnerDetails ? partnerDetails?.ListAssoc[3]?.ListAssoc : [];
  return (
    <div css={partnerPageCss}><div className="hide-on-mobile">
      <div>
        <div css={wellcomeArea}>
          <WelcomeBanner
            moduleTitle={state?.data?.partner_tab_details?.moduleTitle}
            linkText={state?.data?.partner_tab_details?.linkText}
            onExplore={() => backToHome('/explore/api-service')} />
        </div>
      </div>
      {partnerDetails ? (
        <PartnerTypeSection partnerDetails={partnerDetails} />
      ) : (
        <div>No partner details available.</div>
      )}
       {clientConfig.apiFlowToggle && (
      <PartnerTestimonialSection
        testimonials={testimonialDataDetails}
        onChange={onChange} /> )}
    </div>
    <div className="mobile-only">
        <div>
          <WelcomeBannerMobile
            moduleTitle={state?.data?.partner_tab_details?.moduleTitle}
            linkText={state?.data?.partner_tab_details?.linkText}
            onExplore={() => backToHome('/explore/api-service')} />
         
        </div>
        <Divider className="divider-class" />
        <div> <PartnerTypeSectionMobile className="mobile-only" partnerDetails={partnerDetails} /></div>
        {clientConfig.apiFlowToggle && (
        <PartnerTestimonialSectionMobile
          testimonials={testimonialDataDetails}
          onChange={onChange} /> )}
      </div></div>
    
  );
};

export default PartnerPageComponent;
