/* eslint-disable react/prop-types */
import React from 'react';
import { Button as AntDButton } from 'antd';
import { appPrimaryButton, appSecondaryButton } from './Button.style';
// eslint-disable-next-line react/prop-types
const Button = props => {
  let style = null;
  if (props?.type === 'app-primary') {
    style = appPrimaryButton;
  } else if (props?.type === 'app-secondary') {
    style = appSecondaryButton;
  }
  const type = props?.type !== 'app-primary' ? props?.type : 'default';
  return <AntDButton css={style} {...props} type={type} />;
};

export default Button;
