import React, { Fragment, useState } from 'react';
import { Row, Col, Drawer, Divider } from 'antd';
import ApiFilters from './ApiFilters';
import ApiDetailList from './ApiDetailList';
import {FilterOutlined} from '@ant-design/icons';
import { apiListDisplayCss } from './ApiList.style';

const ApiList = ({ apiFilters, apiList }) => {
  const [checkedValues, setCheckedValues] = useState([]);
  const [groupedCheckedValues, setGroupedCheckedValues] = useState({});

  const [drawerVisible, setDrawerVisible] = useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const handleCheckboxChange = filterText => {
    setCheckedValues(prevCheckedValues =>
      prevCheckedValues.includes(filterText)
        ? prevCheckedValues.filter(value => value !== filterText)
        : [...prevCheckedValues, filterText],
    );
  };

  const handleApply = () => {
    const groupedValues = apiFilters.data.reduce((acc, category) => {
      const categoryFilters = category.filters.filter(filter => checkedValues.includes(filter.text));
      if (categoryFilters.length > 0) {
        acc[category.heading] = categoryFilters.map(filter => filter.text);
      }
      return acc;
    }, {});
    setGroupedCheckedValues(groupedValues);
    closeDrawer();
  };

  const handleClearAll = () => {
    setCheckedValues([]);
    setGroupedCheckedValues({});
    closeDrawer();
  };
  return (
    <Fragment>
      <div  css={apiListDisplayCss}>
      {
        <p onClick={showDrawer} className="drawer-toggle">
       <span className="show-filter-text"> Show Filters  </span><FilterOutlined />
      
        </p>
        
      }
       <Divider className="divider-visible-css"/>
      <Row className="filterAndListContainer" gutter={[16,16]}>
        <Col xs={0} md={8} lg={8} xxl={6}>
          <ApiFilters
            apiFilters={apiFilters}
            checkedValues={checkedValues}
            handleCheckboxChange={handleCheckboxChange}
            handleApply={handleApply}
            handleClearAll={handleClearAll}
          />
        </Col>
        <Col md={16} lg={16} xxl={18}>
          <ApiDetailList groupedCheckedValues={groupedCheckedValues} apiList={apiList}  setGroupedCheckedValues={setGroupedCheckedValues}  setCheckedValues={setCheckedValues}/>
        </Col>
      </Row>
      <Drawer
        title="Filters"
        placement="right"
        onClose={closeDrawer}
        visible={drawerVisible}
      >
        <ApiFilters
          apiFilters={apiFilters}
          checkedValues={checkedValues}
          handleCheckboxChange={handleCheckboxChange}
          handleApply={handleApply}
          handleClearAll={handleClearAll}
        />
      </Drawer>
      </div>
    </Fragment>
  );
};

export default ApiList;
