import React from 'react';
import { Row, Col, Carousel, Card, Avatar, Divider } from 'antd';
import { partnerTestimonialCss } from './PartnerPage.style';
const { Meta } = Card;

const PartnerTestimonialSection = ({ testimonials, onChange }) => (
  <section id="partner-testimonial"  css={partnerTestimonialCss}>
    <Row justify="center" align="middle" className="col__widget_col partner_testimonial_section">
      <Col sm={24} xs={24} md={24} lg={24} xl={24}>
        <div>
          <p className="section__welcome_line1">Partner Testimonials</p>
        </div>
      </Col>
      <Col sm={24} xs={24} md={24} lg={24} xl={24}>
        <div>
          <p className="section__welcome_line">Happy Clients</p>
        </div>
      </Col>
      <Col sm={24} xs={24} md={24} lg={24} xl={24}>
        <div className="carousel_section">
          <Carousel afterChange={onChange}>
            {testimonials.map((testimonial, index) => (
              
              <div key={index}>
                <Row gutter={16}>
                  <Col span={6}></Col>
                  <Col span={6}>
                  <Card
                      style={{ width: '100%' }}
                      
                    >
                      <p dangerouslySetInnerHTML={{ __html: testimonial?.ListAssoc[0]?.copy }}></p>
                      <Meta
                        title={testimonial?.ListAssoc[0].title}
                        description={
                          <div>
                            <Divider />
                            <Row style={{ marginTop: '16px' }}>
                              <Col span={14}>
                                <Avatar src={ testimonial?.ListAssoc[0]?.ListAssoc[1]?.externalURL} />
                                <p>{testimonial?.ListAssoc[0].description}</p>
                              </Col>
                              <Divider type="vertical" style={{ height: 'auto' }} />
                              <Col span={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img
                                  src={`/public/api/landing/fetch/${testimonial?.ListAssoc[0]?.ListAssoc[1] && testimonial?.ListAssoc[0]?.ListAssoc[0]?.name}`}
                                  alt="Avatar"
                                  style={{ width: '100%', maxWidth: '80px', height: 'auto', display: 'block', marginTop: 8 }} // Adjust the size and spacing as necessary
                                />
                              </Col>
                            </Row>
                          </div>
                        }
                      />
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card
                      style={{ width: '100%' }}    
                    >
                      <p dangerouslySetInnerHTML={{ __html: testimonial?.ListAssoc[1]?.copy }}></p>
                      <Meta
                        title={testimonial?.ListAssoc[1].title}
                        description={
                          <div>
                            <Divider />
                            <Row style={{ marginTop: '16px' }}>
                              <Col span={14}>
                              <Avatar src={testimonial?.ListAssoc[1]?.ListAssoc[1]?.externalURL} />
                                <p>{testimonial?.ListAssoc[1].description}</p>
                              </Col>
                              <Divider type="vertical" style={{ height: 'auto' }} />
                              <Col span={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img
                                  src={`/public/api/landing/fetch/${testimonial?.ListAssoc[1]?.ListAssoc[0] && testimonial?.ListAssoc[1]?.ListAssoc[0]?.name}`}
                                  alt="Avatar"
                                  style={{ width: '100%', maxWidth: '80px', height: 'auto', display: 'block', marginTop: 8 }} // Adjust the size and spacing as necessary
                                />
                              </Col>
                            </Row>
                          </div>
                        }
                      />
                    </Card>
                  </Col>
                  <Col span={6}></Col>
                </Row>
              </div>
            ))
            }
          </Carousel>
        </div>
      </Col>
    </Row>
  </section>
);

export {PartnerTestimonialSection};


const PartnerTestimonialSectionMobile = ({ testimonials, onChange }) => (
  <section id="partner-testimonial"  css={partnerTestimonialCss}>
    <Row justify="center" align="middle" className="col__widget_col partner_testimonial_section">
      <Col sm={24} xs={24} md={24} lg={24} xl={24}>
        <div>
          <p className="section__welcome_line1">Partner Testimonials</p>
        </div>
      </Col>
      <Col sm={24} xs={24} md={24} lg={24} xl={24}>
        <div>
          <p className="section__welcome_line">Happy Clients</p>
        </div>
      </Col>
      <Col sm={24} xs={24} md={24} lg={24} xl={24}>
        <div className="carousel_section">
          <Carousel afterChange={onChange}>
            {testimonials.map((testimonial, index) => (
               testimonial?.ListAssoc && testimonial?.ListAssoc.length > 0 && testimonial.ListAssoc.map((assoc, idx) => (
              <div key={index}>
                <Row>
                 
                  <Col span={24}>
                  <Card
                      style={{ width: '100%' }}
                      
                    >
                      <p dangerouslySetInnerHTML={{ __html: assoc?.copy }}></p>
                      <Meta
                        title={assoc.title}
                        description={
                          <div>
                            <Divider />
                            <Row style={{ marginTop: '16px' }}>
                              <Col span={24}>
                              <Avatar src={assoc?.ListAssoc[1]?.externalURL} />
                                <p>{assoc.description}</p>
                              </Col>
                              </Row>
                              <Divider />
                              <Row style={{ marginTop: '16px' }}>
                              <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img
                                   src={`/public/api/landing/fetch/${assoc?.ListAssoc[0] && assoc?.ListAssoc[0]?.name}`}
                                  alt="Avatar"
                                  style={{ width: '100%', maxWidth: '80px', height: 'auto', display: 'block', marginTop: 8 }} // Adjust the size and spacing as necessary
                                />
                              </Col>
                            
                            </Row>
                          </div>
                        }
                      />
                    </Card>
                  </Col>
                </Row>
              </div>
            ))
            ))}
          </Carousel>
        </div>
      </Col>
    </Row>
  </section>
);

export {PartnerTestimonialSectionMobile};