import { css } from '@emotion/core';
export const greetingCard = css`
  padding: 2%;
  background: inherit;

  .back-to-admin-update-partner {
    color: white;
  }
  .ant-card-body {
    padding: 0px;
  }
  h2.username {
    font-weight: 300;
    text-transform: capitalize;
    margin-top: 0.5em;
  }
  h3.description {
    font-weight: 300;
    background: inherit;
    color: white;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }
`;

export const GetStartedCss = css`
  margin: 2% 10%;
  padding: 24px;
  background: white;

  .ant-card-head {
    border: none;
  }

  .exitButton {
    font-family: Elevance Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 18.24px;
    letter-spacing: -0.02em;
    text-align: center;
    text-decoration: underline;
    color: #286CE2;
  }

  .responsive-row {
    padding: 0 3.125rem;

    @media(min-width: 820px) {
      padding: 5rem 1rem 2rem;
    }

    @media(max-width: 820px) {
      padding: 2rem 1rem;
    }

    @media(max-width: 480px) {
      margin-top: 1.25rem;
    }

    .exitButton-col {
      position: static;
    }

    .ant-steps-item-title {
      font-family: Elevance Sans;
      font-size: 12px;
      font-weight: 600;
      line-height: 13.68px;
      text-align: center;
      color: #666666;
    }

    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
      font-family: Elevance Sans;
      font-size: 12px;
      font-weight: 600;
      line-height: 13.68px;
      text-align: center;
      color: #666666;
    }

    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
      font-family: Elevance Sans;
      font-size: 12px;
      font-weight: 600;
      line-height: 13.68px;
      text-align: center;
    }

    @media (max-width: 768px) {
      .exitButton-col {
        position: absolute;
        top: 6.875rem;
        right: 1.625rem;
      }
    }

  }

  .ant-steps-item-process .ant-steps-item-icon {
    background: #286ce2 0% 0% no-repeat padding-box;
    border-color: #286ce2;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
    background: #286ce2 0% 0% no-repeat padding-box;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #286ce2;
    font-weight: 600;
    font-size: 16px;
  }

  .ant-steps-small {
    width: 50%;
    margin: auto;
  }

  .legal__heading {
    font-family: Elevance Sans;
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    text-align: left;
    color: #333333;

    @media(max-width: 820px) {
      font-size: 24px;
    }
  }

  .ant-radio-wrapper {
    padding-right: 8%;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Lato';
  }

  .ant-radio-group {
    width: 100%;
  }

  .ant-btn {
    min-width: 120px;
    min-height: 45px;
  }

  .registration__card {
    width: 100%;
    margin: auto;
  }

  @media (max-width: 450px) {
    margin: 0px;
    padding: 0px;
    .ant-steps-small {
      width: 90%;
    }
    
    .registration__card {
      width: 100%;
      .ant-card-body {
        padding: 0;
      }
      .ant-card-head {
        padding: 20px;
      }
    }
  }

  @media (min-width: 480px) {
    margin: 0px;
    padding: 0px;
    .ant-steps-small {
      width: 90%;
    }
    
    .registration__card {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 576px) {
    margin: 0;
    padding: 0;
  }

  @media (min-width: 600px) {
    margin: 0px;
    padding: 0px;
    .ant-steps-small {
      width: 90%;
    }
    
    .registration__card {
      width: 90%;
    }
  }

  @media (min-width: 801px) {
    margin: 0px;
    padding: 0px;
    .ant-steps-small {
      width: 90%;
    }
    
    .registration__card {
      width: 90%;
    }
  }

  @media (min-width: 1025px) {
    margin: 2% 5%;
    padding: 24px;
    .ant-steps-small {
      width: 30%;
    }

    .registration__card {
      width: 100%;
    }
  }
  
  @media (min-width: 1281px) {
    margin: 2% 5%;
    padding: 24px;
    .ant-steps-small {
      width: 30%;
    }

    .registration__card {
      width: 90%;
    }
  }

  @media (min-width: 1400px) {
    margin: 2% 10%;
    padding: 24px;
    .ant-steps-small {
      width: 30%;
    }

    .registration__card {
      width: 90%;
    }
  }

  .progress {
    width: 30%;
    margin-left: 40px;

    @media (max-width: 768px) {
      width: 100%;
      margin-left: 0px;
    }
  }

  .align-right {
    text-align: right;
  }
`;

export const GetStartedLayoutCss = css`
  background: #fff;
  .pat-app-13iva0d-greetingCard {
    padding: 2% 2% 0% 2%;
  }

  .main-section {
    margin: auto;
  }

  .getstarted-footer {
    margin-bottom: 1em;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 0;

    .nextButton__card {
      width: 450px;

      .nextButton {
        width: 202.8px;
        height: 52px;
        gap: 0px;
        border-radius: 100px;

        @media (max-width: 480px) {
          width: 180px
        }

        @media (max-width: 390px) {
          width: 160px
        }
      }

      .ant-card-body {
        @media (max-width: 820px) {
          padding-right: 0;
        }
      }

      .ant-btn > span {
        font-family: Elevance Sans;
        font-size: 16px;
        font-weight: 600;
        line-height: 18.24px;
        text-align: center;
      }
    }

    @media (max-width: 1025px) {
      margin-bottom: 0;
    }

    @media (max-width: 992px) {
      margin-bottom: 20px;
      .reviewButton {
        width: 217.8px;
        height: 48px;
      }
    }

    @media (max-width: 820px) {
      padding: 0 0em 2em;
    }
  }
`;

export const exitDropdown = css`
  box-shadow: 6px 14px 20px 0px #00000021;
  border: 1px solid #00000014;
  border-radius: 8px;
  width: 322px;
  height: 313px;

  .exit-dropdown-title-col {
    margin-bottom: 20px;
  }

  .exit-dropdown-title {
    font-family: Bitter;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
    text-align: center;
  }

  .exit-dropdown-button {
    width: 203px;
    height: 59px;
    border-radius: 8px;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
  }

  .exit-dropdown-button-primary:hover {
    color: white;
  }
`;