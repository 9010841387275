import { css } from '@emotion/core';
export const ApiSelectionCss = css`
  .title-head {
    .ant-card-body {
      padding-left: 0;
    }
  }

  .apiselection__title{
    font-family: Elevance Sans;
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
    color: #333333;

    @media (min-width: 576px) {
      font-size: 1.75rem;
    }

    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }

  .apiselection__description {
    font-family: Elevance Sans;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: #333333;

    @media (min-width: 576px) {
      font-size: 1.125rem;
    }

    @media (min-width: 768px) {
      font-size: 1.25rem;
    }

    @media (min-width: 992px) {
      font-size: 1.375rem;
    }

    @media (min-width: 1200px) {
      font-size: 1.5rem;
    }
  }

  .api-info {
    box-shadow: 2px 2px 4px #00000014;
    border: 1px solid #eaeaea;
    margin-top: 14px;
  }

  .upload-btn {
    background: #37475a 0% 0% no-repeat padding-box;
    color: white;
  }

  .txt__center {
    text-align: center;
  }

  .h6__description {
    color: #666666;
    font-weight: 500;
    font-size: 16px;
    font-family: Lato;
  }

  .h1__description {
    font-weight: 600;
    font-size: 22px;
    font-family: Lato;
  }

  .api-img {
    text-align: center;
    width: 4.9394rem;
    height: 4rem;
    display: flex;

    @media (max-width: 992px) {
      text-align: left;
      width: 2.8125rem;
      height: 2.8125rem;
    }
  }

  .app-sub-main-row {
    margin: auto !important;
  }

  .sub-check {
    font-family: Lato;
    font-size: 14px;
    color: #333333;
  }

  .select__all_chk_box {
    margin-left: 25px;
  }

  .tag-btn {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    width: 69px;
    height: 36px;

    .ant-switch-handle {
      top: 4px;
      left: 4px;
    }

    .ant-switch-handle::before {
      width: 28px;
      height: 28px;
      border-radius: 100%;
    }
  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 28px - 4px);
  }

  .ant-switch-checked {
    background-color: #286CE2;
  }

  .notification-btn {
    font-weight: 400;
    border-radius: 6px;
    border: none;
    min-height: 20px;
    height: 25px;
    font-size: 14px !important;
    padding: 3px 15px 5px 10px;
    background: #1A3673;
    font-family: Lato;
    color: #ffffff;
    right: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .notification-img {
    width: .7013rem;
    height: .9225rem;
  }

  @media (max-width: 320px) {
    .h1__title {
      font-size: 16px;
      font-family: Montserrat;
    }

    .h2__title {
      font-size: 16px;
      font-family: Montserrat;
    }
    .select__all {
      text-align: right;
    }
    .app-sub-main-row {
      width: 100%;
    }
  }
  @media (max-width: 480px) {
    .h1__title {
      font-size: 16px;
      font-family: Montserrat;
    }

    .h2__title {
      font-size: 16px;
      font-family: Montserrat;
    }
    .select__all {
      text-align: right;
    }
    .app-sub-main-row {
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    padding: 10px;
    .h1__title {
      font-size: 18px;
      font-family: Montserrat;
    }

    .h2__title {
      font-size: 16px;
      font-family: Montserrat;
    }

    .select__all {
      text-align: right;
    }
    .app-sub-main-row {
      width: 100%;
    }
  }
  @media (max-width: 801px) {
    .h1__title {
      font-size: 24px;
      font-family: Montserrat;
    }

    .h2__title {
      font-size: 22px;
      font-family: Montserrat;
    }

    .select__all {
      text-align: left;
    }
    .app-sub-main-row {
      width: 70%;
    }
  }
  @media (max-width: 1025px) {
    padding: 1.875rem;
    .h1__title {
      font-size: 26px;
      font-family: Montserrat;
    }

    .h2__title {
      font-size: 22px;
      font-family: Montserrat;
    }

    .select__all {
      text-align: center;
    }
    .app-sub-main-row {
      width: 70%;
    }
  }
  @media (max-width: 1281px) {
    .h1__title {
      font-size: 30px;
      font-family: Montserrat;
    }

    .h2__title {
      font-size: 22px;
      font-family: Montserrat;
    }
    .select__all {
      text-align: center;
    }
    .app-sub-main-row {
      width: 70%;
    }
  }
`;
