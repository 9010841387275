import React, { Fragment, useEffect } from 'react';
import { Typography, Card, Row, Col, Avatar, Checkbox, Space } from 'antd';
import { getPublicSiteImageSrc } from 'Client/common/utils/AppUtils';
import { INTERESTED_APIS } from 'Client/common/constants/AppConstants';


const CardsFour = ({handleCheckboxSelection, interestedApis}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="ant-result-title">
        <Fragment>
          <Typography.Paragraph className="cards-heading">
            <Space>
              <Avatar style={{ backgroundColor: '#074079', size: 'large' }} size={{ xs: 32, sm: 32, md: 36, lg: 36, xl: 36, xxl: 36}}>4</Avatar> 
              <span className="avatar-text">Which of our API connections are you most interested in ?</span>
            </Space>
          </Typography.Paragraph>
        </Fragment>
      </div>
      <Checkbox.Group name="interestedApis" 
        value={interestedApis}
        onChange={handleCheckboxSelection}
      >
        <Row gutter={[{xs: 32, sm: 32, md: 64, lg: 16, xl: 16}, 16]}justify="space-around">
          {INTERESTED_APIS.map((apiConnection, index) => (
            <Col xs={12} sm={12} md={6} key={index}>
              <Card
                className="checkbox-card"
                bodyStyle={{
                  height: "inherit"
                }}
              >
                 <Row style={{height:"inherit"}} justify="space-around">
                  <Col sm={{span: 12}} md={{offset: 2, span: 12}}>
                    <img
                      src={
                        getPublicSiteImageSrc(apiConnection.toLowerCase().replaceAll(' ', '-') + ".svg")
                      }
                      alt="Avatar"
                      className="qna_image"
                    />
                  </Col>
                  <Col span={24}>
                    <Checkbox value={apiConnection}>
                      <span className='qna-value'> { apiConnection } </span>
                    </Checkbox>
                  </Col>
                 </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </div>
  );
};

export default CardsFour;
