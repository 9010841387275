import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { array, any, func, string } from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Row, Col, Space, Typography, Card, Tabs, Steps, Collapse } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Popout from 'react-popout';
import ReactPlayer from 'react-player';

import ErrorComponent from 'Client/components/ErrorComponent/ErrorComponent';
import ProgressBar from './../../components/ProgressBar/ProgressBar';
import CSSCacheProvider from './../../Contexts/CSSCacheProvider';
import LandingVideoThumbnail from '../../common/img/landing/api/landing_video_thumbnail.png';

import {
  CarousalCSS,
  WelcomeSectionCSS,
  GetStartedMobileCss,
  MainContainerCss,
  APIWidgetCss,
  UserFlowCSS,
  UserFlowSectionCSS,
  SectionStepsCss,
  apiFlowSection,
  faqSection,
  recommded_api,
} from './Landing.style';
import { TransparentButton } from 'Client/components/Buttons/TransparentButton';
import { SecondaryButton } from 'Client/components/Buttons/SecondaryButton';
import ApiWidget from './ApiWidget';
import User_Flow_Diagram from '../../common/img/ProcessDiagram_Desktop.svg';
import User_Flow_Diagram_Mobile from '../../common/img/ProcessDiagram_Mobile.svg';
import { AppContext } from './../../Contexts/AppContext';
import { scroller } from 'react-scroll';
import { useHistory } from 'react-router-dom';
import CarousalWrapper from './../../components/Carousal/CarousalWrapper';
import Button from 'Client/components/Buttons/Button';
import PreSelectApiList from './PreSelectApiList';
const Panel = Collapse.Panel;
const images = require.context('./../../common/img', false, /\.svg$/);

const WelcomeSection = props => {
  const { welcomeBannerContent } = props;
  return (
    <Fragment>
      <Card
        bordered={false}
        className="card__welcome_section"
        title={
          <div id="welcome-heading">
            <Typography.Title className="div__welcome_heading">{welcomeBannerContent}</Typography.Title>
          </div>
        }
      ></Card>
    </Fragment>
  );
};

WelcomeSection.propTypes = {
  welcomeBannerContent: any,
};

const apiCards = props => {
  const { welcomeBannerContent } = props;
  return (
    <Fragment>
      <Card
        bordered={false}
        className="card__welcome_section"
        title={
          <div id="welcome-heading">
            <Typography.Title className="div__welcome_heading">
              Anthem partner Apis: Real-time connections that power simplicity & efficiency{' '}
            </Typography.Title>
          </div>
        }
      >
        <div id="welcome-description">
          <Typography.Paragraph className="div__welcome_description">
            <div dangerouslySetInnerHTML={{ __html: welcomeBannerContent?.copy }} />
          </Typography.Paragraph>
        </div>
      </Card>
    </Fragment>
  );
};

apiCards.propTypes = {
  welcomeBannerContent: any,
};

const IntroCarousal = props => {
  const { slides, scrollToApiWidgetSection } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 9000,
    swipe: true,
    arrows: false,
  };
  const history = useHistory();

  const navNext = slide_key => {
    if (slide_key === 0) {
      // history.push('/explore#api-widget');
      scrollToApiWidgetSection();
    } else if (slide_key === 2) {
      history.push('/explore/api-service#commercial_api');
    } else {
      history.push('/explore/api-service#agency_api');
    }
  };

  const getImageSrc = imageName => {
    try {
      return images(`./${imageName}.svg`).default;
    } catch (error) {
      console.error(`Image not found: ${imageName}`);
    }
  };

  return (
    <Fragment>
      <div id="carousal-container" className="container__carousal" css={CarousalCSS}>
        <Slider {...settings}>
          {slides.map((slide, index) => {
            return (
              <Fragment key={index}>
                <div id={`slide-${index}`} className={`container__slide_${index} view_height__landing`}>
                  <Row align="middle" justify="space-around" className="container__slide-row">
                    <Col
                      sm={{ order: 2, span: 24 }}
                      xs={{ order: 2, span: 24 }}
                      md={{ order: 2, span: 24 }}
                      lg={{ order: 1, span: 10 }}
                      xl={{ order: 1, span: 10 }}
                    >
                      <Space direction="vertical" size="8" className="space__container">
                        <Typography.Title className="h1__title space__item no_margin">{slide.heading}</Typography.Title>
                        <Typography.Text className="p__description space__item">{slide.description}</Typography.Text>
                        <TransparentButton
                          className="space__item"
                          btnText="Explore APIs"
                          onClick={() => navNext(index)}
                        />
                      </Space>
                    </Col>
                    <Col
                      sm={{ order: 1, span: 20 }}
                      xs={{ order: 1, span: 20 }}
                      md={{ order: 1, span: 20 }}
                      lg={{ order: 2, span: 12 }}
                      xl={{ order: 2, span: 12 }}
                    >
                      <div id={`intro_img_${index}`}>
                        {/* <img
                          src={require(`./../../common/img/${slide?.name}.svg`).default}
                          className="image__intro_slide"
                        /> */}
                        <img src={getImageSrc(slide?.name)} className="image__intro_slide" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Fragment>
            );
          })}
        </Slider>
      </div>
    </Fragment>
  );
};

IntroCarousal.propTypes = {
  scrollToApiWidgetSection: func,
  slides: array,
};

const UserFlowDiagram = ({ flowTitle }) => {
  return (
    <Fragment>
      <Card
        bordered={false}
        css={UserFlowCSS}
        title={
          <div id="user_flow_heading">
            <Typography.Title className="user_flow__title">{flowTitle}====</Typography.Title>
          </div>
        }
      >
        <Row gutter={24}>
          <Col xs={0} sm={0} ms={24} lg={24} xl={24}>
            <div id="user_flow_diagram">
              <img src={User_Flow_Diagram} className="user_flow__diagram" />
            </div>
          </Col>
          <Col xs={24} sm={24} ms={0} lg={0} xl={0}>
            <div id="user_flow_diagram">
              <img src={User_Flow_Diagram_Mobile} className="user_flow__diagram" />
            </div>
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};

UserFlowDiagram.propTypes = {
  flowTitle: string,
};

const onChange = key => {
  // console.log(key);
};

const LandingNewPage = (props) => {
  const { landingPageVideo } = props;
  const [state, setState] = useState({ data: {}, status: 'pending', error: null });
  const [partnerType, setPartnerType] = useState('');
  const [videoOpen, setVideoOpen] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const { wraSlotList } = useContext(AppContext);
  const history = useHistory();
  const mounted = useRef();
  const playerRef = useRef();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1240);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getOwcsContent();
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      history.push('/register', {
        partnerType: partnerType,
        preselectApiFlow: true,
        registrationFlow: 'preselectApis'
      });
    }
  }, [partnerType]);

  const getOwcsContent = () => {
    if (wraSlotList) {
      const client_and_business_management_elevated =
        wraSlotList?.find(data => data?.name === 'client_and_business_management_elevated') || [];
      const partner_api_partnership_program =
        wraSlotList?.find(data => data?.name === 'partner_api_partnership_program') || [];
      const integrate_with_anthem = wraSlotList?.find(data => data?.name === 'integrate_with_anthem') || [];
      const getting_started_partner_api = wraSlotList?.find(data => data?.name === 'getting_started_partner_api') || [];
      const frequently_asked_questions = wraSlotList?.find(data => data?.name === 'frequently_asked_questions') || [];
      const recommend_apis_for_you = wraSlotList?.find(data => data?.name === 'recommend_apis_for_you') || [];
      setState({
        data: {
          client_and_business_management_elevated,
          partner_api_partnership_program,
          integrate_with_anthem,
          getting_started_partner_api,
          frequently_asked_questions,
          recommend_apis_for_you,
        },
        status: 'success',
      });
    } else {
      setState({
        data: {},
        status: 'error',
      });
    }
  };

  const moreFAQ = () => {
    history.push('/explore/FAQuestions');
  };
  const recommendApi = () => {
    history.push('/register/guided-flow');
  };

  const backToHome = value => {
    history.push(value);
  };

  const preselectApis = partnerType => {
    setPartnerType(partnerType);
  };

  const onClickPreview = e => {
    playerRef?.current?.showPreview();
    setVideoOpen(true);
  }

  const onPreviewClose = e => {
    playerRef?.current?.showPreview();
    setVideoOpen(false);
  }

  const items = [
    {
      key: '1',
      label: 'Agency Management API',
      children: (
        <ApiWidget apiData={state?.data?.client_and_business_management_elevated?.ListAssoc[1]} category="agency_api" />
      ),
    },
    {
      key: '2',
      label: 'Commercial Group Submission & Member Management',
      children: (
        <ApiWidget apiData={state?.data?.client_and_business_management_elevated?.ListAssoc[0]} category="agency_api" />
      ),
    },
  ];

  const itemsSteps = [
    {
      title: 'Become an API partner',
      description:
        'Select Get Started to register as an API partner. Let us know whether you’re an agency, benefit administrator, or technology partner. Then follow the prompts.',
    },
    {
      title: 'Request APIs',
      description:
        ' We will recommend APIs that may meet the needs of your business. Select the ones you are interested in.',
    },
    {
      title: 'Access our APIs',
      description:
        'We will review and approve your partnership request within 5 business days. Once approved, we will provide your credentials to access your secure client console, the launch point for establishing your connection to Anthem.',
    },
  ];

  const apiData = [
    {
      title: 'Agencies',
      partnerType: 'BROKER',
      imageSrc: require(`./../../common/img/new-public-Ui/agencies.png`).default,
      apis: [
        'Commissions API',
        'Book of Business API',
        'Application List and Details API',
        'Opportunity Management API',
        'Benefit Details API',
        'Broker News Feed API',
        'Application Submission and more',
      ],
    },
    {
      title: 'Benefit Administrators',
      partnerType: 'BENADMIN',
      imageSrc: require(`./../../common/img/new-public-Ui/ben_admin.png`).default,
      apis: [
        'Book of Business API',
        'Benefit Details API',
        'Application Submission API',
        'Member Maintenance API',
        'Member Enrollment API',
        'Quote Management API and more',
      ],
    },
    {
      title: 'Technology Partners',
      partnerType: 'TECHPLATFORM',
      imageSrc: require(`./../../common/img/new-public-Ui/tech_platform.png`).default,
      apis: [
        'Book of Business API',
        'Benefit Details API',
        'Group structure API',
        'Member Maintenance API',
        'Member Enrollment API',
        'Quote Management API',
        'Application Submission API and more',
      ],
    },
  ];

  const faqData = state?.data?.frequently_asked_questions;

  return (
    <Fragment>
      {state?.status === 'pending' ? (
        <ProgressBar blueColor />
      ) : state?.status === 'error' ? (
        <ErrorComponent />
      ) : (
        <Fragment>
          <div css={MainContainerCss}>
            <section id="welcome-banner" css={WelcomeSectionCSS}>
              <video autoPlay loop muted className="background-video">
                <source src={ landingPageVideo?.background } type="video/mp4"/>
              </video>
              <div className="welcome_banner_content">
                <h2 className="first_headline">{state?.data?.partner_api_partnership_program.heading}</h2>
              </div>
              <div className="welcome_banner_content2">
                <h1 className="second_headline">{state?.data?.partner_api_partnership_program?.description}</h1>
                <Row className="welcome_banner_btn" justify="space-between">
                  <Col xs={9} sm={9} md={0} lg={0} xl={0}>
                    <Button
                      type="app-primary"
                      className="get_started_btn"
                      onClick={() => history.push('/register')}
                    >
                      Get Started
                    </Button>
                  </Col>
                  <Col span={14}>
                    <Button className="tell_us_button" onClick={() => recommendApi()}>
                    Tell Us Why You’re Here
                    </Button>
                  </Col>
                </Row>
              </div>
            </section>

            <section id="api-widget" className="section__widget_section" css={APIWidgetCss}>
              <Row justify="center" align="middle" className="col__widget_col api-widget-row">
                <Col sm={24} xs={24} md={24} lg={24} xl={24}>
                  <div>
                    <p className="section__welcome_line1">
                      {state?.data?.client_and_business_management_elevated.moduleTitle}
                    </p>
                  </div>
                </Col>
                <Col sm={24} xs={24} md={24} lg={24} xl={24}>
                  <div>
                    <p className="section__welcome_line2">
                      {state?.data?.client_and_business_management_elevated.linkText}
                    </p>
                  </div>
                </Col>

                <Col sm={24} xs={24} md={24} lg={0} xl={0}>
                
                </Col>

                <Col span={24} className="col__widget_col api-widget-col">
                  <Tabs defaultActiveKey="1" items={items} onChange={onChange} className="tab-selection" />
                </Col>
              </Row>
            </section>
            <section id="steps-section-steps" className="section__steps" css={SectionStepsCss}>
              <Row justify="center" align="middle" className="col__widget_col">
                <Col sm={24} xs={24} md={24} lg={24} xl={24}>
                  <div>
                    <p className="section__steps1">{state?.data?.integrate_with_anthem.moduleTitle}</p>
                  </div>
                </Col>
                <Col sm={24} xs={24} md={24} lg={24} xl={24}>
                  <div>
                    <p className="section__steps2">{state?.data?.integrate_with_anthem.linkText}</p>
                  </div>
                </Col>
                <Col sm={24} xs={24} md={24} lg={24} xl={24}>
                  <div className="api_steps_flow">
                    <Steps current={0} size="large" labelPlacement="vertical" items={itemsSteps} direction={isMobile ? 'vertical' : 'horizontal'} />
                    {/* SFO-11: added video player on landing page */}
                    <div className="player-wrapper">
                      <ReactPlayer
                        className="react-player"
                        ref={playerRef}
                        playing={isVideoPlaying}
                        url={ landingPageVideo?.getStarted || "https://brokerportal.anthem.com/966d1659/partnerlogin.mp4"}
                        width="100%"
                        height="100%"
                        light={
                          !isVideoPlaying ? (
                            <img src={LandingVideoThumbnail} className="player-thumbnail" alt="Thumbnail" />
                          ) : (
                            false
                          )
                        }
                        onClickPreview={onClickPreview}
                      />
                    </div>
                    {videoOpen && (
                      <Popout
                        title="Video"
                        onClosing={onPreviewClose}
                        width="800"
                        height="600"
                      >
                        <div>
                          <ReactPlayer
                            className="react-player"
                            playing
                            controls
                            url={ landingPageVideo?.getStarted || "https://brokerportal.anthem.com/966d1659/partnerlogin.mp4"}
                            width="100%"
                            height="100%"
                            config={{
                              file: {
                                attributes: {
                                  controlsList: 'nodownload',
                                },
                              }
                            }}
                          />
                        </div>
                      </Popout>
                    )}
                  </div>
                </Col>
                
              </Row>
            </section>
            <section id="api-flow-section" className="api_flow_section" css={apiFlowSection}>
                  <div className="api_flow_section1 hide-on-mobile">
                    <img
                      src={require(`./../../common/img/new-public-Ui/api_flow.svg`).default}
                      alt="Avatar"
                      className="apiFlow_image"
                    ></img>
                  </div>
                  <div className="api_flow_section1 mobile-only">
                    <img
                      src={require(`./../../common/img/new-public-Ui/api_flow_mobile.svg`).default}
                      alt="Avatar"
                      className="apiFlow_image"
                    ></img>
                  </div>
                </section>
            <section id="recommded_api" css={recommded_api}>
              <h2 className="section__welcome_line1">{state?.data?.recommend_apis_for_you?.moduleTitle}</h2>
              <h2 className="section__welcome_line2">{state?.data?.recommend_apis_for_you?.linkText}</h2>
              <Row gutter={[{xs: 0, sm: 0, md: 0, lg: 0}, {xs: 16, sm: 16, md: 16, lg: 16 }]} justify="space-between" className="recommded_api_section">
                {apiData.map((data, index) => (
                  <Col sm={24} xs={24} md={12} lg={8} xl={8} className="recommded_api_list" key={index}>
                    <PreSelectApiList
                      title={data.title}
                      imageSrc={data.imageSrc}
                      apis={data.apis}
                      onRequestAccess={() => preselectApis(data.partnerType)}
                    />
                  </Col>
                ))}
              </Row>
            </section>
            <section id="api-faq-section" css={faqSection} style={{ background: 'white' }}>
              <div>
                <h2 className="section__welcome_line1">{faqData?.moduleTitle}</h2>
                <h2 className="section__welcome_line2">{faqData?.linkText}</h2>
                <div className="collapse-container">
                  <Collapse
                  onChange={onChange} 
                  className="faqCard"
                  expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
                  >
                    {faqData?.ListAssoc?.slice(0, 5).map((item, index) => (
                      <Panel header={item.heading} key={index + 1} className="faqHeader">
                        <p className="faqDescription">{item.description}</p>
                      </Panel>
                    ))}
                  </Collapse>
                </div>
                <Button className="more_info_api" onClick={moreFAQ}>
                <div className='more-info-api-text'>
                  More FAQs
                  </div>
                </Button>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default function LazyLandingModule() {
  const { clientConfig } = useContext(AppContext);

  return (
    <CSSCacheProvider value={{ key: 'landing' }}>
      <LandingNewPage landingPageVideo={clientConfig?.landingPageVideo} />
    </CSSCacheProvider>
  );
}
