import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Row, Col, Layout, Input, Typography, Button } from 'antd';
import { ApiSearchCss, SearchBarCss } from './ApiServiceList.style';

const { Header } = Layout;
const { Search } = Input;

const prefix = (
  <SearchOutlined
    style={{
      fontSize: 16,
      color: '#C2C2C2',
    }}
  />
);

const searchButton = (
  <Button type="primary" size="large" className="btn-large-primary">
    Search
  </Button>
);

const ApiSearch = () => {
  return (
    <Layout id="ApiSearchCss" css={ApiSearchCss}>
      <Header className="apisearch">
        <Row justify="center" gutter={[20, 20]}>
          <Col span={24}>
            <Typography.Title className="search_header search_content">
              Anthem&lsquo;s suite of partner APIs
            </Typography.Title>
          </Col>
          <Col span={24} md={16}>
            <Typography.Paragraph className="search_content search_content_para">
              Our collection APIs connections are designed to help brokers, benefit administrators, insurtech innovators
              and others connect directly to Anthem. We developed our APIs to simplify our partners&lsquo; day-to-day
              tasks and make sharing with Anthem information smoother, faster and more accurate.
            </Typography.Paragraph>
          </Col>
          {/* <Col span={14} css={SearchBarCss}>
            <Search
              placeholder="&nbsp;&nbsp;Enter keyword search"
              allowClear
              enterButton={searchButton}
              size="large"
              prefix={prefix}
            />
          </Col> */}
        </Row>
      </Header>
    </Layout>
  );
};

export default ApiSearch;
