import React, { Fragment, useEffect, useContext, useState } from 'react';
import { Row, Col, Layout, Menu, Space, Button, Dropdown, Typography, Divider } from 'antd';
import { AppContext } from './../../Contexts/AppContext';
import { LockOutlined, DownOutlined } from '@ant-design/icons';
import { SecondaryButton } from 'Client/components/Buttons/SecondaryButton';
import { useHistory, useLocation } from 'react-router-dom';
import DropdownOverlayComponent from './DropDownOverlayComponent';
import MobileDrawer from './MobileDrawer';
import { MenuFoldOutlined } from '@ant-design/icons';
import { HeaderCss } from './Header.style';
import { getPublicSiteImageSrc } from 'Client/common/utils/AppUtils';
import { REGISTRATION_PATHS } from 'Client/common/constants/AppConstants';

const HeaderComponent = () => {
  const history = useHistory();
  const [showHomeSection, setShowHomeSection] = useState(true);
  const [state, setState] = useState({
    data: { agencyManagementApi: null, commercialManagementApi: null },
    error: null,
    status: 'pending',
  });
  const [activeMenu, setActiveMenu] = useState('home');
  const [drpVisible, setDrpVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { wraSlotList } = useContext(AppContext);
  let location = useLocation();

  // Check if the current path contains any of the paths in REGISTRATION_PATHS
  const shouldHideButtons = REGISTRATION_PATHS.some(path => location.pathname.includes(path));


  useEffect(() => {
    getActiveMenuLabelName();
    populateLandingPageContents();
    const isRegistrationPathFound = location?.pathname?.split('/')?.includes('register');
    isRegistrationPathFound ? setShowHomeSection(false) : setShowHomeSection(true);
  }, [location]);

  useEffect(() => {
    if (drpVisible) {
      setActiveMenu('api-service');
    }
  }, [drpVisible])

  const getActiveMenuLabelName = () => {
    const activeMenu = history?.location?.pathname?.split('/');
    if (activeMenu?.includes('api-service')) {
      setActiveMenu('api-service');
    } else if (activeMenu?.includes('Partners')) {
      setActiveMenu('partners');
    } else if (activeMenu?.includes('apiDetails')) {
      setActiveMenu('');
    } else {
      setActiveMenu('home');
    }
  };

  const populateLandingPageContents = () => {
    const apiAssocList = wraSlotList?.find(data => data?.name === 'api-cards-marketing-list')?.ListAssoc || [];
    const agencyManagementApi = apiAssocList?.find(data => data?.name === 'partner_agency_mgmt_apis');
    const commercialManagementApi = apiAssocList?.find(data => data?.name === 'partner_comm_grp_subm_n_mbr_mgmt_apis');
    setState({
      data: { agencyManagementApi, commercialManagementApi },
      status: 'success',
    });
  };

  const [activeItem, setActiveItem] = useState('agency');

  const handleAPIItemClick = item => {
    setActiveItem(item);
  };

  const handleDrpVisibleHandler = () => {
    setDrpVisible(!drpVisible);
  };

  const closeDropDownHandler = () => {
    setDrpVisible(false);
  };

  const navToOverviewPage = () => {
    closeDropDownHandler();
    setActiveMenu('api-service');
    history.push('/explore/api-service');
  };

  const closeDrawer = () => setDrawerVisible(false);

  const openDrawer = () => setDrawerVisible(!drawerVisible);
  
  const imageURL = '/public/api/landing/fetch/anthem-branding-expanded-image.png';
  return (
    <Fragment>
      <Layout.Header css={HeaderCss} className="header__container">
        {/* {showHomeSection ? ( */}
          <Row>
            <Col span={24}>
              <Row align="middle" justify={!shouldHideButtons ? "space-between" : "left"}>
                <Col span={6}>
                  <img src={imageURL} className="img-logo" onClick={() => history.push('/explore')} />
                </Col>
                {!shouldHideButtons && (
                  <Col xs={0} sm={0} md={0} lg={{span: 8, offset: !shouldHideButtons ? 0 : 3}} xl={{span: 8, offset: !shouldHideButtons ? 0 : 3}} className="col__menu_section">
                    <Row justify="space-evenly">
                      <Col>
                        <Menu Menu selectedKeys={[activeMenu]} mode="horizontal">
                          <Menu.Item
                            className="menu__item"
                            key="home"
                            onClick={() => {
                              setActiveMenu('home');
                              history.push('/');
                            }}
                          >
                            <span className='landing_menu_item'>Home</span>
                          </Menu.Item>
                          <Menu.Item key="api-service" className="menu__item">
                            <Dropdown
                              overlay={
                                <DropdownOverlayComponent
                                  agencyApiData={state?.data?.agencyManagementApi}
                                  commercialApiData={state?.data?.commercialManagementApi}
                                  navToOverviewPage={navToOverviewPage}
                                  closeDropDownHandler={closeDropDownHandler}
                                  activeItem={activeItem}
                                  onItemClick={handleAPIItemClick}
                                />
                              }
                              onVisibleChange={handleDrpVisibleHandler}
                              visible={drpVisible}
                              placement="bottomCenter"
                              trigger={['click']}
                              overlayClassName="dropdown__overlay"
                              overlayStyle={{ position: 'fixed', top: '57px' }}
                            >
                              <span>
                                <Space>
                                  <span className='landing_menu_item'>API services</span>
                                  <DownOutlined />
                                </Space>
                              </span>
                            </Dropdown>
                          </Menu.Item>
                          <Menu.Item
                            key="partners"
                            className="menu__item"
                            onClick={() => {
                              setActiveMenu('partners');
                              history.push('/explore/Partners');
                            }}
                          >
                            <span className='landing_menu_item'> Partners </span>
                          </Menu.Item>
                        </Menu>
                      </Col>
                    </Row>
                  </Col>)
                }
                { !shouldHideButtons && 
                  <Col xs={0} sm={0} md={0} lg={7} xl={7} className="header-btn-container">
                    <Space>
                      <Button
                      icon={ <LockOutlined />}
                        className="header__login_btn header_btn"
                        onClick={() => window.location.assign('/login')}
                      >
                        Log In
                      </Button>

                      <SecondaryButton
                        className="header__register-btn header_btn"
                        onClick={() => history.push('/register')}
                      >
                        Get Started
                      </SecondaryButton>
                    </Space>
                  </Col>
                }
                {
                  showHomeSection && (<Col xs={8} sm={8} md={6} lg={0} xl={0} className="align-right menu_icon_container">
                    <Space>
                    <Button className="hamburger__menu" onClick={openDrawer} type="link" 
                      icon={<img src={getPublicSiteImageSrc('hamburger.svg')} />} 
                    />
                    </Space>
                    <MobileDrawer
                      agencyApiData={state?.data?.agencyManagementApi}
                      commercialApiData={state?.data?.commercialManagementApi}
                      closeDrawer={closeDrawer}
                      visible={drawerVisible}
                    />
                  </Col>)
                }
              </Row>
            </Col>
          </Row>
      </Layout.Header>
    </Fragment>
  );
};

export default HeaderComponent;
