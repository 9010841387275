import React, { useEffect, Fragment, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { array, string, object, func } from 'prop-types';
import { Card, Typography, Form, Col, Row, Checkbox, notification } from 'antd';
import * as _ from 'lodash';


import { API_DEVELOPMENT_STATUS } from '../../common/constants/AppConstants';
import ApiComponent from './ApiComponent';
import { ApiSelectionCss } from './ApiSelection.style';
import Button from 'Client/components/Buttons/Button';
import { SecondaryButton } from 'Client/components/Buttons/SecondaryButton';
import LicenseAgreement from './LicenseAggrement';
import { AppContext } from '../../Contexts/AppContext';
import { footerSection } from '../RecommendedApiList/QuestionCard.style';

const NestedForm = ({ form, apiList, changeSelectAllFlag }) => (
  <>
    <Card bordered={false} className="title-head" style={{marginTop: '40px'}}>
      <Row gutter={[12, 12]} className="api-title-head">
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 18, offset: 0 }}
          lg={{ span: 18, offset: 0 }}
        >
          <Typography.Title className="apiselection__title" level={1} strong={true}>
          Other Available APIs 
          </Typography.Title>
        </Col>

      </Row>
    </Card>
    <Form.List name="selectApiTwo">
      {fields => (
        <Fragment>
          {fields.map(field => {
            const apiName = form.getFieldValue(['selectApiTwo', field.name, 'apiName']);
            const description = form.getFieldValue(['selectApiTwo', field.name, 'description']);
            const apiId = form.getFieldValue(['selectApiTwo', field.name, 'apiId']);
            const apiStatus = form.getFieldValue(['selectApiTwo', field.name, 'apiStatus']);
            const notificationSupported = form.getFieldValue(['selectApiTwo', field.name, 'notificationSupported']);
            const tooltipText = form.getFieldValue(['selectApiTwo', field.name, 'tooltipText']);
            return (
              <div key={field.key} style={{ marginTop: 24 }}>
                {apiStatus !== API_DEVELOPMENT_STATUS && (
                  <ApiComponent
                    apiTitle={apiName}
                    apiDescription={description}
                    apiId={apiId}
                    isSelectedFormFieldPath={[field.name, 'isSelected']}
                    apiStatus={apiStatus}
                    changeSelectAllFlag={changeSelectAllFlag}
                    notificationSupported={notificationSupported}
                    tooltipText={tooltipText}
                  />
                )}
              </div>
            );
          })}
        </Fragment>
      )}
    </Form.List>
  </>
);

const ApiSelection = props => {
  const {
    selectApiForm,
    partnerType,
    apiList,
    preselectApiFlow,
    registrationFlow,
    onSelectApis,
    setCurrentPage,
    goToPreviousScreen,
    persona,
    showError
  } = props;
  const { clientConfig } = useContext(AppContext);
  const [_, forceUpdate] = useState(0); // State to force update
  const history = useHistory();

  const initialValues = {
    selectApi: [],
    selectApiTwo: [],
    selectedPersona: '',
    selectAll: false,
  };

  const backToHome = () => {
    history.push('/');
  }

  useEffect(() => {
    const selectApiFormVal = selectApiForm?.getFieldValue('selectApi');
    const selectedPersona = selectApiForm?.getFieldValue('selectedPersona');
    if (!selectApiFormVal || !selectApiFormVal?.length > 0 || isPersonaChange(partnerType, selectedPersona)) {
      constructSelectApiForm(partnerType);
    }
    if(apiList?.status === 'error') {
      showError({err: "Something went wrong. Please try again later.", backToHome: backToHome});
    }
  },[apiList]);

  

  const constructSelectApiForm = selectedPersona => {
    const secondaryApiList = registrationFlow === 'qna' ? populateSelectApiList(selectedPersona, 0) : [];
    const primaryApiList = populateSelectApiList(selectedPersona, null, secondaryApiList);

    selectApiForm.setFieldsValue({
      selectApi: primaryApiList,
      selectApiTwo: secondaryApiList,
      selectedPersona,
      selectAll: false,
    });

    // Force update
    forceUpdate(n => n + 1);
  };

  const populateSelectApiList = (selectedPersona, rank, secondaryApiList = []) => {
    const apiData = apiList?.data?.filter(api => api?.persona?.includes(selectedPersona));
    
    if (registrationFlow === 'qna' && rank === 0 && !persona?.allApis) {
      return apiData?.filter(api => api.rank == 0)?.map(api => {
        return {
          selectedPersona,
          apiId: api?.apiId,
          description: api?.apiDescription,
          apiName: api?.apiName,
          apiStatus: api?.apiStatus,
          notificationSupported: api?.notificationSupported,
          tooltipText: api.toolTip,
          isSelected: preselectApiFlow ? true : false,
        };
      });
    } else if (registrationFlow === 'qna' && rank === 0 && persona?.allApis) {
      return [];
    } else if (registrationFlow === 'qna'  && !persona?.allApis) {
        return apiData?.filter(api => api.rank !== 0)
        ?.sort((a, b) => b.rank - a.rank)
        ?.map(api => {
          return {
            selectedPersona,
            apiId: api?.apiId,
            description: api?.apiDescription,
            apiName: api?.apiName,
            apiStatus: api?.apiStatus,
            notificationSupported: api?.notificationSupported,
            tooltipText: api.toolTip,
            isSelected: preselectApiFlow ? true : false,
          };
        });
    } else if (registrationFlow === 'qna' && !persona?.allApis && secondaryApiList && secondaryApiList?.length > 0) {
      return _.intersectionWith(apiData, secondaryApiList,  (apiVal, secVal) => {
        apiVal.apiId === secVal.apiId;
      }).map(api => {
        return {
          selectedPersona,
          apiId: api?.apiId,
          description: api?.apiDescription,
          apiName: api?.apiName,
          apiStatus: api?.apiStatus,
          notificationSupported: api?.notificationSupported,
          tooltipText: api.toolTip,
          isSelected: preselectApiFlow ? true : false,
        }
      });
    }
    return apiData?.map(api => {
      return {
        selectedPersona,
        apiId: api?.apiId,
        description: api?.apiDescription,
        apiName: api?.apiName,
        apiStatus: api?.apiStatus,
        notificationSupported: api?.notificationSupported,
        tooltipText: api.toolTip,
        isSelected: preselectApiFlow ? true : false,
      };
    });
  };

  const isPersonaChange = (prev, curr) => {
    return prev !== curr;
  };

  const changeSelectAllFlag = () => {
    const formData = selectApiForm.getFieldValue('selectApi');
    const exceptDevelopmentStatusAPI = formData?.filter(data => data?.apiStatus !== API_DEVELOPMENT_STATUS);
    const disableSelectAllFlag = exceptDevelopmentStatusAPI?.some(data => !data?.isSelected);
    selectApiForm.setFieldsValue({ selectAll: !disableSelectAllFlag });
  };

  const changeSelectAllFlag2 = () => {
    const formData = selectApiForm.getFieldValue('selectApiTwo');
    const exceptDevelopmentStatusAPI = formData?.filter(data => data?.apiStatus !== API_DEVELOPMENT_STATUS);
    const disableSelectAllFlag = exceptDevelopmentStatusAPI?.some(data => !data?.isSelected);
    selectApiForm.setFieldsValue({ selectAll: !disableSelectAllFlag });
  };

  const selectAllApiHandler = () => {
    const formData = selectApiForm.getFieldValue('selectApi');
    const selectAllFlag = selectApiForm.getFieldValue('selectAll') || false;
    const updateFormData = formData?.map(api => {
      if (api?.apiStatus !== API_DEVELOPMENT_STATUS) {
        api.isSelected = selectAllFlag;
      }
      return api;
    });
    // const isSelectAll = updateFormData.isSelected === true ? true : false;
    selectApiForm.setFieldsValue({ selectApi: updateFormData });
  };

  return (
    <Fragment>
      <Form
        form={selectApiForm}
        layout="vertical"
        name="selectApiData"
        initialValues={initialValues}
        onFinish={values => onSelectApis(values)}
        hideRequiredMark={true}
        css={ApiSelectionCss}
        scrollToFirstError
      >
        <Fragment>
          <Card bordered={false} className="title-head">
            <Row gutter={[12, 12]} className="api-title-head">
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 18, offset: 0 }}
                lg={{ span: 18, offset: 0 }}
              >
                <Typography.Title className="apiselection__title" level={1} strong={true}>
                APIs That Suit Your Business
                </Typography.Title>
                <Typography.Title className="apiselection__description" level={3} strong={true}>
                  Unlock your business potential with our seamless API integration
                </Typography.Title>
              </Col>

            </Row>
          </Card>
          <Form.List name="selectApi">
            {fields => (
                <Fragment>
                  {fields.map(field => {
                    const apiName = selectApiForm.getFieldValue(['selectApi', field.name, 'apiName']);
                    const description = selectApiForm.getFieldValue(['selectApi', field.name, 'description']);
                    const apiId = selectApiForm.getFieldValue(['selectApi', field.name, 'apiId']);
                    const apiStatus = selectApiForm.getFieldValue(['selectApi', field.name, 'apiStatus']);
                    const notificationSupported = selectApiForm.getFieldValue([
                      'selectApi',
                      field.name,
                      'notificationSupported',
                    ]);
                    const tooltipText = selectApiForm.getFieldValue(['selectApi', field.name, 'tooltipText']);
                    return (
                      <div key={field.key} style={{ marginTop: 24 }}>
                        {apiStatus !== API_DEVELOPMENT_STATUS && (
                          <ApiComponent
                            apiTitle={apiName}
                            apiDescription={description}
                            apiId={apiId}
                            isSelectedFormFieldPath={[field.name, 'isSelected']}
                            apiStatus={apiStatus}
                            changeSelectAllFlag={changeSelectAllFlag}
                            notificationSupported={notificationSupported}
                            tooltipText={tooltipText}
                          />
                        )}
                      </div>
                    );
                  })}
                </Fragment>
              )}
          </Form.List>
          { 
            (() => {
              const selectApiTwoValue = selectApiForm.getFieldValue('selectApiTwo');
              return selectApiTwoValue && selectApiTwoValue.length > 0 && (
                <NestedForm 
                  form={selectApiForm} 
                  apiList={apiList?.data} 
                  changeSelectAllFlag={changeSelectAllFlag2}
                />
              );
            })()
           }
           <Form.Item>
            <div css={footerSection} className="getstarted-footer">
              <Card bordered={false} className="nextButton__card">
                <Row gutter={16}>
                  <Col span={12}>
                    <Button type="app-secondary" className="nextButton" onClick={goToPreviousScreen}>
                      Previous
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button className="nextButton" htmlType="submit">
                      Next
                    </Button>
                  </Col>
                </Row>
              </Card>
            </div>
          </Form.Item>
        </Fragment>
        {clientConfig?.termsAndConditionToggle && (
          <LicenseAgreement isAgreementSelected={selectApiForm.getFieldInstance('isAgreementSelected')} />
        )}
      </Form>
    </Fragment>
  );
};

export default ApiSelection;

ApiSelection.propTypes = {
  selectApiForm: object,
  partnerType: string,
  apiList: object,
  backToLegalEntity: func,
  nextToOverview: func
};
