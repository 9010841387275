import { css } from '@emotion/core';
export const HeaderCss = css`
  .ant-menu-horizontal {
    border-bottom: none;
  }

  .img-logo {
    @media (max-width: 1024px) {
      width: 280px;
    }
    @media(max-width: 480px) {
      width: 300px;
    }
    @media(max-width: 370px) {
      width: 270px;
    }
  }

  .align-right {
    display: flex;
    justify-content: flex-end;
  }

  .lock-icon {
    padding: 0 10px;
  }

  .hamburger__menu span {
    font-size: 30px;
    line-height: 3;
  }

  .menu_icon_container {
    @media (min-width: 992px) {
      display: none;
    }
  }

  .header-btn-container {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 992px) {
      display: none;
    }
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: #1354e9;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active {
    color: #1354e9;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 2px solid #1355E9;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
    color: #1354e9;
  }

  .menu__item {
    font: normal normal bold 18px/52px Lato;
    letter-spacing: -0.45px;
    color: #666666;
    opacity: 1;
  }

  .menu__item:hover {
    color: #1354e9;
    ${'' /* border-bottom: 2px solid #1355E9 */}
  }

  .ant-menu-item-selected {
    font: normal normal bold 18px/52px Lato;
    letter-spacing: -0.45px;
    color: #1354e9;
    opacity: 1;
  }

  &.header__container {
    background: white;
    position: fixed;
    z-index: 9;
    width: 100vw;
  }

  .align-center {
    text-align: center;
  }

  .col-right {
    display: flex;
    justify-content: flex-end;
  }

  .header_btn {
    font-family: Elevance Sans;
    font-size: 1rem !important;
    font-weight: 600 !important;
    line-height: 1.5rem;
    width: 8.375rem;
    height: 3.25rem;
    padding: 0.5 rem;
    border-radius: 6.25rem;
  }

  .header__login_btn {
    color: #286ce2;
    border: 2px solid #1355E9;
    border-radius: 6.25rem;
    width: 8.375rem;
    height: 3.25rem;
  }

  .header__login_btn:hover {
    background-color: #2453a6;
    color: white;
    .anticon-lock {
      color: white;
    }
  }

  .header__register-btn {
    background-color: #1354e9;
    color: white;
  }

  .landing_menu_item {
    font-family: Elevance Sans;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
  }

  .exitButton {
    font-family: Elevance Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 18.24px;
    letter-spacing: -0.02em;
    text-align: center;
    text-decoration: underline;
    color: #286CE2;
  }

  .exitButton-header {
    position: relative;
  }
`;

export const userDropDownMenuCSS = {
  width: '1000px',
  padding: '24px',
  position: 'fixed',
};

export const AgencyListCSS = css`
  .agency__api-type {
    background: inherit;
    text-align: left;
    font-size: 10px;
    font-weight: 500;
  }

  .api__li_option {
    cursor: pointer;
  }
  .agency__api-img {
    height: 25px;
    width: 25px;
    margin-top: 5px;
  }

  .agency__api-name {
    background: inherit;
    text-align: left;
    font-size: 12px;
    font-weight: 700;
  }

  li {
    list-style: none;
  }

  .agency__li {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-gap: 20px;
  }

  .ant-card-body {
    padding: 24px 0px;
  }

  .ant-card-head-title {
    padding-left: 20px;
    font-weight: 600;
    padding-bottom: 0px;
    font-size: 15px;
  }

  .ant-card-head {
    border-bottom: none;
  }
`;

export const CommercialListCSS = css`
  .ant-card-body {
    padding: 24px 0px;
  }

  li {
    list-style: none;
  }

  .ant-card-head-title {
    padding-left: 20px;
    font-weight: 600;
    padding-bottom: 0px;
    font-size: 15px;
  }

  .ant-card-head {
    border-bottom: none;
  }

  .commercial__api-name {
    background: inherit;
    text-align: left;
    font-size: 12px;
    font-weight: 700;
  }

  .commercial__api-type {
    background: inherit;
    text-align: left;
    font-size: 10px;
    font-weight: 500;
  }

  .commercial__api-img {
    height: 25px;
    width: 25px;
    margin-top: 5px;
  }

  .commercial__li {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: 20px;
  }
`;

export const DividerCSS = css`
  .ant-col-lg-24 {
    border-top: 1px solid lightgrey;
  }

  .ant-col-lg-8 {
    border-right: 1px solid lightgrey;
  }
`;

export const BrowseAllAPIsButtonCSS = css`
  .browse-all-apis-btn {
    margin: 15px 10px 10px 0px;
    background: #e95a3c;
    border: none;
    color: white;
    border-radius: 0px;
    font-family: Lato;
    float: right;
    height: 40px;
  }
`;

export const CardCSS = css`
  border: 1px solid lightgrey;
`;

export const APIListMobileViewCSS = css`
  @media (max-width: 768px) {
    .hamburger_menu__api-name {
      font-family: Montserrat;
      margin-left: 10px;
      background: inherit;
      text-align: left;
      font-size: 12px;
      font-weight: 700;
    }

    .hamburger_menu__api-img {
      margin-top: 5px;
      height: 25px;
      width: 25px;
    }

    .hamburger_menu___item span {
      padding: 0 5px;
    }
  }
`;

export const menuBar = css`
  line-height: 35px !important;
  text-align: left;
  font-weight: 400;
  font-family: lato;
  margin-bottom: 5px;
`;
export const HamburgerCSS = css`
  .barsMenu {
    float: right;
    height: 32px;
    padding: 6px;
    margin-top: 15px;
    margin-right: 15px;
    display: none;
    background: none;
    border: none;
  }

  .barsBtn {
    display: block;
    width: 20px;
    height: 2px;
    background: #1354e9;
    position: relative;
  }

  .barsBtn:after,
  .barsBtn:before {
    content: attr(x);
    width: 20px;
    position: absolute;
    top: -6px;
    left: 0;
    height: 2px;
    background: #1354e9;
  }

  .barsBtn:after {
    top: auto;
    bottom: -6px;
  }

  .barsMenu > span {
    display: block;
  }

  @media (max-width: 768px) {
    .barsMenu {
      display: inline-block;
    }
    .ant-drawer-body {
      padding: 24px 0;
    }
  }
  @media (width: 768px) {
    .barsMenu {
      display: inline-block;
      float: none;
    }
    .ant-drawer-body {
      padding: 24px 0;
    }
  }
`;
