import React, { Fragment, useEffect } from 'react';
import { Typography, Card, Divider, Row, Col } from 'antd';
import { css } from '@emotion/core';
import MailImg from './../../common/img/new-public-Ui/open_mail.svg';
import { useLocation } from 'react-router-dom';

export const greetingCardHeader = css`
  padding: 3em 3.8em 2em;
  background: inherit;
  .ant-card-body {
    padding: 0px;
  }
  h2.username {
    font-weight: 300;
    text-transform: capitalize;
    margin-top: 0.5em;
  }
  h3.description {
    font-weight: 300;
    margin-top: 35px;
    background: inherit;
    color: white;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }
`;

export const cssContent = css`
  background: #ffffff;
  background-size: 100% 200px;
  padding: 0 100px;
  @media (max-width: 1024px) {
    padding: 0 10px;
  }
  .ant-card-bordered {
    border: 1px solid #ffffff;
  }
`;

const registerCss = css`
  .ant-result-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 23px;
    line-height: 1.8;
    text-align: center;
    font-family: Elevance Sans;
  }

  span.ant-typography {
    font-family: Elevance Sans;
  }

  .ant-result-info .ant-result-icon > .anticon {
    color: #286ce2 !important;
  }

  .ant-result-icon > .anticon {
    font-size: 65px;
  }

  .ant-divider {
    border: none;
  }

  .ant-typography + h1.ant-typography,
  .ant-typography + h2.ant-typography,
  .ant-typography + h3.ant-typography,
  .ant-typography + h4.ant-typography {
    margin-top: none !important;
  }

  h1.ant-typography,
  .ant-typography h1 {
    margin-bottom: 2em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 30px;
    line-height: 1.23;
  }

  #resend-email-btn {
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    .ant-result-icon span img {
      height: 65px;
    }

    h1.ant-typography,
    .ant-typography h1 {
      font-size: 20px;
    }
    .ant-result-extra .ant-typography {
      font-size: 12px;
    }
  }
  .sub-line-text {
    border-style: ridge;
    padding: 20px;
  }
  .main-line-text {
    font-size: 20px;
    margin: 20px 0;
    color: #333333;
  }
  .pat-app-xetcu2-registerCss span.ant-typography {
    font-family: Elevance Sans;
    font-weight: 400;
  }
     @media (max-width: 420px) {
     .sub-line-text {
    border-style: ridge;
    padding: 20px 2px;
  }
     }
`;

const RegistrationDone = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const myParam = location.state;

  // let str = myParam?.update;
  // str = str.split('');
  // let finalArr = [];
  // let len = str.indexOf('@');
  // str.forEach((item, pos) => {
  //   pos >= 1 && pos <= len - 2 ? finalArr.push('*') : finalArr.push(str[pos]);
  // });
  return (
    <Fragment>
      <div css={cssContent}>
        <Card css={greetingCardHeader} bordered={false}></Card>
        <Card css={registerCss}>
          <div className="ant-result ant-result-info">
            <div className="ant-result-icon">
              <span role="img" aria-label="check-circle" className="anticon anticon-check-circle">
                <img src={MailImg}></img>
              </span>
            </div>
            <div className="ant-result-title">
              <Fragment>
                <Typography.Title>You are almost there!</Typography.Title>
              </Fragment>
            </div>
            <div className="ant-result-extra">
              <Fragment>
                <Row gutter={24} justify="center">
                  <Col md={24}>
                    <Typography.Text className="main-line-text">
                      Please check your email for the verification message and click 'Verify email' link to complete the
                      registration.
                    </Typography.Text>
                    <Divider style={{ margin: '20px' }} />
                    <Typography.Text className="sub-line-text">
                      <b>Subject Line:</b> Partner API Email Verification
                    </Typography.Text>
                    <Divider style={{ margin: '25px' }} />
                    <Typography.Text>Please note:</Typography.Text>
                    <Typography.Text> The verification link is valid only for 24 hours.</Typography.Text>
                    <Divider style={{ margin: '2px' }} />
                  </Col>
                </Row>
              </Fragment>
            </div>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

export default RegistrationDone;
