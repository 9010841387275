import { func, object, array, int } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Divider, Popconfirm } from 'antd';
import { OverviewCss } from './Overview.style';
import { SecondaryButton } from 'Client/components/Buttons/SecondaryButton';
import Button from 'Client/components/Buttons/Button';

const Overview = props => {
  const {
    commercial,
    agency,
    initialValues,
    apiFormDataLength,
    finalSubmission,
    backToApiSelection,
    selectApiForm,
    backToLegalEntity
  } = props;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1366);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1366);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const partnerTypeMapping = {
    'BROKER': 'Agency',
    'BENADMIN': 'Benefit Administrator',
    'TECHPLATFORM': 'Technology Partner'
  };

  const apiLength = `Selected APIs (${apiFormDataLength})`;
  return (
    <div css={OverviewCss}>
      <Row>
        <h1 className="h1_heading">Review and confirm your information</h1>
      </Row>
      <Row gutter={[16,16]} justify="space-between">
        <Col sm={24} md={24} lg={10} xl={10}>
          <Row gutter={[32,32]}>
            <Col span={24}>
                <Card
                title="Legal Entity"
                //size="small"
                extra={<img 
                    src={require(`./../../common/img/CreateNew.svg`).default}
                    onClick={backToLegalEntity}
                  />
                 }
                className="legal_Entity"
               />
            </Col>
            <Col span={24}>
              <Row justify="space-between" gutter={[16,16]}>
                <Col span={24}>
                  <p>
                    <span className="overview-title">Partner Type</span>
                    <br></br> <span className="overview-value">{partnerTypeMapping[initialValues.type]}</span>
                  </p>
                </Col>
                <Col span={24}>
                  <p>
                    <span className="overview-title">Company Name</span>
                    <br></br> <span className="overview-value">{initialValues.companyName}</span>
                  </p>
                </Col>
                {
                  initialValues.type === 'BROKER' && (
                  <>
                    <Col span={24}>
                      <p>
                        <span className="overview-title">Producer Toolbox Username</span>
                        <br></br> <span className="overview-value">{initialValues?.bpUserId ? initialValues?.bpUserId : '-'}</span>
                      </p>
                    </Col>
                    <Col span={24}>
                      <p>
                        <span className="overview-title">Tax ID Number</span>
                        <br></br> <span className="overview-value">{initialValues?.bpTaxId ? initialValues?.bpTaxId : '-'}</span>
                      </p>
                    </Col>
                  </>
                )}
                
                <Col span={24}>
                  <hr className="separator-hr"></hr>
                </Col>
                <Col span={10}>
                  <p>
                    <span className="overview-title">First Name</span>
                    <br></br> <span className="overview-value">{initialValues.firstName}</span>
                  </p>
                </Col>
                <Col span={10}>
                  <p>
                    <span className="overview-title">Last Name</span>
                    <br></br> <span className="overview-value">{initialValues.lastName}</span>
                  </p>
                </Col>
                <Col span={24}>
                  <p>
                    <span className="overview-title">Email</span>
                    <br></br> <b className="emailValues">{initialValues.email}</b>
                  </p>
                </Col>
                <Col span={20}>
                  <p>
                    <span className="overview-title">Phone no</span>
                    <br></br> <span className="overview-value">{initialValues.phoneNumber}</span>
                  </p>
                </Col>
                <Col span={24}>
                  <hr className="separator-hr"></hr>
                </Col>
                <Col span={10}>
                  <p>
                    <span className="overview-title">Address</span>
                    <br></br> <span className="overview-value">
                      {`${initialValues.addressLine1} `} 
                      { (initialValues.addressLine2) ? `${initialValues.addressLine2}` : ''} 
                    </span>
                  </p>
                </Col>
                <Col span={10}>
                  <p>
                    <span className="overview-title">State</span>
                    <br></br> <span className="overview-value">{initialValues.state}</span>
                  </p>
                </Col>
                <Col span={10}>
                  <p>
                    <span className="overview-title">City</span>
                    <br></br> <span className="overview-value">{initialValues.city}</span>
                  </p>
                </Col>
                <Col span={10}>
                  <p>
                    <span className="overview-title">Zip code</span>
                    <br></br> <span className="overview-value">{initialValues.zipCode}</span>
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col sm={24} md={24} lg={10} xl={10} style={isMobile && {marginTop: 50}}>
          <Row gutter={[32,32]}>
            <Col span={24}>
              <Card
              title={apiLength}
              //bordered={false}
              extra={<img 
                  src={require(`./../../common/img/CreateNew.svg`).default} 
                  onClick={backToApiSelection}
                />}
              className="legal_Entity"
              />
            </Col>
            <Col span={24}>
              <Row gutter={[16,16]}>
                {
                  agency.length > 0 && (
                    <Col span={24}>
                      <Card className="apicard agencyCard">
                        <p className="agency-management">Agency Management API</p>
                        <Row gutter={[16,16]}>
                          {
                            agency &&
                            agency.map(agencyAPI => {
                              return (
                                <Col span={24} key={agencyAPI}>
                                  <div className="list">
                                    <span className="tick_icon">
                                      <img src={require(`./../../common/img/check-circle.svg`).default} />
                                    </span>
                                    <span className="list-items">{agencyAPI}</span>
                                  </div>
                                </Col>
                              );
                            })}
                        </Row>
                      </Card>
                    </Col>
                )}
                <div className="divide"></div>
                {
                  commercial.length > 0 && (
                  <Col span={24}>
                    <Card className="apicard commercialCard">
                      <p className="commercial">Commercial Group Submission and Member Management</p>
                      <Row gutter={[16,16]}>
                        {
                          commercial &&
                          commercial.map(commercial => {
                            return (
                              <Col span={24} key={commercial}>
                                <div className="list">
                                  <span className="tick_icon">
                                    <img src={require(`./../../common/img/check-circle.svg`).default} />
                                  </span>
                                  <span className="list-items">{commercial}</span>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                    </Card>
                  </Col>
                )}
                </Row>
              </Col>
          </Row>
        </Col>
      </Row>
      <div className="space-btw-note"> </div>
      <Row style={{ margin: '20px 0'}}>
        <Col sm={24} md={24} lg={24} xl={24}>
          <div className="info-container">
            <div className="info-icon">i</div>
            <div className="info-text">Note: You will receive the verification email after submitting this form.</div>
          </div>
        </Col>
      </Row>
      <div className="space-btw-note"> </div>
      {/* <hr className="separator-hr" /> */}
      <Row>
        <Col sm={24} md={24} lg={8} xl={8}></Col>
        <Col sm={24} md={24} lg={16} xl={16}>
          <Row>
            <Col sm={24} md={24} lg={8} xl={8}></Col>
            <Col sm={24} md={24} lg={16} xl={16}>
              <div className="button-container">
                <Button  type="app-secondary" className="nextButton" onClick={backToLegalEntity}>
                  Previous
                </Button>
                <Button
                  style={{ borderRadius: 25, width: 165, margin: 10 }}
                  type="app-primary"
                  className="review-primary-button"
                  htmlType="submit"
                  onClick={() => finalSubmission(selectApiForm)}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Overview;

Overview.propTypes = {
  backToApiSelection: func,
  finalSubmission: func,
  initialValues: object,
  apiList: array,
  commercial: array,
  agency: array,
  apiFormDataLength: int,
  selectApiForm: array,
  backToLegalEntity: func,
};
