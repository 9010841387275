import React, { Fragment, useEffect } from 'react';
import { Typography, Card, Divider, Row, Col } from 'antd';
import { css } from '@emotion/core';
import VerifiedImg from './../../common/img/Verified.svg';

export const greetingCardHeader = css`
  padding: 3em 3.8em 2em;
  background: inherit;
  .ant-card-body {
    padding: 0px;
  }
  h2.username {
    font-weight: 300;
    text-transform: capitalize;
    margin-top: 0.5em;
  }
  h3.description {
    font-weight: 300;
    margin-top: 35px;
    background: inherit;
    color: white;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }
`;

export const cssContent = css`
  background-size: 100% 200px;
  padding: 100px;
  margin: 4em 0;
  @media (max-width: 1024px) {
    padding: 0 10px;
  }
`;

const alreadyVerifiedCss = css`
  .ant-result-extra {
    font-family: Elevance Sans;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0;
    text-align: center;
    color: #333333;
  }
  
  

  .ant-result-info .ant-result-icon > .anticon {
    color: #286ce2 !important;
  }

  .ant-result-icon > .anticon {
    font-size: 65px;
  }

  .ant-divider {
    border: none;
  }

  .ant-typography + h1.ant-typography,
  .ant-typography + h2.ant-typography,
  .ant-typography + h3.ant-typography,
  .ant-typography + h4.ant-typography {
    margin-top: none !important;
  }

  .ant-result-title {
    font-family: Elevance Sans;
    font-weight: 600;
    font-size: 28px;
    line-height: 36.48px;
    letter-spacing: 0;
    text-align: center;
    color: #333333;
  }

  #resend-email-btn {
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    .ant-result-icon span img {
      height: 65px;
    }

    .ant-result-title {
      font-size: 16px;
    }
    .ant-result-extra .ant-typography {
      font-size: 12px;
    }
  }
`;

const AlreadyVerified = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <div css={cssContent}>
        <Card css={alreadyVerifiedCss}  bordered={false}>
          <div className="ant-result ant-result-info">
            <div className="ant-result-icon">
              <span role="img" aria-label="check-circle" className="anticon anticon-check-circle">
                <img src={VerifiedImg}></img>
              </span>
            </div>
            <div className="ant-result-title">
              You have already verified your email address with us.
            </div>
            <div className="ant-result-extra">
              <Row gutter={24} justify="center">
                <Col md={24}>
                    Please wait while our business team reviews your details and get back to you soon.
                  <Divider style={{ margin: '20px' }} />
                </Col>
              </Row>
            </div>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

export default AlreadyVerified;
