import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { Input, Col, Form, Card, Row, Select } from 'antd';
import Button from './../../components/Buttons/Button';
import { object } from 'prop-types';
import { Personas, PARTNER_WARNING_DESCRIPTION } from './../../common/constants/AppConstants';
import { LegalEntityCss } from './LegalEntityForm.style';
import states from './../../common/constants/states.json';
import ModalWrapper from './../../components/ModalWrapper/ModalWrapper';
import WarningNotification from 'Client/components/WarningNotification/WarningNotification';
import { SecondaryButton } from 'Client/components/Buttons/SecondaryButton';
import { footerSection } from '../RecommendedApiList/QuestionCard.style';

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const phoneNumberMaskHandler = event => {
  const value = event.target.value;
  return value.replace(phoneRegex, '$1-$2-$3');
};

const validateZipCode = (rule, value) => {
  const zipRegex = /\b^[0-9]{5}$\b/;
  if (!value) {
    return Promise.reject('Please enter your zip code');
  }
  if (!zipRegex.test(value)) {
    return Promise.reject('Zip Code must be a 5 digit Number');
  } else {
    return Promise.resolve();
  }
};

const LegalEntityForm = props => {
  const { legalInfoForm, selectApiForm, personaType, goToApiSelection } = props;
  const [showPartnerWarningModal, setShowPartnerWarningModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1366);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1366);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const stateOptions = useMemo(() => {
    const options = Object.keys(states).map(x => ({ label: states[x], value: x }));
    return [...options];
  }, []);

  const resetLegalEntityData = type => {
    legalInfoForm?.setFieldsValue({
      bpUserId: '',
      taxId: '',
      type,
    });
  };

  const modalOnCancelButtonHandler = () => {
    legalInfoForm?.setFieldsValue({
      type: personaChange,
    });
    setShowPartnerWarningModal(false);
  };

  const modalOnOkButtonHandler = () => {
    const type = legalInfoForm.getFieldValue('type');
    selectApiForm.resetFields();
    resetLegalEntityData(type);
    setShowPartnerWarningModal(false);
  };


  return (
    <div css={LegalEntityCss}>
      <Fragment>
        <Row>
          <Col sm={24} xs={24} md={24} lg={11} xl={11} className="companyDetails" 
            style={
              (personaType != Personas.BROKER && !isMobile) ? {padding: '35px 0'} : (isMobile) ?
              {padding: '20px'} : {padding: '0 0'}}>
            <Row gutter={
                isMobile ? 0 : (personaType == Personas.BROKER) ? [16,0] : [16,0]
              }
              justify="center"
            >
              <Col sm={22} xs={22} md={24} lg={24} xl={24}>
                <h3 className="formHeading">Company Details</h3>
              </Col>
              <Col sm={22} xs={22} md={24} lg={24} xl={24}>
                <Form.Item
                  name="companyName"
                  label={<span className="formItem">Company Name</span>}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your company name',
                    },
                  ]}
                >
                  <Input size="small" placeholder="Company Name" className="input" />
                </Form.Item>
              </Col>

              {personaType === Personas?.BROKER && (
                <Col sm={22} xs={22} md={24} lg={24} xl={24}>
                  <Row>
                    <Col sm={24} xs={24} md={11} lg={11} xl={11}>
                      <Form.Item
                        name="bpUserId"
                        label={<span className="formItem">Producer Toolbox Username</span>}
                        rules={[
                          {
                            message: 'Please enter either your Producer Toolbox username or your Tax ID number',
                          },
                        ]}
                      >
                        <Input size="small" placeholder="Producer Toolbox Username" className="input" />
                      </Form.Item>
                    </Col>
                    <Col sm={2} xs={2} md={2} lg={2} xl={2} className="or">
                      <span>OR</span>
                    </Col>
                    <Col sm={22} xs={22} md={11} lg={11} xl={11}>
                      <Form.Item
                        name="bpTaxId"
                        label={<span className="formItem">Tax ID Number</span>}
                        rules={[{ pattern: /^\d{9}$/, message: 'Please enter either your Producer Toolbox username or your Tax ID number' }]}
                      >
                        <Input size="small" placeholder="Tax ID Number" className="input" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}

              <Col sm={22} xs={22} md={24} lg={24} xl={24}>
                <Form.Item
                  name="addressLine1"
                  label={<span className="formItem">Address Line1</span>}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your address line1',
                    },
                  ]}
                >
                  <Input size="small" placeholder="Address Line 1" className="input" />
                </Form.Item>
              </Col>
              <Col sm={22} xs={22} md={24} lg={24} xl={24}>
                <Form.Item
                  name="addressLine2"
                  label={<span className="formItem">Address Line2 (Optional)</span>}
                  rules={[
                    {
                      message: 'Please enter your address line 2',
                    },
                  ]}
                >
                  <Input size="small" placeholder="Address Line 2" className="input" />
                </Form.Item>
              </Col>
              <Col xs={22} sm={22} md={24} lg={24} xl={24}>
                <Row justify="space-between">
                <Col sm={22} xs={22} md={24} lg={7} xl={7}>
                    <Form.Item
                      label={<span className="formItem">City</span>}
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your city',
                        },
                      ]}
                    >
                      <Input size="small" placeholder="City" className="input" autoComplete="off" id="city" />
                    </Form.Item>
                  </Col>
                  <Col sm={22} xs={22} md={24} lg={7} xl={7}>
                    <Form.Item
                      name="state"
                      label={<span className="formItem">State</span>}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your state',
                        },
                      ]}
                    >
                      <Select
                        size="small"
                        placeholder={<span className="placeholderText">State</span>}
                        showSearch
                        optionFilterProp={'label'}
                        notFoundContent={null}
                        options={stateOptions}
                        autoComplete="off"
                        id="state-input"
                        className="stateSelection"
                      />
                    </Form.Item>
                  </Col>
                  
                  <Col sm={22} xs={22} md={24} lg={7} xl={7}>
                    <Form.Item
                      label={<span className="formItem">Zip Code</span>}
                      name="zipCode"
                      rules={[{ required: true, validator: validateZipCode }]}
                    >
                      <Input size="small" placeholder="Zipcode" className="input" autoComplete="off" id="zip-code" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col sm={24} xs={24} md={24} lg={1} xl={1}></Col>
          <Col sm={24} xs={24} md={24} lg={12} xl={12} className="contactPerson" style={{ backgroundColor: '#F0F0F0', 
              padding:  personaType == Personas.BROKER ?  '35px 45px 60px 45px' : '35px 45px 40px 45px'
            }}>
            <Row>
              <h3 className="formHeading">Contact Person</h3>
            </Row>
            <Row>
              <Col sm={24} xs={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="firstName"
                  label={<span className="formItem">First Name</span>}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please enter your first name',
                    },
                    {
                      pattern: /^[A-Za-z ]+$/,
                      message: 'Please enter alphabets in first name',
                    },
                  ]}
                >
                  <Input size="small" placeholder="First Name" className="input" />
                </Form.Item>
              </Col>
              <Col sm={24} xs={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="lastName"
                  label={<span className="formItem">Last Name</span>}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please enter your last name',
                    },
                    {
                      pattern: /^[A-Za-z ]+$/,
                      message: 'Please enter alphabets in last name',
                    },
                  ]}
                >
                  <Input size="small" placeholder="Last Name" className="input" />
                </Form.Item>
              </Col>
              <Col sm={24} xs={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="email"
                  label={<span className="formItem">Email</span>}
                  rules={[
                    {
                      type: 'email',
                      message: 'Please enter a valid email',
                    },
                    {
                      required: true,
                      message: 'Please enter your email',
                    },
                  ]}
                >
                  <Input size="small" placeholder="Email" className="input" />
                </Form.Item>
              </Col>
              <Col sm={24} xs={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label={<span className="formItem">Phone Number</span>}
                  labelCol={{ span: 24 }}
                  name="phoneNumber"
                  getValueFromEvent={phoneNumberMaskHandler}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your phone number',
                    },
                    {
                      pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                      message: 'Please enter a valid phone number',
                    },
                  ]}
                >
                  <Input
                    size="small"
                    placeholder="Phone Number"
                    className="input"
                    autoComplete="off"
                    id="phone-number"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="space-btw-note"> </div>
        <Row>
        <div css={footerSection} className="getstarted-footer">
          <Card bordered={false} className="nextButton__card">
            <Row gutter={16}>
              <Col span={12}>
                <Button  type="app-secondary" className="nextButton" onClick={goToApiSelection}>
                  Previous
                </Button>
              </Col>
                <Col span={12}>
                  <Button className="nextButton reviewButton" htmlType="submit">
                    Review & Confirm
                  </Button>
                </Col>
            </Row>
          </Card>
        </div>
          {/* <Col sm={24} xs={24} md={24} lg={10} xl={10}></Col>
          <Col sm={24} xs={24} md={24} lg={14} xl={14}>
            <Row>
              <Col sm={24} xs={24} md={24} lg={6} xl={6}></Col>
              <Col sm={24} xs={24} md={24} lg={18} xl={18}>
                <div className="button-container">
                  <SecondaryButton className="form-secondary-button" onClick={goToApiSelection}>
                    Previous
                  </SecondaryButton>
                  <Button type="app-primary" className="form-primary-button" htmlType="submit">
                    Review and confirm
                  </Button>
                </div>
              </Col>
            </Row>
          </Col> */}
        </Row>

        <ModalWrapper visible={showPartnerWarningModal}>
          <WarningNotification
            okBtnText={'Yes, change partner type'}
            cancelBtnText={'No, continue as current partner'}
            cancelButtonHandler={modalOnCancelButtonHandler}
            okButtonHandler={modalOnOkButtonHandler}
            description={PARTNER_WARNING_DESCRIPTION}
          />
        </ModalWrapper>
      </Fragment>
    </div>
  );
};
LegalEntityForm.propTypes = {
  legalInfoForm: object,
  selectApiForm: object,
};

export default LegalEntityForm;
