import { css } from '@emotion/core';
import FOOTER_MOBILE_BANNER from './../../common/img/new-public-Ui/banner.png';
import images_getStarted from './../../common/img/new-public-Ui/background_getstarted.svg';
import agency_image from './../../common/img/new-public-Ui/agencies.png';
import benAdmin_images from './../../common/img/new-public-Ui/ben_admin.png';
import tech_platform_images from './../../common/img/new-public-Ui/tech_platform.png';
import API_FLOW from './../../common/img/new-public-Ui/api_flow.png';

export const SectionStepsCss = css`


  background-color: #e3ecfd;

  .section__steps1 {
    margin-top: 50px;
    font-family: Elevance Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
    color: #1355E9;
  }

  .section__steps2 {
    font-family: Elevance Sans;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
    color: #231E33;

    @media(max-width: 850px) {
      font-size: 24px;
    }
  }

  .api_steps_flow {
    font-size: 14px;
    font-weight: bold;
    padding: 10px 15%;

    @media(max-width: 1024px) {
      padding: 7px 5%;
    }

    @media(max-width: 600px) {
      padding-bottom: 1%;
    }

    .ant-steps-item-container > .ant-steps-item-tail::after {
      border: .05rem dashed #396CE8;
    }

    .ant-steps-item-icon {
      background: #286CE2;
      font-family: Lato;
      width: 2.5625rem;
      height: 2.6237rem;
      top: 119.4463rem;
      left: 28.4375rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-steps-icon {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.5rem;
      color: #FFFFFF;
    }

    .ant-steps-item-title {
      font-family: Elevance Sans;
      font-size: 24px;
      font-weight: 600;
      line-height: 27.36px;
      text-align: center;
      color: #231E33 !important;

      @media(max-width: 1024px) {
        font-family: Elevance Sans;
        font-size: 20px;
        font-weight: 600;
        line-height: 22.8px;
      }

      @media(max-width: 850px) {
        font-family: Elevance Sans;
        font-size: 20px;
        font-weight: 600;
        line-height: 22.8px;
      }

      @media(max-width: 850px) {
        font-family: Elevance Sans;
        font-size: 18px;
        font-weight: 600;
        line-height: 21.8px;
      }
    }

    .ant-steps-item-description {
      font-family: Elevance Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      padding-top: 1.25rem;
      color: #231E33 !important;

      @media(max-width: 1024px) {
        font-size: 14px;
      }
    }
  }
  
  .ant-steps-item-description {
    color: black !important;
    font-size: 14px;
    font-weight: 400;
  }
  .ant-steps-label-vertical .ant-steps-item-icon {
    margin-left: 130px;
  }
  .ant-steps-label-vertical .ant-steps-item-tail {
    margin-left: 146px;
  }
  .ant-steps-label-vertical .ant-steps-item-content {
    width: 300px;
  }
  .player-wrapper {
    position: relative;
    margin-top: 3rem;
    padding-top: 37%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .player-thumbnail {
    width: inherit;
  }
`;

export const apiFlowSection = css`
  .apiFlow_image {
    height: 100%;
    width: 100%;
  }

  .mobile-only {
    display: none;
   }
    .hide-on-mobile {
  display: block;
}

  @media(max-width: 480px) {
   .mobile-only {
    display: block;
   }
    .hide-on-mobile {
  display: none;
}
  }

`;

export const recommded_api = css`
  background: #f4f5f7;
  padding: .3125rem 9.375rem 5.125rem;

  @media(max-width: 1024px) {
    padding: .3125rem 4.375rem 5.125rem;
  }

  @media(max-width: 820px) {
    padding: .3125rem 2rem 3.125rem;
  }

  @media(max-width: 480px) {
    padding: .3125rem 1rem 3.125rem;
  }

  .requestapi-title {
    font-family: Elevance Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.2888889014720917px;
    text-align: left;
    color: #333333;
  }

  .section__welcome_line1 {
    margin-top: 50px;
    color: #1355E9;
    font-family: Elevance Sans;
    font-size: 1rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: center;
  }

  .section__welcome_line2 {
    font-family: Elevance Sans;
    font-size: 32px;
    font-weight: 600;
    line-height: 3rem;
    text-align: center;
    color: #333333;

    @media(max-width: 850px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .partner-cover {
    width: 22.5625rem;
    height: 11.9375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: contain; 

    @media(max-width: 1024px) {
      width: inherit;
      height: 150px;
    }

    @media(max-width: 480px) {
      width: inherit;
    }

    @media(max-width: 414px) {
      width: inherit;
      height: 140px;
    }
  }

  .partner-cover-text {
    font-family: Elevance Sans;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: #FFFFFF;
  }

  .requestapilist {
    min-height: 280px;

    @media(max-width: 820px) {
      min-height: 315px;
    }

    p {
      font-family: Elevance Sans;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.625rem;
      color: #333333;
    }
  }

  .preselectapi-card {
    width: 361px;
    height: 600px;

    @media(max-width: 1024px) {
      width: 280px;
    }

    @media(max-width: 820px) {
      width: 600px;
      height: 610px;
    }

    @media(max-width: 480px) {
      width: 300px;
    }

    @media(max-width: 380px) {
      height: 580px;
    }
  }

  .tech-platform-images {
    position: absolute;
    color: #fff;
    top: 15%;
    left: 26%;
    font-size: 22px;
    font-weight: 600;
  }

  .recommded_api_section {
    margin: 0 13%;
    width: 80%;
    min-height: 546px;

    @media (max-width: 1024px) {
      margin: 0;
      width: 100%;
    }

    @media(max-width: 820px) {
      flex-direction: column;
    }
  }

  .request_btn {
    width: 16.75rem;
    height: 3rem;
    border-radius: 6.25rem;
    background: #2869DC;
    font-family: Elevance Sans;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.625rem;
    color: #FFFFFF;
  }

  .request_btn_container {
    display: flex;
    justify-content: center;
    margin-top: 1.25rem;
  }

  .recommded_api_list {
    display: block;
    margin-left: auto;
    margin-right: auto;

    @media(max-width: 1024px) {
      justify-content: center;
      display: flex;
    }
  }
`;

export const faqSection = css`
  padding: .3125rem 12.375rem 2.125rem;

  @media(max-width: 1024px) {
    padding: .3125rem 5.375rem 2.125rem;
  }

  @media(max-width: 480px) {
    padding: .3125rem 1rem 2.125rem;
  }

  .section__welcome_line1 {
    margin-top: 50px;
    color: #1355E9;
    font-family: Elevance Sans;
    font-size: 1rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: center;
  }

  .section__welcome_line2 {
    color: #333333;
    font-family: Elevance Sans;
    font-size: 32px;
    font-weight: 600;
    line-height: 3rem;
    text-align: center;

    @media(max-width: 850x) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .faqCard {
    padding: 50px;

    @media(max-width: 1024px) {
      padding: 25px;
    }

    @media(max-width: 480px) {
      padding: 10px;
    }
  }

  .ant-collapse-header-text {
    font-family: Elevance Sans;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.75rem;
    text-align: left;
    color: #333333;

    @media(max-width: 940px) {
      font-size: 20px;
    }
  }

  .faqDescription {
    margin-left: 6em;
    font-family: Elevance Sans;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375rem;
    text-align: left;
    color: #333333;
  }

  .more_info_api {
    min-width: 125px;
    height: 46px;
    border-radius: 100px;
    color: #1355E9;
    border: 1px solid #1355E9;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    margin-bottom: 20px;
  }

  .more-info-api-text {
    font-family: Elevance Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    color: #1355E9;
  }



  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
    font-weight: 600;
    font-size: 18px;
    padding: 15px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    color: #1354e9;
    padding: 20px;
  }

  .ant-collapse {
    background-color: #fff;
    border: none;
  }

  .collapse-container {
    box-shadow: 0px 8px 16px 0px #00000014;
    margin: 0 6.25rem 150px;

    @media(max-width: 1024px) {
      margin: 0 3.25rem 50px;
    }

    @media(max-width: 1024px) {
      margin: 0 0 50px;
    }
  }

  .ant-collapse > .ant-collapse-item:last-child {
    border-bottom: none;
  }

`;

// export const apiPartnerGetStarted = css`
//   .backgroundImage {
//     background: url(${images_getStarted});
//     background-repeat: no-repeat;

//     background-size: 100% 100%;

//     float: left;

//     width: 100%;
//     height: 453px;
//   }
// `;

export const CarousalCSS = css`
  .no_margin {
    margin: 0;
  }
  .space__container .ant-space-item {
    padding: 15px 5px;
  }
  .container__slide_0 {
    background: transparent linear-gradient(89deg, #0068a6 0%, #0078c1 100%) 0% 0% no-repeat padding-box;
  }
  .container__slide_1 {
    background: transparent linear-gradient(89deg, #0068a6 0%, #0078c1 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
  }
  .container__slide_2 {
    background: transparent linear-gradient(89deg, #0068a6 0%, #0078c1 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  .slick-dots li button:before {
    color: white;
  }

  @media (min-width: 320px) {
    .view_height__landing {
      height: 85vh;
    }
    .image__intro_slide {
      width: 70%;
      margin: auto;
    }
    .h1__title {
      color: white;
      font-size: large;
    }
    .p__description {
      color: white;
      font-size: small;
    }
    .container__slide-row {
      position: relative;
      left: 0;
      padding: 24px;
    }
    .slick-dots {
      top: 0;
      bottom: auto;
      right: 0;
      width: auto;
      text-align: right;
    }
  }
  @media (min-width: 480px) {
    .view_height__landing {
      height: 85vh;
    }
    .image__intro_slide {
      width: 70%;
      margin: auto;
    }
    .h1__title {
      color: white;
      font-size: 1.5em;
    }
    .p__description {
      color: white;
      font-size: 1rem;
    }
    .container__slide-row {
      position: relative;
      left: 0;
      padding: 24px;
    }
    .slick-dots {
      top: 0;
      bottom: auto;
      right: 0;
      width: auto;
      text-align: right;
    }
  }
  @media (min-width: 600px) {
    .view_height__landing {
      height: 85vh;
    }
    .image__intro_slide {
      width: 80%;
      margin: auto;
    }
    .h1__title {
      color: white;
      font-size: 3em;
      line-height: normal;
    }
    .p__description {
      color: white;
      font-size: 1.3rem;
      line-height: normal;
    }
    .container__slide-row {
      position: relative;
      left: 5%;
      padding: 24px;
    }
    .slick-dots {
      top: 0;
      bottom: auto;
      right: 0;
      width: auto;
      text-align: right;
    }
  }
  @media (min-width: 801px) {
    .view_height__landing {
      height: 430px;
    }
    .image__intro_slide {
      width: 80%;
      margin: auto;
    }
    .h1__title {
      text-align: left;
      font: normal normal 520 38px/50px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
    .p__description {
      font: normal normal normal 16px/24px Lato;
      letter-spacing: 0px;
      color: #e9e9e9;
      opacity: 1;
    }
    .container__slide-row {
      position: relative;
      left: 5%;
      padding: 24px;
    }
    .slick-dots {
      top: auto;
      bottom: 5%;
      left: 8%;
      text-align: left;
    }
  }
  @media (min-width: 1025px) {
    .view_height__landing {
      height: 470px;
    }
    .image__intro_slide {
      width: 80%;
      margin: auto;
    }
    .h1__title {
      text-align: left;
      font: normal normal 520 38px/50px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
    .p__description {
      font: normal normal normal 16px/24px Lato;
      letter-spacing: 0px;
      color: #e9e9e9;
      opacity: 1;
    }
    .container__slide-row {
      position: relative;
      left: 5%;
      padding: 24px;
    }
    .slick-dots {
      top: auto;
      bottom: 5%;
      left: 8%;
      text-align: left;
    }
  }
  @media (min-width: 1400px) {
    .view_height__landing {
      height: auto;
    }
    .image__intro_slide {
      width: 85%;
      margin: auto;
    }
    .h1__title {
      text-align: left;
      font: normal normal 520 38px/50px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
    .p__description {
      font: normal normal normal 24px/34px Lato;
      letter-spacing: 0px;
      color: #e9e9e9;
      opacity: 1;
    }
    .container__slide-row {
      position: relative;
      left: 5%;
      padding: 24px;
    }
    .slick-dots {
      top: auto;
      bottom: 5%;
      left: 8%;
      text-align: left;
    }
  }
`;

export const WelcomeSectionCSS = css`
  position: relative;
  .ant-card-head {
    border: none;
  }
  .section__welcome_line {
    color : #1354e9;
    font-size : 12px;
    text-align: center;

  }
  .card__welcome {
    width: "100%",
    height: "400px",
    background: url(${FOOTER_MOBILE_BANNER}) no-repeat center;
  }

  @media (min-width: 320px) {
    &.section__welcome_section {
      padding: 0px;
      background: #ffffff;
      min-height: 400px;
    }
    .card__welcome_section {
      text-align: center;
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .div__welcome_heading {
      font-size: 22px;
    }

    .div__welcome_description {
      font-size: 12px;
      font-weight: 500;
    }
  }
  @media (min-width: 480px) {
    &.section__welcome_section {
      padding: 0px;
      background: #ffffff;
      min-height: 400px;
    }
    .card__welcome_section {
      text-align: center;
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .div__welcome_heading {
      font-size: 22px;
    }

    .div__welcome_description {
      font-size: 12px;
      font-weight: 500;
    }
  }

  @media (min-width: 600px) {
    &.section__welcome_section {
      padding: 24px;
      background: #ffffff;
      min-height: 400px;
    }
    .card__welcome_section {
      text-align: center;
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .div__welcome_heading {
      font-size: 22px;
    }

    .div__welcome_description {
      font-size: 12px;
    }
  }
  @media (min-width: 801px) {
    &.section__welcome_section {
      padding: 24px;
      background: #ffffff;
      min-height: 400px;
    }
    .card__welcome_section {
      width: 70%;
      text-align: center;
      margin: 0 12%;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .div__welcome_heading {
      font-size: 30px;
    }

    .div__welcome_description {
      font-size: 18px;
    }
  }

  @media (min-width: 1281px) {
    &.section__welcome_section {
      padding: 24px;
      background: #ffffff;
      min-height: 350px;
    }
    .card__welcome_section {
      width: 70%;
      text-align: center;
      margin: 0 12%;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      text-align: center;
    }
    .div__welcome_heading {
      font: normal normal 600 30px/38px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }

    .div__welcome_description {
      font: normal normal normal 16px/18px Lato;
      letter-spacing: 0px;
      color: #707070;
      opacity: 1;
    }
  }

  @media (min-width: 1600px) {
    &.section__welcome_section {
      padding: 24px;
      background: #ffffff;
      min-height: 400px;
    }
    .card__welcome_section {
      width: 70%;
      text-align: center;
      margin: 0 12%;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      text-align: center;
    }
    .div__welcome_heading {
      font: normal normal 600 36px/46px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }

    .div__welcome_description {
      font: normal normal normal 18px/22px Lato;
      letter-spacing: 0px;
      color: #707070;
      opacity: 1;
    }
  }
`;

export const GetStartedMobileCss = css`
  @media (min-width: 320px) {
    &.section__get-started {
      display: block;
      position: fixed;
      width: 100%;
      height: 70px;
      background: white;
      z-index: 9;
    }

    .btn__get-started {
      width: 81%;
      position: relative;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%);
      background: #1354e9;
    }
  }
  @media (min-width: 480px) {
    &.section__get-started {
      display: block;
      position: fixed;
      width: 100%;
      height: 70px;
      background: white;
      z-index: 9;
    }
    .btn__get-started {
      width: 81%;
      position: relative;
      top: 50%;
      left: 50%;
      text-align: center;
      background: #1354e9;
      transform: translate(-50%, -50%);
    }
  }
  @media (min-width: 600px) {
    &.section__get-started {
      display: block;
      position: fixed;
      width: 100%;
      height: 70px;
      background: white;
      z-index: 9;
    }
    .btn__get-started {
      width: 81%;
      position: relative;
      top: 50%;
      left: 50%;
      text-align: center;
      background: #1354e9;
      transform: translate(-50%, -50%);
    }
  }
  @media (min-width: 801px) {
    &.section__get-started {
      display: none;
    }
    .btn__get-started {
      background: #1354e9;
    }
  }
  @media (min-width: 1025px) {
    &.section__get-started {
      display: none;
    }
    .btn__get-started {
      background: #1354e9;
    }
  }
  @media (min-width: 1281px) {
    &.section__get-started {
      display: none;
    }
    .btn__get-started {
      background: #1354e9;
    }
  }
`;
export const MainContainerCss = css`
   
  @media(min-width: 1024px) {
    position: relative;
    bottom: 47px;
  }

  @media(min-width: 2560px) {
    bottom: 180px;
  }

  @media(max-width: 1024px) {
    position: relative;
    bottom: 40px;
  }

  @media(max-width: 820px) {
    position: relative;
    bottom: 22px;
  }

  @media(max-width: 540px) {
    position: relative;
    bottom: 28px;
  }

  @media(max-width: 440px) {
    position: relative;
    bottom: 0;
  }

  .backgroundImage {
    background: url(${FOOTER_MOBILE_BANNER});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    float: left;
    width: 100%;
    height: 750px;
  }

  .second_headline {
    font-family: Elevance Sans;
    color: white;
    font-weight: 600;
    font-size: 48px;

 @media(max-width: 1024px) {
      font-size: 42px;
    }

    @media(max-width: 850px) {
      font-size: 32px;
      line-height: 40px;
      text-align: left;
    }

    @media(max-width: 540px) {
      color: #333333;
    }
  }

  .first_headline {
    font-family: Elevance Sans;
    color: white;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.03em;

    @media(max-width: 1024px) {
      font-size: 20px;
    }

    @media(max-width: 850px) {
      font-size: 18px;
    }

    @media(max-width: 480px) {
      font-size: 16px;
      font-weight: 600;
      line-height: 34.72px;
      letter-spacing: 0.03em;
    }
  }

  .tell_us_button {
    height: 40px;
    display: block;
    background-color: #2359E0;
    border-radius: 20px;
    color: white;
    border: none;
    font-family: Elevance Sans;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;

    @media(max-width: 480px) {
      background-color: #ffffff;
      color: #1355E9;
      border: 1px solid #1355E9;
      border-radius: 100px;
    }
  }

  .welcome_banner_btn {
    display: flex;

    @media(max-width: 480px) {
      justify-content: space-between;
    }

    @media(min-width: 768px) {
      justify-content: flex-start;
    }
  }

  .get_started_btn {
    width: 139px;
    height: 40px;
    display: block;
    background-color: #2359E0;
    border-radius: 20px;
    color: white;
    border: none;
    font-family: Elevance Sans;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;

    @media(max-width: 480px) {
      width: 130px;
    }
  }

  .tell_us_button, .get_started_btn {
    @media(max-width: 370px) {
      font-size: 14px !important;
      width: auto;
    }
  }

  .get-started {
    height: 40px;
    border: 1px solid #286ce2;
    display: block;
    background-color: #fff;
    border-radius: 20px;
    color: #1354e9;
    width: 200px;
  }
  
  #welcome-banner {
    position: relative;
    height: 90vh;
    width: 100vw;

    @media(min-width: 762px) {
      height: 85vh;
    }

    @media(max-width: 1024px) {
      height: 50vh;
    }

    @media(max-width: 820px) {
      height: 45vh;
    }

    @media(max-width: 768px) {
      height: 50vh;
    }

    @media(max-width: 540px) {
      height: 75vh;
    }

    @media(max-width: 440px) {
      height: 55vh
    }

    @media(max-width: 375px) {
      height: 80vh;
    }

    @media(max-width: 360px) {
      height: 60vh;
    }
  }

  .background-video {
    width: 100vw;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover; 
    z-index: 1;
    height: 85vh;

    @media (max-width: 1024px) {
      height: 50vh;
      position: absolute; /* Change position to relative */
      z-index: 2; /* Ensure it appears above welcome_banner_content */
    }

    @media(max-width: 820px) {
      height: 45vh;
    }

    @media(max-width: 768px) {
      height: 50vh;
    }

    @media (max-width: 480px) {
      height: 50vh;
      position: absolute; /* Change position to relative */
      z-index: 2; /* Ensure it appears above welcome_banner_content */
    }

    @media (max-width: 440px) {
      height: 30vh;
      position: absolute; /* Change position to relative */
      z-index: 2; /* Ensure it appears above welcome_banner_content */
    }

    @media (max-width: 375px) {
      height: 45vh;
      position: absolute; /* Change position to relative */
      z-index: 2; /* Ensure it appears above welcome_banner_content */
    }

    @media (max-width: 370px) {
      height: 30vh;
      position: absolute; /* Change position to relative */
      z-index: 2; /* Ensure it appears above welcome_banner_content */
    }

  }

  .welcome_banner_content {
   margin: 5% 10% 6% 10%;
    position: relative;
    z-index: 2; /* Higher z-index to place it on top of the video */
    padding: 20px; 

    @media(max-width: 540px) {
      margin: 5% 10% 5% 0%;
    }

    @media(max-width: 480px) {
      margin: 0 5% 20%;
      padding: 10px 0px;
    }

    @media(min-width: 1026px) {
      padding: 150px 100px; 
    }
  }

  .welcome_banner_content2 {
    margin: 5% 25% 5% 10%;
    position: relative;
    z-index: 2; /* Higher z-index to place it on top of the video */
    padding: 20px; 
    bottom: 15%;

    @media(max-width: 1024px) {
      bottom: 12%;
    }

    @media(min-width: 1026px) {
      padding: 100px;
      bottom: 38%; 
    }

    @media(min-width: 1792px) {
      bottom: 48%; 
    }

    @media(min-width: 1792px) and (max-height: 600px) {
      bottom: 80%; 
    }

    @media(min-width: 1920px) {
      bottom: 40%;
    }

    @media(min-width: 2560px) {
      bottom: 33%;
    }

    @media(min-width: 3840px) {
      bottom: 26%;
    }

    @media(max-width: 540px) {
      color: #333333;
      width: 98vw;
      padding: 10px;
      margin: 10px;
      position: relative; /* Ensure it appears below background-video */
      z-index: 1; /* Lower z-index to place it below the video */
      top: 46%;
      bottom: 0;
    }

    @media(max-width: 480px) {
      color: #333333;
      width: 98vw;
      padding: 10px;
      margin: 10px;
      position: relative; /* Ensure it appears below background-video */
      z-index: 1; /* Lower z-index to place it below the video */
      top: 50%;
      bottom: 0;
    }

    @media(max-width: 440px) {
      top: 33%;
    }
    
    @media(max-width: 400px) {
      top: 30%;
    }

    @media(max-width: 375px) {
      top: 39%;
    }

    @media(max-width: 360px) {
      top: 28%;
    }
  }
`;

export const APIWidgetCss = css`

  @media(max-width: 480px) {
    margin-top: 0;
  }

  .ant-card-body {
    padding: 5px;
    width: 100%;
    height: 100%;
  }

  .container {
    width: inherit;
    height: inherit;
    text-align: center;
  }
  
  .api_cards {
    width: 100%;
    height: inherit;

    @media(max-width: 850px) {
      width: 50%;
    }
  }

  .align-center {
    display: flex;
    justify-content: center; 
  }

  .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
      margin-left: 3%;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #1354e9;
      font-weight: 600;
  }

  .more_info_api {
    height: 40px;
    border-radius: 20px;
    color: #286ce2;
    border: 1px solid #286ce2;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
  }

  .more-info-api-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
  }

  .card__api_widget {
    background: transparent;
  }

  .card__api_widget .ant-card-head {
    border: none;
    text-align: center;
  }

  .section__welcome_line1 {
    margin-top: 50px;
    color: #1355E9;
    font-family: Elevance Sans;
    font-size: 1rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: center;

    @media(max-width: 850px) {
      font-size: 16px;
    }
  }

  .section__welcome_line2 {
    font-family: Elevance Sans;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
    color: #231E33;

    @media(max-width: 850px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .typography__api_title {
    font-size: 1em;
  }

  .overlay {
    opacity: 0;
    transition: 0.5s ease;
    transform: rotateY(180deg);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .overlay-container {
      height: inherit;
    }
  }

  .card__api_list:hover .overlay {
    opacity: 1;
    padding: 1.25rem;

    @media (max-width: 850px) {
      padding: 10px;
    }
  }
  .card__api_list:hover .api_cards {
    opacity: 0.5;
    visibility: hidden;
  }

  .ant-card-body:hover {
    background-color: #E3ECFD;
  }

  .card__api_list {
    width: 340px;
    height: 220px;
  }

  .card__api_list:hover .container,
  .card__api_list:focus .container{
    transform: rotateY(180deg);
  }

  .api_cards,
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .api_cards {
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .625rem;
  }

  .text {
    color: #333333;
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: Elevance Sans;
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;

  }

  .image__card_shot_list {
    width: 100%;
  }

  .col__widget_col {
    padding: 0 8%;
    background: transparent linear-gradient(90deg, #f7f7f7 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    box-sizing: border-box;
    
    @media (max-width: 820px) {
      padding: 0 5%;
    }

    @media (max-width: 480px) {
      padding: 0;
    }
  }

  .api-widget-row {
    @media(max-width: 650px) {
      padding: 5px;
    }
  }

  .ant-tabs-content-holder {
    padding: 3% 5%;

    @media(max-width: 850px) {
      padding: 3% 0;
    }

    @media(max-width: 480px) {
      padding: 3% 5%;
    }
  }

  .browse_all {
    box-shadow: 3px 3px 6px #0000000f;
    border: 1px solid #286ce2;
  }

  .link__heading {
    color: #286ce2;
  }

  .typography__api_heading {
    height: 70px;
    font-size: 2em;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .ant-tabs-tab {
    margin: 0 32px;
  }

  .ant-tabs-nav-wrap {
    white-space: normal !important;
  }

  .ant-tabs-tab-btn {
    font-family: Elevance Sans;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: #333333;

    @media(max-width: 850px) {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }

  .api_title {
    font-family: Elevance Sans;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.71rem;
    text-align: center;
    color: #333333;

    @media(max-width: 1024px) {
      font-size: 20px;
      font-weight: 600;
      line-height: 18.24px;
    }

    @media(max-width: 850px) {
      font-size: 16px;
      font-weight: 600;
      line-height: 18.24px;
    }
  }

  .api_desc {
    font-family: Elevance Sans;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;
    color: #333333;

    @media(max-width: 1024px) {
      line-height: 18px;
    }

    @media(max-width: 850px) {
      line-height: 16px;
    }
  }

  @media (min-width: 320px) {
    ${'' /* .section__api_widget {
      height: 68vh;
    } */}
    .card__api_list {
     width: auto;
      height: 150px;
    }
    .typography__api_heading {
      font-size: 22px;
    }
    .image__card_shot_list {
      width: 55%;
    }
    .browse-all-logo {
      width: 35%;
    }
  }
  @media (min-width: 480px) {
    ${'' /* .section__api_widget {
      height: 68vh;
    } */}
    .card__api_list {
      width: auto;
      height: 150px;
    }
    .typography__api_heading {
      font-size: 22px;
    }
    .image__card_shot_list {
      width: 55%;
    }
    .browse-all-logo {
      width: 35%;
    }
  }
  @media (min-width: 600px) {
    ${'' /* .section__api_widget {
      height: 68vh;
    } */}
    .card__api_list {
     width: auto;
      height: 150px;
    }
    .typography__api_heading {
      font-size: 22px;
    }
    .image__card_shot_list {
      width: 55%;
    }
    .browse-all-logo {
      width: 55%;
    }
  }
  @media (min-width: 801px) {
    ${'' /* .section__api_widget {
      height: 550px;
    } */}
    .card__api_list {
      width: auto;
      height: 150px;
    }
    .typography__api_heading {
      font-size: 25px;
    }
    .image__card_shot_list {
      width: 75%;
    }
    .browse-all-logo {
      width: 55%;
    }
  }
  @media (min-width: 1025px) {
    ${'' /* .section__api_widget {
      height: 480px;
    } */}
    .card__api_list {
      width: auto;
      height: 220px;
    }
    .typography__api_heading {
      font-size: 25px;
    }
    .image__card_shot_list {
      width: 70%;
    }
    .browse-all-logo {
      width: 50%;
    }
  }
  @media (min-width: 1400px) {
    ${'' /* .section__api_widget {
      height: 580px;
    } */}
    .card__api_list {
      width: auto;
      height: 220px;
    }
    .typography__api_heading {
      font-size: 30px;
    }
    .image__card_shot_list {
      width: 100%;
    }
    .browse-all-logo {
      width: 50%;
    }
  }

  .image {

    @media(max-width: 1024px) {
      height: 42px;
      width: 42px;
    }

    @media(max-width: 850px) {
      height: 40px;
      width: 40px;
    }

    @media(max-width: 600px) {
      height: 32px;
      width: 32px;
    }
  }
`;

export const UserFlowCSS = css`
  .user_flow__title {
    text-align: center;
    font: normal normal bold 32px/39px Montserrat;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }
  .ant-card-head {
    border-bottom: none;
  }
  img {
    display: block;
    margin: auto;
    width: 80%;
  }
  /* @media (max-width: 768px) {
    .user_flow__title {
      font-size: 1.3em;
    }
  } */

  @media (min-width: 320px) {
    .user_flow__title {
      font: normal normal bold 18px/28px Montserrat;
    }
  }
  @media (min-width: 480px) {
    .user_flow__title {
      font: normal normal bold 18px/28px Montserrat;
    }
  }
  @media (min-width: 600px) {
    .user_flow__title {
      font: normal normal bold 18px/28px Montserrat;
    }
  }
  @media (min-width: 801px) {
    .user_flow__title {
      font: normal normal bold 28px/34px Montserrat;
    }
  }
  @media (min-width: 1300px) {
    .user_flow__title {
      font: normal normal bold 28px/34px Montserrat;
    }
  }
  @media (min-width: 1600px) {
    .user_flow__title {
      font: normal normal bold 32px/39px Montserrat;
    }
  }
`;

export const UserFlowSectionCSS = css`
  padding: 24px;
  background: #ffffff;
`;
