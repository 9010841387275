import React, { Fragment, useEffect } from 'react';
import { Typography, Card, Space, Row, Col, Avatar, Checkbox } from 'antd';
import { getPublicSiteImageSrc } from 'Client/common/utils/AppUtils';


const CardsTwo = ({ handleCheckboxSelection, interestedLOBs }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Define a reusable Card component
  const LOBCard = ({ imgSrc, altText, label, value }) => (
    <Col xs={12} sm={12} md={6} lg={6}>
      <Card
        className="checkbox-card"
        bodyStyle={{
          height: "inherit"
        }}
      >
        <Row style={{height:"inherit"}} justify="space-around">
          <Col offset={2} span={12}>
            <img src={getPublicSiteImageSrc(imgSrc)} alt={altText} className="qna_image" />
          </Col>
          <Col span={24}>
            <Checkbox value={value}>
              <span className='qna-value'> { label } </span>
            </Checkbox>
          </Col>
        </Row>
      </Card>
    </Col>
  );

  // Data for each card
  const cardData = [
    {
      imgSrc: 'all-business.svg',
      altText: 'allBusiness',
      value: 'All Business',
      label: 'All Business Units',
    },
    {
      imgSrc: 'individual.svg',
      altText: 'individual',
      value: 'Individual',
      label: 'Individual',
    },
    {
      imgSrc: 'small-group.svg',
      altText: 'smallGroup',
      value: 'Small Group',
      label: 'Small Group',
    },
    {
      imgSrc: 'large-group.svg',
      altText: 'Avatar',
      value: 'Large Group',
      label: 'Large Group',
    },
  ];

  return (
    <Fragment>
      <div>
        <div className="ant-result-title">
          <Fragment>
            <Typography.Paragraph className="cards-heading">
              <Space>
                <Avatar style={{ backgroundColor: '#074079', size: 'large' }} size={{ xs: 32, sm: 32, md: 36, lg: 36, xl: 36, xxl: 36}}>2</Avatar>
                <span className="avatar-text">What line of business is your primary focus?</span>
              </Space>
            </Typography.Paragraph>
          </Fragment>
        </div>
        <Checkbox.Group name="interestedLOBs" 
          value={interestedLOBs}
          onChange={handleCheckboxSelection}
        >
          <Row gutter={[{xs: 32, sm: 32, md: 64, lg: 16, xl: 16}, 16]} justify="space-around">
            {
              cardData.map((card, index) => {
                return (
                  <LOBCard
                    key={index}
                    imgSrc={card.imgSrc}
                    altText={card.altText}
                    label={card.label}
                    value={card.value}
                  />
                )
              })
            }
          </Row>
        </Checkbox.Group>
      </div>
    </Fragment>
  );
};

export default CardsTwo;
