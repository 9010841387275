import { css } from '@emotion/core';
import FOOTER_MOBILE_BANNER from './../../common/img/new-public-Ui/partner_tab.png';

export const partnerPageCss = css`
  @media (min-width: 768px) {
    padding-top: 3em;
  }

  @media (min-width: 1024px) {
    padding-top: 0em;
  }

.mobile-only {
    display: none;
   }
    .hide-on-mobile {
  display: block;
}
@media (max-width: 820px) {
   .mobile-only {
      display: block;
    }
    .hide-on-mobile {
      display: none;
    }
    .divider-class {

    }
}
`;

export const wellcomeArea = css`
  background: url(${FOOTER_MOBILE_BANNER}) no-repeat center;
  background-size: 100%;
  padding: 90px 100px;
  height: 85vh;
  @media (max-width: 1024px) {
    padding: 0 10px;
    height: 37vh;
  }
  .ant-card-actions {
    margin: 5px 16px;
  }
  @media (max-width: 480px) {
    padding: 0 10px;
    height: 50vh;
  }
`;
export const wellcomeAreaMobile = css`
  background: url(${FOOTER_MOBILE_BANNER}) no-repeat center;
  background-size: 100%;
  padding: 90px 100px;
  @media (max-width: 1024px) {
    padding: 0 10px;
  }
  .ant-card-actions {
    margin: 5px 16px;
  }
  @media (max-width: 480px) {
    padding: 0 10px;
  }
`;

export const partnerTypeCss = css`
width: 'inherit';


.partnerDetails {
  margin-top: 2rem;
}

.partnerDisplay {
  width: 100%;
}

.partnerDisplayImage {
  width: 24.5625rem;
  height: 18.4375rem;
}

.partnerDisplayDesc{
  align-content: center;
}

.partnerDisplayContent-right {
  display: flex;
  justify-content: end;
  text-align: left;
  max-width: 24.5625rem;
}

.partnerDisplayTitle {
  font-family: Elevance Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 31px;
  color: #1A1A1A;
  }
 .partnerDisplayDescription {
  font-family: Elevance Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #333333;
  text-align: left;
 }

.partnerDisplayContent-left {
  display: flex;
  text-align: left;
  max-width: 24.5625rem;
}

.section__welcome_line1 {
  margin-top: 70px;
  color:#1355E9;
;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  font-family: Elevance Sans;
}
.section__welcome_line2 {
  color: Black;
  font-size: 14px;
  text-align: left;
}

.section__welcome_line {
  color: #231E33;
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  font-family: Elevance Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  text-align: center;
}

.typography__api_title {
  font-size: 1em;
}

.container {
  position: relative;
  width: 100%;
}

.api_title {
  font-weight: bold;
}
.card__api_list {
   width: 240px
  height: 180px;
}

.link__heading {
  color: #286ce2;
}

.typography__api_heading {
  height: 70px;
  font-size: 2em;
  display: flex;
  justify-content: center;
  text-align: center;
}

.ant-tabs-tab {
  margin: 0 32px;
}

.ant-tabs-nav-wrap {
  white-space: normal !important;
}
`;

export const partnerTypeCssMobile = css`

.partnerDetails {
  margin-top: 2rem;
}

.partnerRow{
width: 100%;
}

.partnerDisplay {
  width: 100%;
}

.partnerDisplayImage {
  height: 16.4375rem;
  padding: 15px 15px 15px 32px;
}

.partnerDisplayDesc{
  padding: 15px 15px 15px 32px;
  text-align: left;
}

.partnerDisplayTitle {
  font-family: Elevance Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 31px;
  color: #1A1A1A;
  }
 .partnerDisplayDescription {
  font-family: Elevance Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #333333;
  text-align: left;
 }


.section__welcome_line1 {
  margin-top: 40px;
  color:#1355E9;
;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  font-family: Elevance Sans;
}
.section__welcome_line2 {
  color: Black;
  font-size: 14px;
  text-align: left;
}
  .section__welcome_line {
  color: #231E33;
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  font-family: Elevance Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  text-align: center;
}
  @media(max-width: 480px) {
    .partnerDisplayImage {
      height: 18rem;
      padding: 15px 15px 15px 40px;
    }
    .partnerDisplayDesc{
      padding: 15px 15px 15px 40px;
    }
  }

  @media(max-width: 390px) {
    .partnerDisplayImage {
      height: 16.4375rem;
      padding: 15px 15px 15px 32px;
    }

    .partnerDisplayDesc{
      padding: 15px 15px 15px 32px;
    }
  }
}
`;

export const WelcomeSectionCSS = css`
  position: relative;
  margin: -5% 0;
  padding: 5% 0;

  .section__welcome_line {
    color: #1354e9;
    font-size: 12px;
    text-align: center;
  }

  .second_headline {
    color: white;
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 1.5em;
    font-family: Elevance Sans;
    font-weight: 600;
    vertical-align: middle;

  }

  .welcome_banner_content {
    margin: 5% 30% 5% 15%;
  }

  .first_headline {
    color: #F5F5F5;
    margin-bottom: 1em;
    font-family: Elevance Sans;
    font-size: 24px;
    font-weight: 500;
    line-height: 34.72px;
    text-align: left;
  }

  .tell_us_button {
    height: 40px;
    border: none;
    display: block;
    background-color: #2359E0;
    border-radius: 20px;
    color: white;
    font-family: Lato;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: left;

  }
  @media (max-width: 480px) {
   .welcome_banner_content {
    margin: 3%;
}
}
`;
export const WelcomeSectionCSSMobile = css`
.backgroundImage {
background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0.9)),
    url(${FOOTER_MOBILE_BANNER}) no-repeat center;
  background-size: 150%;
  height: 250px;
}
.first_headline {
    color: #F5F5F5;
    margin-bottom: 1em;
    font-family: Elevance Sans;
    font-size: 24px;
    font-weight: 500;
    line-height: 34.72px;
    text-align: left;
    padding: 1em 0.5em;
  }
    .second_headline {
    color: #3A3A3A;
    font-family: Elevance Sans;
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 1.5em;
    padding: 1em 0.5em;
  }

  .tell_us_button {
    height: 50px;
    border: none;
    display: block;
    background-color: #2359E0;
    border-radius: 25px;
    color: white;
    font-family: Lato;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
    margin: -1em 1em;
    width: 150px;
  }
 

`;
export const partnerTestimonialCss = css`
  background: #F9F9F9;
  margin-top: 6.25rem;

  .section__welcome_line1 {
    margin-top: 70px;
    color: #1354e9;
    font-size: 16px;
    text-align: center;
    font-weight: 600;
  }

  .section__welcome_line {
    color: Black;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
  }

  .carousel_section {
    margin: 3%;
  }

  .ant-card-actions {
    margin: 5px 16px;
  }

  .ant-card-actions > li {
    text-align: left;
    width: 75%;
  }

  .ant-carousel .slick-dots {
    bottom: -17px;

    margin-left: 0px;
    padding-left: 0;
    list-style: none;
  }
  .ant-card-body {
    padding: 40px;
  }
`;
