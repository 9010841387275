import { css } from '@emotion/core';

export const MobileDrawerCss = css`

overflow-y: scroll;

.ant-drawer-wrapper-body {
  border-top-left-radius: 16px;
}

.ant-drawer-content {
  height: 100%;
  overflow-y: scroll;
}


.ant-menu {
  display: block;
  font-weight: 600;
  width: auto !important;

  @media(max-width: 375px) {
    margin: 0 !important;
  }
}

.ant-menu-hidden {
  display: none;
}

.ant-menu-item, .ant-menu-submenu-selected {
  color: #000;
}

..ant-menu-light .ant-menu-submenu-title:hover {
  color: #000;
  //  color: #286CE2;
}

.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-item-selected,  .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover{
  color: #000;
  background: #fff;
}

.ant-menu-inline .ant-menu-item::after {
  border: none;
}

.ant-menu-item::selection, .ant-menu-sub::selection {
  background: transparent;
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #000;
  font-size: 18px;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  
  height: inherit;
  font-size: 16px;
  color: #333333;
  font-weight: 600;
  line-height: 30px;
  list-style-position: inside;
  list-style-type: disc;
  display: inline-flex;
  white-space: break-spaces;
  padding-left: 20px !important;
}

.ant-menu-sub.ant-menu-inline {
  background: #fff;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  font-size: 14px;
  color: #666666;  
}

.menu_btn {
  width: 156px;
  height: 48px;
  padding: 8px 32px;
  border-radius: 100px;
  font-family: Elevance Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  @media(max-width: 375px) {
    width: auto;
    font-size: 14px !important;
  }
}

.menu_login_btn {
  border: 2px solid #1355E9;
  color: #286CE2;
}

.menu_register_btn {
  background: #1355E9;
  color: #fff;
}

.menu-btn-container {
  display: flex;
  justify-content: center;
}

.ant-menu-item-only-child[data-menu-id$="-register"] {
  min-height: 120px;

  @media(max-width: 375px) {
    padding: 0 !important;
  }
}


.lock-icon {
  padding: 0 10px;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected[data-menu-id$="-register"] {
  background: #fff;
}

.ant-drawer-content-wrapper {
  @media(max-width: 375px) {
    width: 350px !important;
  }

  @media(max-width: 345px) {
    width: 320px !important;
  }
}

.ant-drawer-body {
  @media(max-width: 410px) {
    padding: 10px;
  }
}

`;