import { css } from '@emotion/core';
import images_getStarted from './../../common/img/new-public-Ui/backgroundImage_api.png';
export const ApiSearchCss = css`
  min-height: 100%;
 padding-top: 3em;
  @media (max-width: 768px) {
    padding-top: 0em;
  }

  @media (max-width: 1024px) {
    padding-top: 0em;
  }

  .apisearch {
     background: url(${images_getStarted});
    text-align: center;
    padding: 5%;
    height: 100%;
  }

  .search_content {
    color: #fff;
  }

  .search_content_para {
    font-family: Elevance Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;

  }

  .search_header {
    font-family: Elevance Sans;
    font-size: 48px;
    font-weight: 600;
    line-height: 60px;
    text-align: center;
  }
`;

export const ApiListCss = css`
  min-height: 100%;
  margin-bottom: 10%;
`;

export const SearchBarCss = css`
  .ant-input-group-wrapper {
    .ant-input-wrapper {
      .ant-input-affix-wrapper {
        border-radius: 50px 0 0 50px;
      }
    }
  }

  .ant-input-group-wrapper {
    .ant-input-wrapper {
      .ant-input-group-addon {
        border-radius: 0 50px 50px 0;
        .ant-input-search-button {
          border-radius: 0 50px 50px 0;
        }
      }
    }
  }
`;
