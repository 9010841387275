import { css } from '@emotion/core';

export const QuestionCardCss = css`
  padding: 0 3.8em 2em;

  .partnerType_selection {
    .ant-result-title { 
      @media(max-width: 820px) {
        padding-left: 60px;
      }

      @media(max-width: 760px) {
        padding-left: 0;
      }
    }
  }
  
  .ant-result-title {
    font-family: Elevance Sans;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.625rem;
    text-align: left;
    color: #333333;
  }

  .question-title {
    font-family: Elevance Sans;
    font-size: 1.5rem;
    line-height: 2.625rem;
    font-weight: 500;
    text-align: left;
    color: #333333;

    @media (max-width: 768px) {
      .question-title {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  .question-title-box {
    margin: 1.25rem 0;
  }

  .cards-heading {
    font-family: Elevance Sans;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.625rem;
    padding-bottom: 10px;

    .avatar-text {
      font-size: 24px;
    }

    @media (max-width: 768px) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  .cards-heading-secondary {
    font-family: Elevance Sans;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.625rem;
    padding-bottom: 0px;
    margin-bottom: 0.5em;

    .avatar-text {
      font-size: 24px;
    }

    @media (max-width: 768px) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  .qna-value {
    font-weight: 500;
    font-family: Elevance Sans;

    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 20.8px;
    }
  }

  .qna-radiobutton {
    width: 21.25rem;
    height: 14.625rem;
    border: 1px solid #00000014;
    font-family: Lato;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.8rem;

    .ant-radio-inner {
      border-color: #286CE2;
    }

    .qna_image_container {
      min-height: 80px;
      display: flex;
      align-items: center; 
    }

    @media (max-width: 768px) {
      width: 165px;
      min-height: 168px;
      max-height: 210px;
    }

    .ant-space-item {
      @media (max-width: 768px) {
        width: 165px;
        height: 168px;
      }
    }
  }

  .qna-radio-button Row {
    width: inherit;
    height: inherit;
  }

  .qna-big-card {
    @media (max-width: 768px) {
      width: 300px;
      min-height: 168px;
      max-height: 210px;
    }

    @media (max-width: 760px) {
      width: 165px;
      min-height: 168px;
      max-height: 210px;
    }

    @media (max-width: 375px) {
      width: 155px;
      min-height: 158px;
      max-height: 210px;
    }

    @media (max-width: 360px) {
      width: 150px;
      min-height: 158px;
      max-height: 210px;
    }

  }

  .partnerType-card-row {
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .partnerType-card {
    font-family: Elevance Sans;
    width: 22.5rem;
    height: 19.0625rem;

    .partnerType_heading {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.71rem;
      color: #333333;
    }

    .partnerType-desc {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      color: #666666;
    }

    .image-partnerType {
      width: 100%;
      max-width: 80px;
      height: auto;
    }

    .align-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-radio-inner {
      border-color: #286CE2;
    }
  }

  @media (max-width: 1366px) {
    .partnerType-card {
      width: 18rem;
      height: 15rem;

      .ant-card-body {
        padding: 22px;
      }

      .partnerType_heading {
        font-size: 1.4rem;
        line-height: 1.6rem;
      }

      .partnerType-desc {
        font-size: 0.95rem;
        line-height: 1.4rem;
        -webkit-line-clamp: 3; /* Adjust number of lines for larger tablets */
      }

      .image-partnerType {
        max-width: 50px;
      }

      .align-center {
        justify-content: space-between;
      }
    }
  }

  @media (max-width: 992px) {
    .qna-group {
      justify-content: center;  
    }

    .partnerType-card {
      width: 38rem;
      height: 14rem;

      .ant-card-body {
        padding: 22px;
      }

      .partnerType_heading {
        font-size: 1.4rem;
        line-height: 1.6rem;
      }

      .partnerType-desc {
        font-size: 0.95rem;
        lline-height: 1rem;
        overflow: hidden;
        /* Adding ellipsis for displaying content on small screen  */
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* Number of lines to show */
        -webkit-box-orient: vertical;
      }

      .image-partnerType {
        max-width: 50px;
      }

      .align-center {
        justify-content: space-between;
      }
    }
  }

  @media (max-width: 768px) {
    .partnerType-card {

      .ant-card-body {
        padding: 15px;
      } 

      .partnerType_heading {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }

      .partnerType-desc {
        font-size: 0.875rem;
        line-height: 1rem;
        overflow: hidden;
        /* Adding ellipsis for displaying content on small screen  */
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* Number of lines to show */
        -webkit-box-orient: vertical;
      }
    }
    .image-partnerType {
      max-width: 50px;
    }

    .align-center {
      justify-content: space-between;
    }
  }

  @media (max-width: 576px) {
    .partnerType-card {
      width: 100%;
      height: auto;
      padding: 1rem;

      .ant-card-body {
        padding: 5px;
      }

      .partnerType_heading {
        font-size: 1rem;
        line-height: 1.25rem;
      }

      .partnerType-desc {
        font-size: 0.75rem;
        line-height: 1rem;
        overflow: hidden;
        /* Adding ellipsis for displaying content on small screen  */
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* Number of lines to show */
        -webkit-box-orient: vertical;
      }
    }

    .image-partnerType {
      max-width: 50px;
    }
  }

  .ant-result-info .ant-result-icon > .anticon {
    color: #286ce2 !important;
  }

  .ant-result-icon > .anticon {
    font-size: 65px;
  }

  .ant-divider {
    border: none;
  }

  .secondary-qna {
    padding-top: 3.125rem;
    padding-bottom: .625rem;
  }

  .ant-radio-wrapper {
    font-family: Elevance Sans;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.71rem;
    text-align: left;
    gap: .625rem;
    color: #333333;
    .ant-radio-inner {
      transform: scale(1.5);

      @media (max-width: 768px) {
        transform: scale(1.2);
      }
    }
  }

  .ant-checkbox-wrapper {
    font-family: Elevance Sans;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.71rem;
    text-align: left;
    gap: .625rem;
    color: #333333;
    .ant-checkbox-inner {
      border-color: #286CE2;
      transform: scale(1.5);
      @media (max-width: 768px) {
        transform: scale(1.2);
      }
    }
  }

  .checkbox-card {
    width: auto;
    height: 15.625rem;

    @media (max-width: 1024px) {
      width: 210px;
      height: 180px;
    }

    @media (max-width: 820px) {
      width: 180px;
      min-height: 200px;
      max-height: 210px;
    }

    @media (max-width: 768px) {
      width: 160px;
      height: 162px;

      .ant-card-body {
        padding: 22px;
      }
    }
  }

  .qna_image {
    width: 73px;
    height: 73px;
    top: 44px;
    left: 99px;
    gap: 0px;
    border: 2.5px 0px 0px 0px;
    opacity: 0px;

    @media (max-width: 768px) {
      width: 64.24px;
      height: 48px;
    }
  }

  .thirdparty-admin-subtitle {
    font-family: Elevance Sans;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 27.36px;
    margin-top: 1.875rem;
    color: #333333;
    p > span:nth-child(2) {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.71rem;
    }

    @media (max-width: 768px) {
      font-size: 20px;
      color: #333333;
      p > span:nth-child(2) {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 576px) {
    padding: 0 1em 1em;
  }

  @media (max-width: 768px) {
    .ant-result-icon span img {
      height: 65px;
    }

    h1.ant-typography,
    .ant-typography h1 {
      font-size: 16px;
    }
    .ant-result-extra .ant-typography {
      font-size: 12px;
    }
  }
`;

export const greetingCardHeader = css`
  ${'' /* padding: 3em 3.8em 0; */}
  padding: 0 3.8em 2em;
  background: inherit;

  .ant-card-body {
    @media(max-width: 600px) {
      padding: 0px;
    }
  }

  .backToHome {
    font-family: Elevance Sans;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0px;
  }
  
  @media (min-width: 1024px) {
    padding: 3.5em 3em 0 2em; /* Reduced left padding */
  }

  @media (max-width: 992px) {
    padding: 3.5em 3em 0 2em; /* Reduced left padding */
  }

  @media (max-width: 768px) {
    padding: 2em 2.5em 0 1.5em; /* Reduced left padding */
  }

  @media (max-width: 576px) {
    padding: 1.5em 2em 0 1em; /* Reduced left padding */
  }
`;

export const cssContent = css`
  background-size: 100% 200px;
  padding: 0 9.375rem;
  margin-bottom: 4em;
  @media (max-width: 1024px) {
    padding: 0 10px;
  }

  @media (max-width: 992px) {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    padding-top: 3em;
  }

  @media (min-width: 1024px) {
    padding-top: 5em;
  }
`;

export const footerSection = css`
  ${'' /* padding: 0 3.8em 2em; */}
  margin-top: 2rem;
  @media (max-width: 820px) {
    padding: 0 2em 2em;
  }

  @media (max-width: 576px) {
    padding: 0 0 2em;
  }

  @media (min-width: 1024px) {
    padding: 0 3.8em 2em;
  }

  .nextButton-card {
    margin-left: 60%;
    @media (max-width: 1024px) {
      margin-left: 45%;
    }

    @media (max-width: 820px) {
      margin-left: 40%;
    }

    @media (max-width: 576px) {
      margin-left: 0;
    }

    .nextButton-row {
      justify-content: flex-end;

      @media (max-width: 576px) {
        justify-content: center;
      }
    }

    .ant-card-body {
      padding: 2em;

      @media (max-width: 992px) {
        padding: 1.5em;
      }

      @media (max-width: 768px) {
        padding: 1em;
      }

      @media (max-width: 576px) {
        padding: 0.5em;
      }
    }
  }
  .nextButton {
    font-size: 20px;
    height: 50px;
    width: 150px;
    border: 1px solid #286ce2;
    display: block;
    background-color: #1354e9;
    border-radius: 30px;
    color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    @media (max-width: 992px) {
      font-size: 18px;
      height: 45px;
      width: 140px;
    }

    @media (max-width: 768px) {
      font-size: 16px;
      height: 40px;
      width: 130px;
    }

    @media (max-width: 576px) {
      font-size: 14px;
      height: 35px;
      width: 120px;
    }

    .ant-btn > span {
      font-family: Elevance Sans;
      font-size: 16px;
      font-weight: 600;
      line-height: 18.24px;
      text-align: center;
    }
  }
`;