import React, { Suspense } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import ProgressBar from './../components/ProgressBar';
import LazyConfirmationModule from './Registration/Confirmation';
import LazyLandingModule from './Landing/Landing';
//const LazyLandingModule = lazy(() => import('./Landing/Landing'));
import ApiService from './ApiService/ApiService';
import ApiOverviewScrollerHOC from './ApiService/ApiOverviewScrollerHOC';
import ApiDetailsComponent from './Landing/ApiDetailsComponent';
import GetStarted from './Registration/GetStarted';
import Thankyou from './Registration/Thankyou';
import Expired from './Registration/Expired';
import ResendEmail from './Registration/ResendEmail';
import AlreadyVerified from './Registration/AlreadyVerified';
import RegistrationDone from './Registration/RegistrationDone';
import EmailConfirmation from './Registration/EmailConfirmation';
import ErrorPage from './Registration/ErrorPage';
import ApiServiceList from './ApiService/ApiServiceList';
import FAQComponent from './Landing/FAQComponent';
import CardsOne from './RecommendedApiList/CardsOne';
import CardsTwo from './RecommendedApiList/CardsTwo';
import CardsThree from './RecommendedApiList/CardsThree';
import CardsFour from './RecommendedApiList/CardsFour';
import PartnerTypeSelection from './Registration/PartnerTypeSelection';
import ApiDetails from './ApiService/ApiDetails';
import PartnerPageComponent from './PartnerTab/PartnerPage';
import CerosPage from './Registration/CerosPage';
import QuestionsCard from './RecommendedApiList/QuestionsCard';

function NoMatch() {
  const location = useLocation();
  return (
    <div>
      <h3>
        Route not match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export default function AppRoutes() {
  return (
    <Suspense fallback={<ProgressBar />}>
      <Switch>
        <Route exact path="/explore/api-service/:category">
          <ApiDetailsComponent />
        </Route>
        <Route path="/explore/api-service">
          <ApiOverviewScrollerHOC>
            {/* <ApiService /> */}
            <ApiServiceList />
          </ApiOverviewScrollerHOC>
        </Route>
        <Route exact path="/register">
          <GetStarted />
        </Route>
        <Route exact path="/GetStarted">
          <GetStarted />
        </Route>
        <Route exact path="/register/confirmation">
          <LazyConfirmationModule />
        </Route>
        <Route exact path="/register/thankyou">
          <Thankyou />
        </Route>
        <Route exact path="/register/expired/:token">
          <Expired />
        </Route>
        <Route exact path="/register/resent">
          <ResendEmail />
        </Route>
        <Route exact path="/register/verified">
          <AlreadyVerified />
        </Route>
        <Route exact path="/register/registered">
          <RegistrationDone />
        </Route>
        <Route exact path="/register/confirm/:token">
          <EmailConfirmation />
        </Route>
        <Route exact path="/register/error">
          <ErrorPage />
        </Route>
        <Route exact path="/explore/FAQuestions">
          <FAQComponent />
        </Route>
        <Route exact path="/register/guided-flow">
          <QuestionsCard />
        </Route>
        <Route exact path="/register/selectCareOne">
          <CardsOne />
        </Route>
        <Route exact path="/register/selectCareTwo">
          <CardsTwo />
        </Route>
        <Route exact path="/register/selectCareThree">
          <CardsThree />
        </Route>
        <Route exact path="/register/selectCareFour">
          <CardsFour />
        </Route>
        <Route exact path="/register/selectPartnerType">
          <PartnerTypeSelection />
        </Route>
        <Route exact path="/explore/apiDetails/:apiName">
          <ApiDetails />
        </Route>
        <Route exact path="/explore/Partners">
          <PartnerPageComponent />
        </Route>
        <Route exact path="/register/Ceros">
          <CerosPage />
        </Route>
        <Route exact path="/explore">
          <LazyLandingModule />
        </Route>
        <Route path="/">
          <Redirect to="explore" />
        </Route>
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </Suspense>
  );
}
