import React from 'react';
import { Button } from 'antd';
import { WelcomeSectionCSS, WelcomeSectionCSSMobile } from './PartnerPage.style';

const WelcomeBanner = ({ moduleTitle, linkText, onExplore }) => (
  <section id="welcome-banner" css={WelcomeSectionCSS}>
    <div className="backgroundImage">
      <div className="welcome_banner_content">
        <h2 className="first_headline">{moduleTitle}</h2>
        <h1 className="second_headline">{linkText}</h1>
        <Button className="tell_us_button" onClick={onExplore}>
          Explore APIs
        </Button>
      </div>
    </div>
  </section>
);
export {WelcomeBanner};

const WelcomeBannerMobile = ({ moduleTitle, linkText, onExplore }) => (
  <section id="welcome-banner-mobile" css={WelcomeSectionCSSMobile}>
    <div className='backgroundImage'>
      <div className="welcome_banner_content">
        <h2 className="first_headline">{moduleTitle}</h2>
      </div>
    </div>
    <div>
    <h1 className="second_headline">{linkText}</h1>
        <Button className="tell_us_button" onClick={onExplore}>
          Explore APIs
        </Button>
    </div>
  </section>
);

export { WelcomeBannerMobile };
