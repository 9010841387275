export const sortByStrProp = (prop, array) => {
  if (!prop) return;
  array.sort(function(a, b) {
    var nameA = a[prop]?.toUpperCase(); // ignore upper and lowercase
    var nameB = b[prop]?.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  });
};

export const sortByDateDes = array => {
  array.sort(function(a, b) {
    var key1 = new Date(a.eventTime);
    var key2 = new Date(b.eventTime);
    if (key1 < key2) {
      return -1;
    } else if (key1 == key2) {
      return 0;
    } else {
      return 1;
    }
  });
};

export const sortByDateAsc = array => {
  array.sort(function(a, b) {
    var key1 = new Date(a.eventTime);
    var key2 = new Date(b.eventTime);
    if (key1 < key2) {
      return 1;
    } else if (key1 == key2) {
      return 0;
    } else {
      return -1;
    }
  });
};

export const getPublicSiteImageSrc = (imageName) => {
  return require(`./../../common/img/new-public-Ui/${imageName}`).default
};