import React from 'react';
import { Col, Row, Card, Typography, Form, Space, Button, Tooltip, Switch } from 'antd';
import { API_DEVELOPMENT_STATUS } from './../../common/constants/AppConstants';
import { node, string, any, func, bool } from 'prop-types';
import { ApiDetailCSS } from '../Landing/ApiDetailsComponent.style';
import { getPublicSiteImageSrc } from 'Client/common/utils/AppUtils';

const ApiComponent = props => {
  const {
    apiId,
    apiTitle,
    apiDescription,
    isSelectedFormFieldPath,
    apiStatus,
    changeSelectAllFlag,
    notificationSupported,
    tooltipText,
  } = props;

  return (
    <Card className="api-info" css={ApiDetailCSS}>
      <Row gutter={[{ xs:2, sm:16, md:16}, {xs: 0, sm: 0, md: 16, lg: 24}]} justify="space-around" align="middle" className="app-row">
        <Col xs={3} sm={3} md={3} lg={2} className="api-img-col">
          <div className="api-img-container">
            <img
              className='api-img'
              src={getPublicSiteImageSrc(`api-images-list/${apiId?.toUpperCase()}.svg`)}
            />
          </div>
        </Col>
        <Col  xs={11} sm={14} md={14} lg={16}>
          <Row gutter={[{xs: 8, sm: 8, md:16}, {xs: 8, sm: 8, md:16}]} className="api-row">
            <Col>
              <Typography.Title level={3} id={`${apiTitle}-api-title`} className="apiTitle">
                {apiTitle}
              </Typography.Title>
            </Col>
            <Col span={4} className="subscription-col">
              {notificationSupported && (
                <Form.Item name={isSelectedFormFieldPath}>
                  <Tooltip className="notification-tooltip" title={tooltipText} color={'white'} key={'white'}>
                    <Button type="primary" className="notification-btn">
                      <Space>
                        <img className="notification-img" src={require('./../../common/img/NotificationBell.svg').default} />
                        <span>Get Notifications</span>
                      </Space>
                    </Button>
                  </Tooltip>
                </Form.Item>
              )}
            </Col>
          </Row>
          <Typography.Text level={4} className="apiDescription">
            <span dangerouslySetInnerHTML={{ __html: apiDescription }}></span>
          </Typography.Text>
        </Col>
        {apiStatus !== API_DEVELOPMENT_STATUS && (
          <Col xs={5} sm={4} md={3} lg={3} className="subscription-switch">
            <Form.Item name={isSelectedFormFieldPath} valuePropName="checked">
              <Switch className="tag-btn" onChange={changeSelectAllFlag} id={`${apiId}-select-btn`} size="large">
                Select
              </Switch>
            </Form.Item>
          </Col>
        )}
      </Row>
    </Card>
  );
};

ApiComponent.propTypes = {
  apiId: node,
  apiTitle: string,
  apiDescription: string,
  isSelectedFormFieldPath: any,
  apiStatus: string,
  changeSelectAllFlag: func,
  notificationSupported: bool,
  tooltipText: string,
};

export default ApiComponent;
