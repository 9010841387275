import React, { Fragment, useEffect } from 'react';
import { Typography, Card, Result, Button, Divider, Row, Col } from 'antd';
import CSSCacheProvider from './../../Contexts/CSSCacheProvider';
import { PARTNER_REGISTER_DESCRIPTION } from './../../common/constants/AppConstants';
import { CheckCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/core';
import { useHistory } from 'react-router-dom';

export const greetingCardHeader = css`
  padding: 3em 3.8em 2em;
  background: inherit;
  .ant-card-body {
    padding: 0px;
  }
  h2.username {
    font-weight: 300;
    text-transform: capitalize;
    margin-top: 0.5em;
  }
  h3.description {
    font-weight: 300;
    margin-top: 35px;
    background: inherit;
    color: white;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }
`;

export const cssContent = css`
  background: transparent linear-gradient(90deg, #58abdf 0%, #286ce2 100%) 0% 0% no-repeat padding-box;
  background-size: 100% 200px;
  padding: 0 100px;
  margin-bottom: 4em;
  @media (max-width: 1024px) {
    padding: 0 10px;
  }
`;

const confirmationCss = css`
  .ant-result-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 23px;
    line-height: 1.8;
    text-align: center;
    font-family: Montserrat;
    font-weight: 600;
  }

  span.ant-typography {
    font-family: Montserrat;
    font-weight: 500;
  }

  .ant-result-info .ant-result-icon > .anticon {
    color: #286ce2 !important;
  }

  .ant-result-icon > .anticon {
    font-size: 45px;
  }

  .ant-divider {
    border: none;
  }

  .ant-typography + h1.ant-typography,
  .ant-typography + h2.ant-typography,
  .ant-typography + h3.ant-typography,
  .ant-typography + h4.ant-typography {
    margin-top: none !important;
  }

  h1.ant-typography,
  .ant-typography h1 {
    margin-bottom: -0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.23;
  }
`;

const Confirmation = () => {
  const history = useHistory();
  const okButtonHandler = () => {
    window.location.assign('/login');
  };
  const cancelButtonHandler = () => {
    history.push('/explore');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div css={cssContent}>
      <Card css={greetingCardHeader} bordered={false}>
        <Typography.Title level={3} className="description heading-underline">
          Register
        </Typography.Title>
      </Card>
      <Card css={confirmationCss}>
        <Result
          title={
            <Fragment>
              <Typography.Title>Thank you for showing interest in our APIs. </Typography.Title>
              <Typography.Title>Our team will review your details and get back to you soon.</Typography.Title>
            </Fragment>
          }
          icon={<CheckCircleOutlined />}
          extra={
            <Fragment>
              <Row gutter={24} justify="center">
                <Col md={24}>
                  <Typography.Text>{PARTNER_REGISTER_DESCRIPTION}</Typography.Text>
                  <Divider style={{ margin: '10px' }} />
                </Col>
                <Col md={6}>
                  <Button
                    type="primary"
                    size="large"
                    className="btn-large-primary"
                    block
                    onClick={cancelButtonHandler}
                    id="back-to-home-btn"
                  >
                    Back to home page
                  </Button>
                  <Divider style={{ margin: '10px' }} />
                  <Button
                    type="default"
                    size="large"
                    className="btn-large-secondary"
                    block
                    onClick={okButtonHandler}
                    id="go-to-login-btn"
                  >
                    Log In
                  </Button>
                </Col>
              </Row>
            </Fragment>
          }
        />
      </Card>
    </div>
  );
};

export default function LazyConfirmationModule() {
  return (
    <CSSCacheProvider value={{ key: 'registration' }}>
      <Confirmation />
    </CSSCacheProvider>
  );
}
