import { css } from '@emotion/core';
export const apiListDisplayCss = css`

.divider-visible-css {
 display: none;
}
.drawer-toggle {
   display: none;
  }
.show-filter-text {
   color: #1355E9;;
    font-size: 16px;
    font-weight: 600;
  }
.filterAndListContainer {
    padding: 50px 80px 120px;
    background: #fff;   
  }
  @media (max-width: 920px) {
  .filterAndListContainer {
    padding:15px;
   }
  }
  @media (max-width: 430px) {
  .filterAndListContainer {
    padding:15px;
   }
  .drawer-toggle {
    display: block;
    cursor: pointer;
    text-align: right;
    margin: 20px 30px;
  }
 .divider-visible-css {
    display: block;
  }
  }
`;