import React from 'react';
import { Row, Col, Typography } from 'antd';
import { partnerTypeCss, partnerTypeCssMobile } from './PartnerPage.style';

const PartnerTypeSection = ({ partnerDetails }) => (
  <Row id="partner-type" justify="center" className="partner-type_section" css={partnerTypeCss} gutter={[16, 16]}>
    <Col md={20}>
      <Row align="middle" className="col__widget_col">
        <Col sm={24}>
          <div>
            <p className="section__welcome_line1">Better Data, Seamless Integration</p>
          </div>
        </Col>
        <Col sm={24}>
          <div>
            <p className="section__welcome_line">Our Partner types</p>
          </div>
        </Col>
      <Col lg={24} className="partnerDetails">
        <Row gutter={[16, {xs: 8, sm: 16, md: 24, lg: 32 }]}>
        {
          partnerDetails?.ListAssoc?.slice(0, 3).map((partner, index) => (
            <Col  key={index} sm={24} align="middle" >
              <div>
                <Row className="partnerDisplay" align="middle" gutter={[24, 24]}>
                  <Col xs={24}>

                      {
                        index % 2 === 0 ?
                        <Row justify="center" gutter={[80, 80]}>
                          <Col sm={24} xs={10} className="partnerDisplayContent-right">
                            <div>
                              <img
                                src={require(`./../../common/img/new-public-Ui/${partner.name}_image.png`).default}
                                alt="Avatar"
                                className="partnerDisplayImage"/>
                            </div>
                          </Col>
                          <Col  sm={24} xs={10} className="partnerDisplayContent-right">
                            <Row align="middle" className="partnerDisplayDesc">
                              <Col xs={24}>
                                <Typography.Title className='partnerDisplayTitle' level={3}>{partner.title}</Typography.Title>
                              </Col>
                              <Col xs={24}>
                                <span className='partnerDisplayDescription'>{partner.description}</span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>:
                        <Row justify="center" gutter={[80, 80]}>
                          <Col  sm={24} xs={10} className="partnerDisplayContent-left">
                            <Row align="middle" className="partnerDisplayDesc">
                              <Col xs={24}>
                                <Typography.Title className="partnerDisplayTitle" level={3}>{partner.title}</Typography.Title>
                              </Col>
                              <Col xs={24}>
                                <span className='partnerDisplayDescription'>{partner.description}</span>
                              </Col>
                            </Row>
                          </Col>
                          <Col sm={24} xs={10} className="partnerDisplayContent-left">
                            <div>
                              <img
                                src={require(`./../../common/img/new-public-Ui/${partner.name}_image.png`).default}
                                alt="Avatar"
                                className="partnerDisplayImage"/>
                            </div>
                          </Col>
                        </Row>
                      }
                  </Col>
                </Row>
              </div>
            </Col>
        ))}
      </Row>
      </Col>
      </Row>
    </Col>
  </Row>
);

export {PartnerTypeSection};

const PartnerTypeSectionMobile = ({ partnerDetails }) => (
  <Row id="partner-type" justify="center" className="partner-type_section" css={partnerTypeCssMobile}>
    <Col xs= {24} md={24}>
      <Row align="middle" className="col__widget_col">
        <Col xs= {24} sm={24}>
          <div>
            <p className="section__welcome_line1">Better Data, Seamless Integration</p>
          </div>
        </Col>
        <Col xs= {24} sm={24}>
          <div>
            <p className="section__welcome_line">Our Partner types</p>
          </div>
        </Col>
      <Col xs= {24} lg={24} className="partnerDetails">
        <Row gutter={[ {xs: 24, sm: 24, md: 24, lg: 32 }]} className='partnerRow'>
        {
          partnerDetails?.ListAssoc?.slice(0, 3).map((partner, index) => (
            <Col  key={index} sm={24} align="middle" >
              <div>
                <Row className="partnerDisplay" align="middle">
                  <Col xs={24}>
                        <Row justify="center">
                          <Col sm={24} xs={24} className="partnerDisplayContent-right">
                            <div>
                              <img
                                src={require(`./../../common/img/new-public-Ui/${partner.name}_image.png`).default}
                                alt="Avatar"
                                className="partnerDisplayImage"/>
                            </div>
                          </Col>
                          <Col  sm={24} xs={24} className="partnerDisplayContent-right">
                            <Row align="middle" className="partnerDisplayDesc">
                              <Col xs={24}>
                                <Typography.Title className='partnerDisplayTitle' level={3}>{partner.title}</Typography.Title>
                              </Col>
                              <Col xs={24}>
                                <span className='partnerDisplayDescription'>{partner.description}</span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                  </Col>
                </Row>
              </div>
            </Col>
        ))}
      </Row>
      </Col>
      </Row>
    </Col>
  </Row>
);

export {PartnerTypeSectionMobile};