// Desc: ApiServiceList component
import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import ApiList from './ApiList';
import ApiSearch from './ApiSearch';
import { getApiList, getFilters } from 'Client/services/registration.service';
import { showError} from '../../components/ErrorModal/ErrorModal';
import ProgressBar from 'Client/components/ProgressBar/ProgressBar';

const ApiServiceList = () => {
  const [apiFilters, setApiFilters] = useState({ results: { data: [] }, status: 'pending' });
  const [apiList, setApiList] = useState({ status: 'pending', data: [], error: null });

  const history = useHistory();

  

  useEffect(() => {
    async function getApiFilters() {
      getFilters()
        .then(res => {
          setApiFilters({ results: { ...res?.data?.results }, status: 'success' });
        })
        .catch(err => {
          setApiFilters({ results: { data: [] }, status: 'error' });
        });
    }
    getApiFilters();
    getAPIList();
  }, []);

  const backToHome = () => {
    history.push('/');
  }
  
  const getAPIList = () => {
    getApiList()
      .then(res => {
        setApiList({ data: res?.data?.results?.data, status: 'success', error: null });
      })
      .catch(err => {
        showError({err: err, backToHome: backToHome});
        setApiList({ data: [], status: 'error', error: err });
      });
  };
  return (
    <Fragment>
      <>
        <ApiSearch></ApiSearch>
        { 
          (apiFilters.status != 'error' && apiFilters.results?.data?.length > 0) &&  
          <ApiList apiList={apiList} apiFilters={apiFilters?.results} />
        }
      </>
    </Fragment>
  );
};

export default ApiServiceList;
