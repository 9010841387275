import { css } from '@emotion/core';

export const ApiDetailsCss = css`
  ${'' /* border-top: 1px solid #e8e8e8; */}
  padding: 5px;
  ul {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding-left: 4px;
  }
  .sectionHeading {
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    border: 2px solid #e8e8e8;
    border-radius: 5px;
  }
  .filterTypes {
    margin: 5px;
    font-size: 16px;
  }

  .apiListItem {
    display: flex;
    align-items: start;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    padding: 15px 0;
  }

  .noApiFound {
    margin-Bottom: 20px;
    font-Size: 16px;
    font-Weight: 600;
    margin-left: 20px;
  }

  .apiImage {
    margin-right: 10px;
    width: 60px; /* Adjust the width as needed */
    height: 60px; /* Adjust the height as needed */
  }

  .apiListCard {
    display: flex;
    flex-direction: column;
  }

  .apiHeading {
    color: #333;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Elevance Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 100% */
    padding-left: 30px;
  }

  .apiDesc {
    color: #666;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Elevance Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 187.5% */
    letter-spacing: -0.361px;
    padding-left: 30px;
  }

  .sort-input {
    width: 230px;
    font-family: Elevance Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #333333;
  }

  .readmore-text {
    font-family: Elevance Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.3611111044883728px;
    color: #1355E9;
  }

  .messageContainer {
    display: flex;
    flexDirection: 'row';
    flexWrap: 'wrap';
  }
  @media (max-width: 768px) {
    padding: 5px 20px;
  }

  @media (max-width: 430px) {
    .showingAllStyle {
      padding: 5px;
    }
  }
`;

export const ErrorModalCss = css`
  .ant-modal-content {
    width: 100% !important;
  }
`;

export const SortStyle = css`
    .sort-label {
      font-family: Elevance Sans;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #333333;
    }
    
  .sortContainerStyle {
    display: flex;
    justify-Content: flex-end;
    margin-Bottom: 20px;
    font-Size: 16px;
    font-Weight: 600;
    margin-Top: -40px;
  }

    .ant-select {
      width: 14.375rem;
      height: 2.5rem;
      .ant-select-selector {
        border-radius: .3125rem;
        height: inherit !important;

        .ant-select-selection-item {
          display: flex;
          align-items: center;
        }
      }
    }
    .showingAllStyle {
      display:flex;
      margin-Top: 10px;
      font-Size: 16px;
      font-Weight: 600;
      padding-Left: 20px;
    }

    @media (max-width: 768px) {
    .sortContainerStyle {
    margin-Top: 20px;
  }
    .showingAllStyle {
      padding-Left: 20px;
    }

  }
     @media (max-width: 430px) {
     .showingAllStyle {
      padding-Left: 8px;
    }
     }
  `;