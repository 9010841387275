import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, Space, message } from 'antd';
import { css } from '@emotion/core';
import { LeftOutlined } from '@ant-design/icons';
import Button from 'Client/components/Buttons/Button';
import CardsOne from './CardsOne';
import CardsTwo from './CardsTwo';
import CardsThree from './CardsThree';
import CardsFour from './CardsFour';
import { cssContent, footerSection, greetingCardHeader, QuestionCardCss } from './QuestionCard.style';
import { ALL_APIS_TEXT, INTERESTED_APIS, INTERESTED_LOBS } from 'Client/common/constants/AppConstants';
import * as _ from 'lodash';


const QuestionsCard = () => {

  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [isBroker, setIsBroker] = useState(null);
  const [partnerType , setPartnerType] = useState(null);
  const [interestedLOBs, setInterestedLOBs] = useState([]);
  const [anthemAppointedBroker, setAnthemAppointedBroker] = useState(null);
  const [contactPersonName, setContactPersonName] = useState(null);
  const [interestedApis, setInterestedApis] = useState([]);

  const backToHome = () => {
    history.push('/')
  };

  const handleRadioSelection = event => {
    const { target } = event;
    if(target.name=="isBroker") {
      setIsBroker(target.value);
    } else if (target.name=="partnerType") {
      setPartnerType(target.value);
    } else if (target.name=="anthemAppointedBroker") {
      setAnthemAppointedBroker(target.value);
    }
  };

  const handleLOBSelection = list => {
    setInterestedLOBs(list);
  }

  const handleContactPersonInput = event => {
    setContactPersonName(event.target.value);
  }

  const goToNextPage = () => {
    if (currentPage == 1 && isBroker === null) {
      message.error('Please select an option');
    } else if (currentPage == 1 && isBroker == false && partnerType === null) {
      message.error('Please select partner type');
    } else if (currentPage == 2 && interestedLOBs.length === 0) {
      message.error('Please select at least one option');
    } else if (currentPage == 3 && anthemAppointedBroker === null) {
      message.error('Please select an option');
    } else if (currentPage == 4 && interestedApis.length === 0) {
      message.error('Please select at least one option');
    } else {
      setCurrentPage(prevPage => {
        if (prevPage < 4) {
          return currentPage + 1;
        }
        if (prevPage === 4) {
          // data collected
          const data = {
            isBroker,
            partnerType,
            interestedLOBs,
            anthemAppointedBroker,
            contactPersonName,
            interestedApis
          };

          history.push({
            pathname: '/register',
            state: { registrationFlow: "qna", persona: { 
              anthemAppointedBroker, 
              thirdPartyAdmin: !anthemAppointedBroker,
              contactPersonName,
              allApis: interestedApis.includes(ALL_APIS_TEXT),
              interestedLOBs: getInterestedLOBs(interestedLOBs),
              interestedApis: getInterestedApis(interestedApis),
              type: partnerType,
            }}
          });
        }
      });
    }
  }

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  }

  const getInterestedApis = list => {
    const allText = ALL_APIS_TEXT;
    if (list.includes(allText)) {
      return _.without(INTERESTED_APIS, allText);
    } 
    return list;
  }

  const getInterestedLOBs = list => {
    const allText = 'All Business';
    if (list.includes(allText)) {
      return _.without(INTERESTED_LOBS, allText);
    }
    return list;
  }

  return (
    <div css={cssContent}>
      <div className="recommendapis-section">
        <Card css={greetingCardHeader} bordered={false}>
          <div className="backToHome">
            <a onClick={() => backToHome('/explore')} style={{ color: '#1354E9' }}>
            <Space>
              <LeftOutlined />
              <span>Back to Home</span>
            </Space>
            </a>
          </div>
        </Card>
        <div css={QuestionCardCss}>
          <div className='question-title-box'>
            <span className='question-title'>
              Answer a few quick questions and we'll recommend API connections that match your business model:{' '}
            </span>
          </div>
        <div>
          {
            currentPage === 1 ? 
            <CardsOne 
              handleRadioSelection={handleRadioSelection} 
              backToHome={backToHome}
              isBroker={isBroker}
              partnerType={partnerType}
            /> : ''
          }
          { 
            currentPage === 2 ? 
            <CardsTwo
              handleCheckboxSelection={handleLOBSelection}
              interestedLOBs={interestedLOBs}
              backToHome={backToHome}
            />
             : ''
          }
          { 
            currentPage === 3 ? 
            <CardsThree
              handleRadioSelection={handleRadioSelection}
              anthemAppointedBroker={anthemAppointedBroker}
              handleContactPersonInput={handleContactPersonInput}
              contactPersonName={contactPersonName}
              backToHome={backToHome} 
            />
            : ''}
          {
            currentPage === 4 ? 
            <CardsFour 
              handleCheckboxSelection={setInterestedApis}
              interestedApis={interestedApis}
              backToHome={backToHome}/> 
            : ''
          }
        </div>
      </div>
      <div css={footerSection}>
        <Card bordered={false} className="nextButton-card">
            <Row className="nextButton-row" gutter={[{xs:32, sm:32, md: 32, lg: 32, xl:16}, 24]}>
              { 
              ( currentPage !== 1 &&  currentPage <= 4) && (
                <Col xs={{offset: 0, span: 8}} sm={{offset: 0, span: 8}} md={{offset: 4, span: 8}}>
                  <Button type="app-secondary" className="nextButton" onClick={() => goToPreviousPage()}>
                    Previous
                  </Button>
                </Col>
              )
              }
              { 
                currentPage <= 4 && 
                <Col span={8}>
                  <Button className="nextButton" onClick={() => goToNextPage()}>
                    Next
                  </Button>
                </Col>
              }
            </Row>
        </Card>
        </div>
      </div>
    </div>
  );
};

export default QuestionsCard;
