import { get, post } from 'axios';

const baseURL = '/public/api';

export const getApiList = () => {
  const url = `${baseURL}/enrollmentList`;
  return post(url);
};

export const getSubscriptionList = () => {
  const url = `${baseURL}/subscriptions/subscribables`;
  return get(url);
};

export const registerPartner = data => {
  const url = `${baseURL}/enroll`;
  return post(url, data);
};

export const emailVerificationCode = token => {
  const url = `${baseURL}/emailVerification/${token}`;
  return get(url);
};

export const resendEmail = token => {
  const url = `${baseURL}/resendEmail/${token}`;
  return get(url);
};

export const getFilters = () => {
  const url = `${baseURL}/filters`;
  return post(url);
};
