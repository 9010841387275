import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import { Select, Space, Modal } from 'antd';
import { useHistory } from 'react-router-dom';

import { ApiDetailsCss, ErrorModalCss, SortStyle } from './ApiDetailList.style';
import { API_DEVELOPMENT_STATUS } from './../../common/constants/AppConstants';
import { FilterMapping, FilterPersonas } from './../../common/constants/AppConstants';
import { getPublicSiteImageSrc } from 'Client/common/utils/AppUtils';
import { Button } from 'react-scroll';
import { CloseOutlined } from '@ant-design/icons';
import ProgressBar from 'Client/components/ProgressBar/ProgressBar';

const { Option } = Select;

const ApiDetailList = ({ apiList, groupedCheckedValues, setGroupedCheckedValues, setCheckedValues }) => {
  const [sortType, setSortType] = useState('A-Z'); // State for sort type
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
  const history = useHistory();

  const handleSortChange = value => {
    setSortType(value);
  };

  const makeLink = name => {
    var contactLink = document.createElement('a'); //create <a> tag
    contactLink.setAttribute('id', 'linkC'); //set id att for <a> tag
    contactLink.classList.add('readmore-text'); // add class to <a> tag
    var contactLinkTxt = document.createTextNode(name); //new text node
    contactLink.appendChild(contactLinkTxt); //append text as child of <a> tag
    return contactLink;
  };

  const makeMessage = item => {
    const word = '. Read More';
    var highRiskmsg = item.apiDescription;
    if (highRiskmsg.includes(word)) {
      return item.apiDescription;
    } else {
      var span = document.createElement('span');
      span.innerHTML = highRiskmsg;
      span.appendChild(makeLink(word));
      return span.outerHTML;
    }
  };

  const filteredApiList =
    Object.keys(groupedCheckedValues).length === 0
      ? apiList?.data
      : apiList?.data.filter(api => {
          return Object.entries(groupedCheckedValues).every(([category, values]) => {
            const apiField = FilterMapping[category];
            if (apiField === 'persona') {
              if (values.some(value => value.toLowerCase() === 'all')) {
                return Object.keys(FilterPersonas).some(persona => api[apiField].includes(FilterPersonas[persona]));
              }
              return values.some(value => api[apiField].includes(FilterPersonas[value]));
            }
            if (category === 'POPULAR APIs') {
              return values.includes(api[apiField]);
            }
            return values?.some(value => api[apiField]?.includes(value));
          });
        });

  const sortedApiList = [...filteredApiList].sort((a, b) => {
    if (sortType === 'A-Z') {
      return a.apiName.localeCompare(b.apiName);
    } else if (sortType === 'Z-A') {
      return b.apiName.localeCompare(a.apiName);
    }
    return 0;
  });

  const generatedApiList = [...sortedApiList].map(item => {
    item.apiDescription = makeMessage(item);
    return item;
  });

  const onclickReadMore = text => {
    history.push(`/explore/apiDetails/${text}`);
  };

  useEffect(() => {
    if (generatedApiList.length === 0) {
      setIsModalVisible(true);
    }
  }, [generatedApiList]);

  const handleOk = () => {
    setIsModalVisible(false);
    setGroupedCheckedValues({});
    setCheckedValues([]);
  };

  return (
    apiList?.status === 'pending' ? 
    <ProgressBar blueColor />
    :
    <>
      <div css={SortStyle}>
        <div className="showingAllStyle">
          <span>
            <label>
              <b>Showing all {sortedApiList.length} APIs </b>
            </label>
          </span>
        </div>
        <div className="sortContainerStyle">
          <span className="sort-section">
            <Space>
              <label>
                <span className="sort-label">Sort By </span>
              </label>
              <Select
                defaultValue="A-Z"
                className="sort-input"
                onChange={handleSortChange}
                suffixIcon={<img src={getPublicSiteImageSrc('select_suffix_icon.svg')} />}
              >
                <Option value="A-Z">API Name (A-Z)</Option>
                <Option value="Z-A">API Name (Z-A)</Option>
              </Select>
            </Space>
          </span>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }} css={ApiDetailsCss}>
        {(generatedApiList.length === 0 && apiList?.status === "success") ? (
          <Modal closable = {false} visible={isModalVisible} onOk={handleOk} footer={null} centered css={ErrorModalCss}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', fontFamily: "Elevance Sans"}}>
            <CloseOutlined
                style={{ position: 'absolute', top: '16px', right: '16px', cursor: 'pointer',  color: '#376FEC', fontSize: '20px' }}
                onClick={handleOk}
              />
              <img
                src={require(`./../../common/img/new-public-Ui/status_icon.svg`).default}
                alt="Status Icon"
                style={{ marginBottom: '16px' }}
              />
              <h3 style={{fontWeight:600, fontSize: 24}}>No Matching API‘s Found</h3>
              <p style={{fontWeight:400, fontSize: 18}}>Consider adjusting your filters to find suitable API‘s</p>
            </div>
          </Modal>
        ) : (
          generatedApiList.map(
            (api, index) =>
              api.apiStatus !== API_DEVELOPMENT_STATUS && (
                <div key={index} className="apiListItem">
                  <img
                    src={require(`./../../common/img/new-public-Ui/api-images-list/${api?.apiId}.svg`).default}
                    alt={`${api.apiName} logo`}
                    className="apiImage"
                  />
                  <div className="apiListCard">
                    <h3 className="apiHeading">{api.apiName}</h3>
                    <div className="messageContainer">
                      <p
                        className="apiDesc"
                        dangerouslySetInnerHTML={{ __html: api.apiDescription }}
                        onClick={() => onclickReadMore(api.apiName)}
                      ></p>
                    </div>
                  </div>
                </div>
              ),
          )
        )}
      </div>
    </>
  );
};

export default ApiDetailList;
