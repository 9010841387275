import React, { Fragment, useEffect, useContext, useState } from 'react';
import { Typography, Card, Row, Col, Radio } from 'antd';
import { css } from '@emotion/core';
import { LeftOutlined } from '@ant-design/icons';
import { AppContext } from './../../Contexts/AppContext';
import Button from 'Client/components/Buttons/Button';
import { useHistory } from 'react-router-dom';

export const greetingCardHeader = css`
  padding: 1em 0;
  background: inherit;
  font-weight: 500;
  font-size: 20px;
  .ant-card-body {
    padding: 24px 2px;
  }
`;

export const cssContent = css`
  background: #fff;
`;

export const footerSection = css`
  background: #fff;
  border: 2px solid #f0f0f0;

  .nextButton-card {
    margin-left: 80%;
  }
  .nextButton {
    font-size: 20px;
    height: 50px;
    width: 150px;
    border: 1px solid #286ce2;
    display: block;
    background-color: #1354e9;
    border-radius: 30px;
    color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
`;

const alreadyVerifiedCss = css`
  .ant-result-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 23px;
    line-height: 1.8;
    text-align: left;
    font-family: Montserrat;
    font-weight: 600;
  }

  .partnerType-card {
    border: 2px solid #f0f0f0;
  }

  span.ant-typography {
    font-family: Montserrat;
    font-weight: 500;
  }

  .ant-result-info .ant-result-icon > .anticon {
    color: #286ce2 !important;
  }

  .ant-result-icon > .anticon {
    font-size: 65px;
  }

  .ant-divider {
    border: none;
  }

  .ant-typography + h1.ant-typography,
  .ant-typography + h2.ant-typography,
  .ant-typography + h3.ant-typography,
  .ant-typography + h4.ant-typography {
    margin-top: none !important;
  }

  h1.ant-typography,
  .ant-typography h1 {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
    line-height: 1.23;
  }

  #resend-email-btn {
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    .ant-result-icon span img {
      height: 65px;
    }

    h1.ant-typography,
    .ant-typography h1 {
      font-size: 16px;
    }
    .ant-result-extra .ant-typography {
      font-size: 12px;
    }
  }

  .partnerType_button {
    font-size: 22px;
    font-weight: 600;
  }
  .partnerType-desc {
    font-size: 16px;
    color: #666666;
  }
`;

const CerosPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();

  const backToHome = value => {
    history.push(value);
  };

  return (
    <Fragment>
      <div css={cssContent} className="back-button">
        <iframe
          src="https://view.ceros.com/elevancehealth/project-1-2-1"
          width="100%"
          height="900px"
          style={{ border: 'none' }}
          title="Ceros Project"
        ></iframe>
      </div>
    </Fragment>
  );
};

export default CerosPage;
