// ApiFilters.js
import React, { useState, useEffect } from 'react';
import { Space, Row, Col } from 'antd';

import { ApiFilterCss } from './ApiFilters.style';

const ApiFilters = ({ apiFilters, checkedValues, handleCheckboxChange, handleApply, handleClearAll }) => {
  const [isFilterSelected, setIsFilterSelected] = useState(false);

  useEffect(() => {
    setIsFilterSelected(checkedValues.length > 0);
  }, [checkedValues]);

  return (
    <div css={ApiFilterCss} className="filterListContainer">
      {apiFilters.data.map((category, index) => (
        <div key={index}>
          <h3 className="sectionHeading">{category.heading}</h3>
          {
            category.filters.map((filter, filterIndex) => (
            <div key={filterIndex} className="filterTypes">
              <Space>
                <input
                  style={{ marginRight: '10px' }}
                  type="checkbox"
                  id={`filter-${index}-${filterIndex}`}
                  checked={checkedValues.includes(filter.text)}
                  onChange={() => handleCheckboxChange(filter.text)}
                />
                <label htmlFor={`filter-${index}-${filterIndex}`} className="filter-label">
                  <Row justify="space-between">
                    <Col span={20}>
                      <span className="filter-text">{filter.text}</span>
                    </Col>
                    <Col span={2}>
                    {
                      (filter && filter.count != undefined) && <span className="filter-count">{filter.count}</span>
                    }
                    </Col>
                  </Row>
                </label>
              </Space>
            </div>
          ))}
        </div>
      ))}
      <div className="button-container">
      <button
          onClick={handleApply}
          className={`tell_us_button ${!isFilterSelected ? 'disabled-button' : ''}`}
          disabled={!isFilterSelected}
        >
          Apply
        </button>
      </div>
      <div className="link-container">
        <a onClick={handleClearAll} className="clear_all_button">
          Clear All
        </a>
      </div>
    </div>
  );
};

export default ApiFilters;
