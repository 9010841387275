import React, { Fragment, useState, useEffect } from 'react';
import { string, object } from 'prop-types';
import { Typography, Card, Row, Col, Space, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { getPublicSiteImageSrc } from 'Client/common/utils/AppUtils';

const ApiWidget = props => {
  const { apiData, category } = props;
  const history = useHistory();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1240);
  const [isMobileDevice, setIsMobileDevice] = useState(window.innerWidth <= 500);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1366);
      setIsMobileDevice(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const selectPartnerType = apiName => {
    if (apiName) {
      history.push(`/explore/apiDetails/${apiName}`);
    }
  };

  const moreAPIs = () => {
    history.push('/explore/api-service');
  };

  return (
    <Fragment>
      {apiData && (
        <section className="section__api_widget">
          <div bordered={false} className="card__api_widget">
            <div>
              <Row gutter={[8, 24]} justify="space-between" align="middle">
                {apiData?.ListAssoc?.slice(0, isMobileDevice ? 4 : 6)?.map(api => {
                  return (
                    <Col sm={11} xs={11} md={8} lg={8} xl={8} key={api?.name}>
                      <Card className="card__api_list" onClick={() => selectPartnerType(api?.title)}>
                        <div className="container">
                          <div className="api_cards">
                            <Row justify="center" align="middle" style={{height: 'inherit'}}>
                              <Col span={20} className="align-center">
                                <img
                                  src={getPublicSiteImageSrc(api?.name + ".svg")}
                                  alt="Avatar"
                                  className="image"
                                ></img>
                              </Col>
                              <Col span={24}>
                                  <p className="api_title">{api?.title}</p>
                              </Col>
                            </Row>
                          </div>
                          <div className="overlay">
                            <Row className="overlay-container" justify="center" align="middle"> 
                              <Col>
                                <p className="api_title">{api?.title}</p>
                              </Col>
                              <Col>
                                <Typography.Paragraph 
                                  ellipsis={isMobile ? { rows: 3, expandable: false, suffix: 'more'  } : false}
                                  className="api_desc"
                                >
                                  { api?.description }
                                </Typography.Paragraph>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
              <Button className="more_info_api" onClick={moreAPIs}>
                <div className='more-info-api-text'>
                  More APIs
                  </div>
                </Button>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

ApiWidget.propTypes = {
  apiData: object,
  category: string,
};

export default ApiWidget;
