import React, { Fragment, useState } from 'react';
import { object, func, bool } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Menu, Drawer, Space, Button, Row, Col } from 'antd';
import { SecondaryButton } from 'Client/components/Buttons/SecondaryButton';
import { LockOutlined } from '@ant-design/icons';

import { MobileDrawerCss } from './MobileDrawer.style';
import './MobileDrawer.css';

const { SubMenu } = Menu;
import { HashLink as Link } from 'react-router-hash-link';
import { getPublicSiteImageSrc } from 'Client/common/utils/AppUtils';

const scrollWidthOffset = el => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -220;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const MobileDrawer = props => {
  const history = useHistory();
  const { agencyApiData, commercialApiData, visible, closeDrawer } = props;
  const [stateOpenKeys, setStateOpenKeys] = useState([]);
  
  

  const Services = {
    agency: [
      getItem('Applications List and Details API', 'Applications List and Details API'),
      getItem('Benefit Details API', 'Benefit Details API'),
      getItem('Book of Business API', 'Book of Business API'),
      getItem('Broker News Feed API', 'Broker News Feed API'),
      getItem('Commissions API', 'Commissions API'),
      getItem('Small Group Renewal Packet API', 'Small Group Renewal Packet API'),
      getItem('Opportunity Management API', 'Opportunity Management API'),
      getItem('Application Submission API', 'Application Submission API'),
      getItem('Application Status Pull Notification', 'Application Status Pull Notification'),
      getItem('Group Structure API', 'Group Structure API'),
      getItem('Member Maintenance API', 'Member Maintenance API'),
      getItem('Document Upload API', 'Document Upload API'),
      getItem('Member Enrollment API', 'Member Enrollment API'),
      getItem('Quote Management API', 'Quote Management API'),
    ],

    benefit: [
      getItem('Book of Business API', 'Book of Business API'),
      getItem('Benefit Details API', 'Benefit Details API'),
      getItem('Application Submission API', 'Application Submission API'),
      getItem('Group Structure API', 'Group Structure API'),
      getItem('Member Maintenance API', 'Member Maintenance API'),
      getItem('Member Enrollment API', 'Member Enrollment API'),
      getItem('Quote Management API', 'Quote Management API'),
    ],
  
    techConnect: [
      getItem('Book of Business API', 'Book of Business API'),
      getItem('Benefit Details API', 'Benefit Details API'),
      getItem('Small Group Renewal Packet API', 'Small Group Renewal Packet API'),
      getItem('Application Submission API', 'Application Submission API'),
      getItem('Group Structure API', 'Group Structure API'),
      getItem('Member Maintenance API', 'Member Maintenance API'),
      getItem('Member Enrollment API', 'Member Enrollment API'),
      getItem('Quote Management API', 'Quote Management API'),
    ],
    management: [
      getItem('Commissions API', 'Commissions API'),
      getItem('Book of Business API', 'Book of Business API'),
      getItem('Applications List and Details API', 'Applications List and Details API'),
      getItem('Opportunity Management API', 'Opportunity Management API'),
      getItem('Benefit Details API', 'Benefit Details API'),
      getItem('Broker News Feed API', 'Broker News Feed API'),
      getItem('Small Group Renewal Packet API', 'Small Group Renewal Packet API'),
    ],
    commercial: [
      getItem('Application Submission API', 'Application Submission API'),
      getItem('Application Status Pull Notification', 'Application Status Pull Notification'),
      getItem('Group Structure API', 'Group Structure API'),
      getItem('Member Maintenance API', 'Member Maintenance API'),
      getItem('Member Enrollment API', 'Member Enrollment API'),
      getItem('Quote Management API', 'Quote Management API'),
      getItem('Document Upload API', 'Document Upload API'),
    ],
  };



  function getItem(label, key, children, type) {
    return {
      key,
      children,
      label,
      type,
    };
  }

  

  const items = [
    getItem(
    <div className="menu-btn-container">
      <Row gutter={[0, 16]}>
        <Col span={4}>
          <img src={getPublicSiteImageSrc('drawer_back_button.svg')} alt="back button" />
        </Col>
        <Col span={24}>
          <Space>
            <Button
               icon={ <LockOutlined />}
              className="menu_login_btn menu_btn"
              onClick={() => window.location.assign('/login')}
            >
              Log In
            </Button>

            <SecondaryButton
              className="menu_register_btn menu_btn"
              onClick={() => history.push('/register')}
            >
              Get Started
            </SecondaryButton>
          </Space>
        </Col>
      </Row>
    </div>, 'register', undefined, 'register-button'),
    getItem(<div className='menu' > Home </div>, 'home'),
    getItem(<div className='menu' > API Services </div>, 'api_services', [
      getItem(<div className='menuTitle' > CHOOSE API BY PARTNER</div>, 'choose_api_by_partner', [getItem(<div className={(stateOpenKeys.includes('for_agencies') ? 'subMenuTitleSelected' : 'subMenuTitle')}>             
        For Agencies         
      </div>, 'for_agencies', Services.agency), getItem(<div className={(stateOpenKeys.includes('benifit_administrators') ? 'subMenuTitleSelected' : 'subMenuTitle')}>             
        Benifit Administrators         
      </div>, 'benifit_administrators', Services.benefit), getItem(<div className={(stateOpenKeys.includes('technology_partner') ? 'subMenuTitleSelected' : 'subMenuTitle')}>             
        Technology Partner  
      </div>, 'technology_partner',Services.techConnect)]),
      getItem(<div className='menuTitle' > API BY BUSINESS CASE</div>, 'api_by_business_case', [getItem(<div className={(stateOpenKeys.includes('agencies_management') ? 'subMenuTitleSelected' : 'subMenuTitle')}>             
        Agencies Management
      </div>, 'agencies_management', Services.management), getItem(<div className={(stateOpenKeys.includes('commercial-group-submission') ? 'subMenuTitleSelected' : 'subMenuTitle')}>             
      Commercial Group Submission & Member Management
      </div>, 'commercial-group-submission', Services.commercial)]),
    ]),
    getItem(<div className='menu' > Partners </div>, 'partner'),
  ];

  const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };
  
  const levelKeys = getLevelKeys(items);

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1
    );
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
    
  };

  const OnClick = (item) => {
    if(item.key === 'register') {
      closeDrawer();
    }else if (item.key === 'home') {
      history.push('/explore');
      closeDrawer();
    }else if(item.key === 'partner') {
      closeDrawer();
      history.push('/explore/Partners');
      closeDrawer();
    } else {
      closeDrawer();
      history.push(`/explore/apiDetails/${item.key}`);
    }
  }


  return (
    <Drawer
      css={MobileDrawerCss}
      title="" 
       placement="right"
       closable={false}
       onClose={closeDrawer}
       visible={visible}
       width={380}
    >
      <Menu
        mode="inline"
        openKeys={stateOpenKeys}
        onOpenChange={onOpenChange}
        onClick={OnClick}
        style={{
          width: 380,
          marginLeft: -30,
        }}
        items={items}
      />
    </Drawer>
  )  
};

MobileDrawer.propTypes = {
  agencyApiData: object,
  commercialApiData: object,
  visible: bool,
  closeDrawer: func,
};

export default MobileDrawer;