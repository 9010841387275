import React, { Fragment, useState, useRef, useEffect, useContext } from 'react';
import { object, func } from 'prop-types';
import { Card, Row, Col, List, Space, Typography, Divider, Avatar } from 'antd';
import { css } from '@emotion/core';
import { HashLink as Link } from 'react-router-hash-link';
import { useHistory } from 'react-router-dom';
import { getPublicSiteImageSrc } from 'Client/common/utils/AppUtils';
import { AppContext } from './../../Contexts/AppContext';

import './APIListCss.css';

const scrollWidthOffset = el => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -220;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

export const DropDownOverlayCss = css`
  width: 100%;
  margin: auto;
  top: 29px;
  box-shadow: 1px 1px 2px 2px lightgrey;

  .ant-card-body {
    padding: 0px 170px;
  }
    .active {
    color: #286CE2;
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    }

  .btn__browse_all-first {
    float: left;
    font-family: Lato;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    color: #333333;
    }
.btn__browse_all-last {
  float: right;
  font-family: Lato;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: #333333;
  }
.btn__browse_all {
  float: center;
  font-family: Lato;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: #333333;
}
  .newReleaseImg {
    width: 294px;
    height: 189px;
  }

  .divider__vertical {
    height: 100%;
    border: 1px solid lightgrey;
  }

  .divider__horizontal {
    border-top: 1px solid lightgrey;
    margin: 15px 0;
  }

  .drpTitle {
    font: normal normal bold 14px/24px Montserrat;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }

  .responsive-placeholder-img {
    width: 100%;
    height: auto;
    max-width: 100%;

    @media (min-width: 576px) {
      width: 75%;
    }

    @media (min-width: 768px) {
      width: 75%;
    }
  }

  @media (min-width: 1300px) {
    width: 100%;
    .img__api {
      width: 70%;
      margin: 20px 45px;
    }
    .h4__heading {
      font: normal normal 600 12px/20px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }
    .api__description_drp {
      font: normal normal normal 12px/18px Lato;
      letter-spacing: 0px;
      color: #666666;
      opacity: 1;
    }

    .api__title_section {
      padding-top: 12px;
    }
  }
  @media (min-width: 1600px) {
    width: 100%;
    .img__api {
      width: 70%;
    }
    .h4__heading {
      font: normal normal 600 14px/24px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }
    .api__description_drp {
      font: normal normal normal 16px/24px Lato;
      letter-spacing: 0px;
      color: #666666;
      opacity: 1;
    }

    .api__title_section {
      padding-top: 12px;
    }
  }
`;

const getImageSrc = (apiName) => {
  return require(`./../../common/img/api-services/${apiName}_service.svg`).default;
};

const AgencyListComponent = props => {
  const { agencyApiData, closeDropDownHandler } = props;
 
  return (
    <Fragment>
      <Card
        bordered={false}
        className="card__api"
        title={<span className="drpTitle">{agencyApiData?.moduleTitle}</span>}
      >
        <List
          grid={{
            column: 1,
          }}
          dataSource={agencyApiData?.ListAssoc || []}
          renderItem={api => (
            <List.Item>
              <Link
                to={`/explore/api-service/agency_api#${api?.name}`}
                scroll={el => scrollWidthOffset(el)}
                onClick={closeDropDownHandler}
              >
                <Space direction="horizontal">
                  <div>
                    <img
                      className="img__api"
                      src={getImageSrc(api?.name)}
                    />
                  </div>
                  <div className="api__title_section">
                    <Typography.Title className="h4__heading" level={4}>
                      {api?.heading}
                    </Typography.Title>
                    <Typography.Text level={4} className="api__description_drp">
                      {api?.title}
                    </Typography.Text>
                  </div>
                </Space>
              </Link>
            </List.Item>
          )}
        />
      </Card>
    </Fragment>
  );
};

AgencyListComponent.propTypes = {
  agencyApiData: object,
  closeDropDownHandler: func,
};

const CommercialListComponent = props => {
  const { commercialApiData, closeDropDownHandler } = props;

  return (
    <Fragment>
      <Card
        bordered={false}
        className="card__api"
        title={<span className="drpTitle">{commercialApiData?.moduleTitle}</span>}
      >
        <List
          grid={{
            column: 2,
          }}
          dataSource={commercialApiData?.ListAssoc || []}
          renderItem={api => (
            <List.Item>
              <Link
                to={`/explore/api-service/commercial_api#${api?.name}`}
                onClick={closeDropDownHandler}
                scroll={el => scrollWidthOffset(el)}
              >
                <Space direction="horizontal">
                  <span>
                    <img
                      className="img__api"
                      src={getImageSrc(api?.name)}
                    />
                  </span>
                  <span>
                    <Typography.Title className="h4__heading" level={4}>
                      {api?.heading}
                    </Typography.Title>
                    <Typography.Text level={4} className="api__description_drp">
                      {api?.title}
                    </Typography.Text>
                  </span>
                </Space>
              </Link>
            </List.Item>
          )}
        />
      </Card>
    </Fragment>
  );
};

CommercialListComponent.propTypes = {
  commercialApiData: object,
  navToDetailPage: func,
  closeDropDownHandler: func,
};

const DropdownOverlayComponent = props => {
  const history = useHistory();
  const { activeItem, onItemClick, agencyApiData, commercialApiData, navToOverviewPage, closeDropDownHandler } = props;

  const { wraSlotList } = useContext(AppContext);
  const mounted = useRef();
  const [state, setState] = useState({ data: {}, status: 'pending', error: null });
  const [bannerContent, setBannerContent] = useState({});

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      getOwcsContent();
    } 
  }, []);


  const getOwcsContent = () => {
    const banner_content = wraSlotList?.find(data => data?.name === 'banner_content_display') ?? [];

    setState({
      data: {
        banner_content,
      },
      status: wraSlotList ? 'success' : 'error',
    });

    setBannerContent(banner_content?.ListAssoc ? banner_content?.ListAssoc[0] : {});
  };

  const selectApi = item => {
    if (item) {
      closeDropDownHandler();
      history.push(`/explore/apiDetails/${item}`);
    }
  };
  const handleAPIItemClick = item => {
    onItemClick(item);
    setSelectedAPI(item);
  };
  
  const Services = {
    agency: [
      'Applications List and Details API',
      'Benefit Details API',
      'Book of Business API',
      'Broker News Feed API',
      'Commissions API',
      'Small Group Renewal Packet API',
      'Opportunity Management API',
      'Application Submission API',
      'Application Status Pull Notification',
      'Group Structure API',
      'Member Maintenance API',
      'Document Upload API',
      'Member Enrollment API',
      'Quote Management API',

    ],
    benefit: [
      'Book of Business API',
      'Benefit Details API',
      'Application Submission API',
      'Group Structure API',
      'Member Maintenance API',
      'Member Enrollment API',
      'Quote Management API',
    ],
    techConnect: [
      'Book of Business API',
      'Benefit Details API',
      'Small Group Renewal Packet API',
      'Application Submission API',
      'Group Structure API',
      'Member Maintenance API',
      'Member Enrollment API',
      'Quote Management API',
    ],
    management: [
      'Commissions API',
      'Book of Business API',
      'Applications List and Details API',
      'Opportunity Management API',
      'Benefit Details API',
      'Broker News Feed API',
      'Small Group Renewal Packet API',
    ],
    commercial: [
      'Application Submission API',
      'Application Status Pull Notification',
      'Group Structure API',
      'Member Maintenance API',
      'Member Enrollment API',
      'Quote Management API',
      'Document Upload API',
    ],
  };
  const [selectedAPI, setSelectedAPI] = useState('agency');
  const handleClick = key => {
    // if (selectedAPI === 1) setSelectedAPI(0);
    // else setSelectedAPI(1);
    setSelectedAPI(key);
  };
  return (
    <Fragment>
      <Card bordered={false} css={DropDownOverlayCss}>
        <Row gutter={24}>
          {/* <Col span={6}>
            <AgencyListComponent agencyApiData={agencyApiData} closeDropDownHandler={closeDropDownHandler} />
          </Col>

          <Col span={18} style={{ borderLeft: '1px solid' }}>
            <CommercialListComponent
              commercialApiData={commercialApiData}
              closeDropDownHandler={closeDropDownHandler}
            />
          </Col> */}
          <Col span={6} className="chooseAPIBox">
            <div className="choose-api">
              <span className='choose-api-by-partner'>
                Choose Api By Partner
              </span>
              <li onClick={() => handleAPIItemClick('agency')} className={activeItem === 'agency' ? 'active' : ''}>
                For Agencies
              </li>
              <li onClick={() => handleAPIItemClick('benefit')} className={activeItem === 'benefit' ? 'active' : ''}>
                Benefit Administrators
              </li>
              <li
                onClick={() => handleAPIItemClick('techConnect')}
                className={activeItem === 'techConnect' ? 'active' : ''}
              >
                Technology Partners
              </li>
            </div>
            <div className="choose-api">
              <span className='choose-api-by-partner'>
                API By Business Case
              </span>
              <li
                onClick={() => handleAPIItemClick('management')}
                className={activeItem === 'management' ? 'active' : ''}
              >
                Agency Management
              </li>
              <li
                onClick={() => handleAPIItemClick('commercial')}
                className={activeItem === 'commercial' ? 'active' : ''}
              >
                Commercial Group Submission & Member Management
              </li>
            </div>
          </Col>

          <Col span={12} style={{ borderLeft: '2px solid #99999933', paddingTop: 25, }}>
            <div className="col-2">
              
                <span className="apiServiceHeading">API SERVICES</span>
              
              <ul className='apiServiceBody'>
                {Services[selectedAPI].map(item => {
                  return (
                    <div className="partnerTypeAPIList" onClick={() => selectApi(item)}>
                      {item}
                    </div>
                  );
                })}
              </ul>
            </div>
          </Col>
          <Col span={6}>
         { bannerContent?.ListAssoc &&
            <div className="col-3 newReleaseSection">
              <Row justify="center" align="middle" gutter={[8, 16]}>
                <Col span={24}>
                  <div>
                    <span className="newReleaseHeading">New Release</span>
                  </div>
                </Col>
                <Col span={21}>
                  <div>
                    <img 
                      className="newReleaseImg" 
                      src={`/public/api/landing/fetch/${bannerContent?.ListAssoc && bannerContent?.ListAssoc[0]?.name}`}
                    />
                  </div>
                </Col>
                <Col span={20}>
                  <Row gutter={8} align="middle" className="brokerNewsHeading">
                    {/* <Col span={6} className="centered-col"> 
                      <img src={`/public/api/landing/fetch/${bannerContent?.ListAssoc[1]?.name}` }
                       className="responsive-placeholder-img"
                      />
                    </Col> */}
                    <Col span={24}>
                      <p style={{marginBottom:5 }}>
                        <b className="brokerNewsHeadTitle">{ bannerContent?.title }</b>
                      </p>
                    </Col>
                    <Col span={24}>
                      <div className="brokerNewsText">
                       { bannerContent?.description }
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          }
          </Col>
          {/* <ListOfApi></ListOfApi> */}
          <Col span={24}>
            <Divider className="divider__horizontal" plain type="horizontal" />
            <Row style={{ paddingBottom: 12 }}>
              <Col span={24}>
                <a className="btn__browse_all-last" onClick={navToOverviewPage}>
                  Show all APIs
                </a>
              </Col>
              {/* <Col span={5}>
                <a className="btn__browse_all" onClick={navToOverviewPage}>
                  Popular APIs
                </a>
              </Col>
              <Col span={5}>
                <a className="btn__browse_all" onClick={navToOverviewPage}>
                  Notification APIs
                </a>
              </Col>
              <Col span={5}>
                <a className="btn__browse_all" onClick={navToOverviewPage}>
                  Application APIs
                </a>
              </Col>
              <Col span={4}>
                <a className="btn__browse_all-last" onClick={navToOverviewPage}>
                  Enrollment APIs
                </a>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};

DropdownOverlayComponent.propTypes = {
  agencyApiData: object,
  commercialApiData: object,
  closeDropDownHandler: func,
  navToOverviewPage: func,
};

export default DropdownOverlayComponent;
