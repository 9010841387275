import React, { Fragment } from 'react';
import { css } from '@emotion/core';
import { Button } from 'antd';
import { func, node } from 'prop-types';

export const SecondaryButtonCss = css`
  font-size: 14px;
  font-family: Lato;
  font-weight: 400;
  color: #286ce2;
  height: 40px;
  border: 1px solid #286ce2;
  background-color: #fff;
  :hover {
    background-color: #2453a6;
  }
`;

export const SecondaryButton = props => {
  const { navigateNext, children, ...rest } = props;
  return (
    <Fragment>
      <Button css={SecondaryButtonCss} type="link" onClick={navigateNext} {...rest}>
        {children}
      </Button>
    </Fragment>
  );
};
SecondaryButton.propTypes = {
  navigateNext: func,
  children: node,
};
