import { css } from '@emotion/core';
import getstartedbg from './../../common/img/relatedapibg.svg';
import images_getStarted from './../../common/img/new-public-Ui/background_getstarted.svg';
import headerBackground from './../../common/img/new-public-Ui/header_background.svg';
export const ApiDetailCss = css`
  
  .centerText {
    text-align: center;
  }

  .apiName {
    display: block;
  }

  .arrow {
    height: 50px;
    width: 120px;
    margin: 0px 22px;
  }


  .images {
    display: flex;
    justify-content: space-evenly;
    margin: auto;
  }

  .backgroundImage {
    background: url(${getstartedbg});
  }

  .h3-heading {
    color: #1A3773;
    font-weight: 600;
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0.1em;
    text-align: left;
  }

  .overviewSection {
    background-color: #f2f2f2;
    padding: 5% 20%;
  }

  .overViewContainer {
  margin-top: 20px;
  }

  .overviewSectionOutcome {
   background-color: #f2f2f2;
    padding: 5% 10%;
  }

  ul {
    list-style-type: disc;
    display: grid;
    grid-template-columns: auto;
}

  .divide {
    height: 20px;
  }

  .cardHeading {
    font-family: Elevance Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 27.36px;
    text-align: left;
  }
  .cardBody {
    font-family: Elevance Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.04752964526414871px;
    text-align: left;
    color: #333333;
  }

  .prereqCard {
    width : 100%
  }

  .overviewCard {
   width : 100%
  }

  .outcomes {
    padding: 20px 0 20px 20px;
    border-top: 5px solid #008080;
    background-color: #f0fbf9;
  }

  .tick_icon {
    margin-right: 16px;
  }

  .outcome-icon {
    display: inline;
  }

  .inline {
    display: inline;
  }

  .outcomeRow {
    margin-bottom: 25px;
  }

  .list-items {
    font-weight: bold;
    font-size: 18px;
    color: #696969;
  }

  .whysection {
    margin: 50px 10%;
  }

  .whyheading {
    color: #02bbbb;
  }

  .whydescription {
    font-weight: 800;
    font-size: 26px;
  }
  
  .apiFlowImage {
    width: 100%;
    padding: 30px 0;
  }

  .apiHeading {
    font-weight: 900;
    font-size: 15px;
    margin-top: 20px;
  }

  .container {
    position: relative;
    width: 100%;
  }

  .image {
    display: block;
    height: auto;
    margin: 21% auto;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
  }
  .api_cards {
    width: inherit;
  }

  .card__api_list:hover .overlay {
    opacity: 1;
  }
  .card__api_list:hover .api_cards {
    opacity: 0.5;
    visibility: hidden;
  }
  .card__api_list:hover {
    background-color: #E3ECFD;
  }
  .api_title {
    font-weight: bold;
  }
.card__api_list {
     width: 240px
    height: 180px;
  }
  .text {
    color: black;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }
    .card__api_list {
    text-align: center;
    box-shadow: 4px 4px 8px #0000000f;
    border: 1px solid #e0e0e0;
    border-radius: 0px;
    opacity: 1;
    position: relative;
    margin: auto;
  }
    .relatedAPICss {
    background: #f2f2f2;
    padding: 100px 350px;
    }
    .moreButton {
    color: #1354e9;
    font-wight: bold;
    }

  .mobile-only {
    display: none;
   }
    .hide-on-mobile {
  display: block;
}
  @media(max-width: 1024px) {
     .relatedAPICss {
       padding: 50px;
      }
     .arrow {
       width: 42px;
      }
       .overviewSection {
    padding: 5% 15%;
  }
    }

    @media(max-width: 820px) {
     .image {
       margin: 5px auto;
      }
       .overviewSection {
    padding: 5% 10%;
  }
    }

   @media (max-width: 768px) {
    .mobile-only {
      display: block;
    }
      .hide-on-mobile {
    display: none;
  }
    .relatedAPICss {
      padding: 10px 10px;
    }
.overviewSection {
    padding: 5% 10%;
  }
    .apiFlowImage {
    padding: 30px 110px;
  }
  .image {
    display: block;
    height: auto;
    margin: 5% auto;
    height: 70px;
    width: 70px;
  }
    .text {
    font-size: 12px;
  }
     .arrow {
    height: 50px;
    margin: 100px 0px;
    width: 60px;
  }
      .arrow-down {
   transform: rotate(90deg);
    margin: 10px;
  }
  .arrow-left {
    transform: rotate(180deg);    
}
    .whysection {
    margin: 40px 10%;
  }
    .whydescription {
    font-weight: 800;
    font-size: 20px;
  }
  @media(max-width: 480px) {
    .image {
      margin: 5% auto;
    }
      .overviewSection {
    padding: 5%;
  }
    .apiFlowImage {
    padding: 30px 0;
  }
  }
`;
export const apiPartnerGetStarted = css`
  .backgroundImage {
    background: url(${images_getStarted});
    background-repeat: no-repeat;

    background-size: 100% 100%;

    float: left;

    width: 100%;
    height: 453px;
    font-size: 20px;
  }
  .welcome_banner_content {
    margin: 5% 40% 5% 15%;
  }

  .second_headline {
    color: white;
    font-weight: bold;
    font-size: 35px;
  }

  .first_headline {
    color: white;
    font-weight: 600;
    font-size: 16px;
  }

  .tell_us_button {
    height: 40px;
    border: 1px solid #286ce2;
    display: block;
    background-color: #1354e9;
    border-radius: 20px;
    color: white;
  }
  .get-started {
    height: 40px;
    border: 1px solid #286ce2;
    display: block;
    background-color: #fff;
    border-radius: 20px;
    color: #1354e9;
    width: 200px;
  }
`;

export const ApiSearchCss = css`
  .apisearch {
     background: #1A3773 url(${headerBackground}) right no-repeat;
  background-position: left 130% center;

    padding: 1%;
    height: 100%;
  }
  .welcome_banner_content {
    margin: 3% 10% 3% 10%;
  }

  .second_headline {
    color: white;
    font-family: Elevance Sans;
    font-size: 48px;
    font-weight: 600;
    line-height: 60px;
    margin-top: 10px;
  }

  .first_headline {
    color: white;
    font-weight: 400;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    background-color: transparent;
    border: none;
    margin-left: -22px;
  }
  .first_headline_text {
    color: white;
    font-weight: 600;
    font-size: 16px;
    margin-left: 10px;
    margin-top: -5px;
  }

`;
