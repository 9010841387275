
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';


import ErrorImg from '../../common/img/error_modal_icon.svg';
import './ErrorModal.css';

export function showError({ backToHome, err }) {
  const path = window.location.pathname;
  let modalStyle = {};

  if(path.includes('register')) {
    modalStyle = {
      top: '30%',
      right: '8%'
    }
  } else if(path.includes('api-service')) {
    modalStyle = {
      right: '8%'
    }
  }

  const modal = Modal.error({
      title: (
        <div className="modal-title">
          <img src={ErrorImg} alt="Error Icon" style={{ margin: 'auto' }} />
        </div>
      ),
      content: (
        <div className="modal-content-text">
          Something went wrong. Please try again later.
        </div>
      ),
      onOk() {},
      icon: (<CloseOutlined onClick={() => {
          modal.destroy();
          backToHome();
        }}
      />
      ),
      style: modalStyle,
      centered: path.includes('register') ? false : true
    })
}

