import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Card, Row, Col, Radio, Avatar, Space } from 'antd';

import { getPublicSiteImageSrc } from '../../common/utils/AppUtils'


const CardsOne = ({ isBroker = '', partnerType = '', handleRadioSelection }) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1366);
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1366);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
      <>
        <div>
          <Fragment>
            <Typography.Paragraph className="cards-heading">
              <Space>
                <Avatar style={{ backgroundColor: '#074079' }} size={{ xs: 32, sm: 32, md: 36, lg: 36, xl: 36, xxl: 36}}>1</Avatar> 
                <span className="avatar-text">Are you an Anthem-appointed broker ?</span>
              </Space>
            </Typography.Paragraph>
          </Fragment>
        </div>
        <Row gutter={{sm: 16, md: 24}}>
          <Col span={8}>
            <Radio.Group name="isBroker" onChange={handleRadioSelection} value={isBroker}>
              <Space size="large">
                <Row className="qna-radiobutton qna-big-card" type="flex" justify="center" align="middle">
                  <Col xs={{offset: 4, span: 12}} sm={{offset: 4, span: 12}} md={{offset: 8, span: 12}}>
                    <img
                        src={getPublicSiteImageSrc('broker.svg')}
                        alt="Avatar"
                        className="qna_image"
                      />
                  </Col>
                  <Col offset={4} span={20}>
                    <Radio value={true}>
                        <span className='qna-value'>Yes</span>
                    </Radio>
                  </Col>
                </Row>
                <Row className="qna-radiobutton qna-big-card" type="flex" justify="center" align="middle">
                  <Col xs={{offset: 4, span: 12}} sm={{offset: 4, span: 12}} md={{offset: 8, span: 12}}>
                    <img
                        src={getPublicSiteImageSrc('no_broker.svg')}
                        alt="Avatar"
                        className="qna_image"
                      />
                  </Col>
                  <Col offset={4} span={20}>
                    <Radio value={false}>
                        <span className='qna-value'>No</span>
                    </Radio>
                  </Col>
                </Row>
              </Space>
            </Radio.Group>
          </Col>
        </Row>

        { isBroker === false && (
          <div className='secondary-qna'>
            <div className="ant-result-title">
              <Typography.Paragraph className="cards-heading-secondary">Choose Partner Type</Typography.Paragraph>
            </div>
            <Radio.Group name="partnerType" onChange={handleRadioSelection} value={partnerType}>
              <Row className="partnerType-card-row" gutter={[{sm: 16, md: 24}, 24]}>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <Card
                    className="partnerType-card"
                  >
                    <Row alignItems="center" gutter={[{ sm: 0, md: 16}, { xs: 8, sm: 16, md: 24 }]}>
                      <Col span={12}>
                        <Radio
                          className="partnerType_heading"
                          value="broker"
                        >
                          Agency
                        </Radio>
                      </Col>
                      <Col md={{ span: 6, offset: 6 }}  sm={{ span: 4, offset: 8 }}>
                        <img
                          src={getPublicSiteImageSrc("agency.svg")}
                          alt="Avatar"
                          className="image-partnerType"
                        />
                      </Col>
                      <Col span={24}>
                        <Typography.Paragraph className="partnerType-desc" ellipsis={ isMobile ? { rows: 3, expandable: false } : false}>
                          The agency profile is largely for brokerages, appointed brokers, or any entity that directly
                          quotes, sells and services Anthem individual or group plans without the using the services or
                          technology of a third party benefit administrator.
                        </Typography.Paragraph>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <Card
                    className="partnerType-card"
                  >
                    <Row alignItems="center" gutter={[16, { sm: 16, md: 24 }]}>
                      <Col span={12}>
                        <Radio
                          className="partnerType_heading"
                          value="benadmin"
                        >
                          Benefit Administrator
                        </Radio>
                      </Col>
                      <Col md={{ span: 6, offset: 6 }}  sm={{ span: 4, offset: 8 }}>
                        <img
                          src={getPublicSiteImageSrc("benfit.svg")}
                          alt="Avatar"
                          className="image-partnerType"
                        />
                      </Col>
                      <Col span={24}>
                        <Typography.Paragraph className="partnerType-desc" ellipsis={ isMobile ? { rows: 3, expandable: false } : false}>
                        The benefit administrator profile is for firms or companies that provide overall administrative
                        support for broker clients through their technology platform. Allows brokers to connect directly
                        to carriers for quoting, eligibility, enrollment and other client management functions.
                        </Typography.Paragraph>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <Card
                    className="partnerType-card"
                  >
                    <Row alignItems="center" gutter={[16, { sm: 16, md: 24 }]}>
                      <Col span={12}>
                        <Radio
                          className="partnerType_heading"
                          value="techplatform"
                        >
                          Technology Partner
                        </Radio>
                      </Col>
                      <Col md={{ span: 6, offset: 6 }}  sm={{ span: 4, offset: 8 }}>
                        <img
                            src={getPublicSiteImageSrc("techplatform.svg")}
                        alt="Avatar"
                        className="image-partnerType"
                        />
                      </Col>
                      <Col span={24}>
                       <Typography.Paragraph className="partnerType-desc" ellipsis={ isMobile ? { rows: 3, expandable: false } : false}>
                          The technology partner profile applies to companies that create the direct-to-carrier connection
                          technology used by benefit administrators and other vendors.
                        </Typography.Paragraph>
                      </Col>
                    </Row>
                  </Card>
                </Col>                
              </Row>
            </Radio.Group>
          </div>
        )}
    </>
  );
};

export default CardsOne;
