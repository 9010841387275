import { css } from '@emotion/core';
export const ApiDetailCSS = css`
  .ant-card-body {
     @media (max-width: 992px) {
      padding: 16px;
    }

    @media (max-width: 768px) {
      padding: 12px;
    }
  }

  .api-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .api-img-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .subscription-col {
    text-align: right;
  }

  .app-row {
    @media (max-width: 768px) {
      padding: .625rem;
    }
  }

  @media (max-width: 768px) {

    .api-img-col {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .api-row {
      flex-direction: column;
      align-items: flex-start;
    }

    .subscription-col {
      order: -1;
      align-self: flex-end;
      width: 100%;
      text-align: right;
      margin-right: 3.125rem;
    }

    .apiTitle {
      width: 100%;
      text-align: left;
    }

    .subscription-switch {
      padding: 0;
    }

  }


  .api__content div {
    display: block;
  }
  
  .card__api {
    text-align: center;
    background: inherit;
  }
  .section__api_container {
    height: 90vh;
    width: 90%;
    left: 2%;
    top: 3%;
    margin: auto;
    position: relative;
  }

  .apiTitle {
    font-family: Elevance Sans;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.625rem;
    color: #333333;

    @media (max-width: 992px) {
      font-size: 1.25rem;
    }
  }

  .apiDescription{
    font-family: Elevance Sans;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.14rem;
    color: #333333;

    @media (max-width: 992px) {
      font-size: 1rem;
    }
  }

  .card__api_col2 {
    background: inherit;
  }

  .image__grid_item {
    width: 90%;
  }

  @media (min-width: 320px) {
    .section__api_container {
      height: auto;
    }
    .api__heading {
      font-size: 18px;
      white-space: normal;
    }

    .api__title {
      font-size: 14px;
    }
    .image__grid_item {
      width: 50%;
    }
  }
  @media (min-width: 480px) {
    .section__api_container {
      height: auto;
    }
    .api__heading {
      font-size: 18px;
      white-space: normal;
    }

    .api__title {
      font-size: 14px;
    }

    .image__grid_item {
      width: 75%;
    }
  }
  @media (min-width: 600px) {
    .section__api_container {
      height: auto;
    }
    .api__heading {
      font-size: 18px;
      white-space: normal;
    }

    .api__title {
      font-size: inherit;
    }

    .image__grid_item {
      width: 45%;
    }
  }
  @media (min-width: 801px) {
    .section__api_container {
      height: auto;
    }
    .api__heading {
      font: normal normal 600 40px/54px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
      white-space: normal;
    }

    .api__title {
      font: normal normal bold 26px/28px Lato;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }
    .image__grid_item {
      width: 90%;
    }

    .api__description {
      font: normal normal normal 18px/28px Lato;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }

    .image__grid_item {
      width: 90%;
    }
  }
  @media (min-width: 1025px) {
    .section__api_container {
      height: 70vh;
    }
    .api__heading {
      font: normal normal 600 26px/38px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
      white-space: normal;
    }

    .api__title {
      font: normal normal bold 18px/24px Lato;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }
    .image__grid_item {
      width: 90%;
    }

    .api__description {
      font: normal normal normal 14px/24px Lato;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }

    .image__grid_item {
      width: 90%;
    }
  }
  @media (min-width: 1400px) {
    .section__api_container {
      height: 90vh;
    }
    .api__heading {
      font: normal normal 600 48px/64px Montserrat;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
      white-space: normal;
    }

    .api__title {
      font: normal normal bold 32px/34px Lato;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }
    .image__grid_item {
      width: 90%;
    }
    .api__description {
      font: normal normal normal 22px/32px Lato;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }
  }
`;

export const SideBarCss = css`
  .menu__link {
    font: normal normal normal 14px/38px Lato;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }

  .menu__item {
    border-bottom: 1px solid #e8e8e8;
    height: 60px;
    display: grid;
    white-space: normal;
  }
`;

export const ApiLayoutCss = css`
  overflow-y: hidden;

  @media (min-width: 320px) {
    &.site__layout {
      padding: 0 0;
      margin-left: 0;
      margin-top: 30px;
    }
  }
  @media (min-width: 480px) {
    &.site__layout {
      padding: 0 0;
      margin-left: 0;
      margin-top: 30px;
    }
  }
  @media (min-width: 600px) {
    &.site__layout {
      padding: 0 0;
      margin-left: 0;
      margin-top: 30px;
    }
  }
  @media (min-width: 801px) {
    &.site__layout {
      padding: 24px 0;
      margin-left: 300px;
      margin-top: 70px;
    }
  }
  @media (min-width: 1025px) {
    &.site__layout {
      padding: 24px 0;
      margin-left: 300px;
      margin-top: 70px;
    }
  }
  @media (min-width: 1281px) {
    &.site__layout {
      padding: 24px 0;
      margin-left: 300px;
      margin-top: 70px;
    }
  }
`;

export const BreadCrumbHeaderCss = css`
  &.section__header {
    position: fixed;
    width: 100%;
    background: #286ce2 0% 0% no-repeat padding-box;
    z-index: 2;
    padding: 12px 24px;
  }

  .span__nav_current_page {
    font-size: x-large;
    color: white;
  }
  .span__item,
  .ant-breadcrumb-separator {
    color: white;
  }

  @media (min-width: 320px) {
    .span__nav_current_page {
      font-size: 14px;
    }
  }
  @media (min-width: 480px) {
    .span__nav_current_page {
      font-size: 14px;
    }
  }
  @media (min-width: 600px) {
    .span__nav_current_page {
      font-size: 14px;
    }
  }
  @media (min-width: 801px) {
    .span__nav_current_page {
      font-size: 14px;
    }
  }
  @media (min-width: 1025px) {
    .span__nav_current_page {
      font-size: 22px;
    }
  }
  @media (min-width: 1281px) {
    .span__nav_current_page {
      font-size: 22px;
    }
  }
`;
