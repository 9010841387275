import { css } from '@emotion/core';
export const LegalEntityCss = css`
  .formHeading {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 30px;
    font-family: Elevance Sans;
  }

  .formItem {
    font-family: Elevance Sans;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.14rem;
    color: #333333;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    font-family: Elevance Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.14rem;
    color: #333333;
  }

  .input {
    border: none;
    outline: none;
    padding-left: 20px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin-bottom: 15px;
  }

  .ant-select-selector {
    border: none;
    outline: none;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: rgba(60,64,67,0.3) 0px 1px 2px 0px,rgba(60,64,67,0.15) 0px 2px 6px 2px;
    margin-bottom: 15px;
    max-height: 50px;
  }

  .companyDetails {
    @media (max-width: 600px) {
      padding: 30px;
    }
  }

  .contactPerson {
    height: fit-content;
  }

  .input-companydetails {
    margin-bottom: 0px;
  }

  .or {
    font-family: Elevance Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 15.96px;
    text-align: center;
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 600px) {
      margin: auto;
    }
  }

  .space-btw-note {
    margin-bottom: 50px;
  }

  .separator-hr {
    width: 100%;
    margin-bottom: 20px;
    border-top: 0.5px solid #e6e6e6;
  }

  .button-container {
    margin-top: 10px;
  }

  .form-primary-button {
    border: none;
    background-color: #286ce1;
    color: white;
  }

  .form-secondary-button {
    border: 1px solid #286ce2;
    color: #286ce2;
    background-color: #fff;
  }

  .button-container button {
    width: 200px;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 100px;
  }

  .stateSelection {
    font-weight: 400;
    font-family: Elevance Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.24px;
    text-align: left;
    color: #666666;
  }

  .stateSelection .ant-select-selector {
    display: flex;
    align-items: center; /* Center items vertically */
    height: 100%; /* Ensure the height is 100% */
  }

  .stateSelection .ant-select-arrow {
    top: 40%;
  }

  .stateSelection .ant-select-selection-search {
    top: 15px;
  }

  .ant-select-selection-search {
    padding-left: 14px;
  }

  .stateSelection .ant-select-selection-item {
    display: flex;
    align-items: center; /* Center items vertically */
    height: 100%; /* Ensure the height is 100% */
  }
  .placeholderText {
    color: #d2d2d2;
    font-family: Elevance Sans;
    font-size: 16px;
    font-weight: 400;
    height: 50px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
    border: none;
    padding-left: 20px;
  }

  .ant-input {
    font-family: Elevance Sans;
    font-size: 16px;
    font-weight: 400;
    height: 50px;
  }
`;
