import React, { useEffect, useState } from 'react';
import { Typography, Card, Row, Col, Radio, Space } from 'antd';
import { css } from '@emotion/core';
import { LeftOutlined } from '@ant-design/icons';
import { AppContext } from './../../Contexts/AppContext';
import Button from 'Client/components/Buttons/Button';
import { useHistory } from 'react-router-dom';
import { footerSection, greetingCardHeader, QuestionCardCss } from '../RecommendedApiList/QuestionCard.style';
import { getPublicSiteImageSrc } from 'Client/common/utils/AppUtils';
import { cssContent } from './Confirmation';


const PartnerTypeSelection = ({ handleRadioSelection, partnerType, handleNext }) => {
 
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1366);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1366);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const history = useHistory();


  const backToHome = value => {
    history.push(value);
  };

  return (
    <div>
      <Card css={greetingCardHeader} bordered={false}>
        <div className="backToHome">
          <a onClick={() => backToHome('/explore')} style={{ color: '#1354E9' }}>
          <Space>
            <LeftOutlined />
            <span>Back to Home</span>
          </Space>
          </a>
        </div>
      </Card>
      <section css={QuestionCardCss}>
        <div className='partnerType_selection'>
          <div className="ant-result-title">
            <Typography.Paragraph className="cards-heading">Choose Partner Type</Typography.Paragraph>
          </div>
          <Radio.Group name="partnerType" onChange={handleRadioSelection} value={partnerType}>
            <Row gutter={[{sm: 16, md: 24}, 24]} className="qna-group">
              <Col xs={24} sm={24} md={20} lg={8}>
                <Card
                  className="partnerType-card"
                >
                  <Row className="align-center" gutter={[{ sm: 0, md: 16}, { xs: 8, sm: 16, md: 24 }]}>
                    <Col span={12}>
                      <Radio
                        className="partnerType_heading"
                        value="broker"
                      >
                        Agency
                      </Radio>
                    </Col>
                    <Col md={{ span: 6, offset: 6 }}  sm={{ span: 4, offset: 8 }}>
                      <img
                        src={getPublicSiteImageSrc("agency.svg")}
                        alt="Avatar"
                        className="image-partnerType"
                      />
                    </Col>
                    <Col span={24}>
                      <Typography.Paragraph className="partnerType-desc" ellipsis={ isMobile ? { rows: 3, expandable: false } : false}>
                        The agency profile is largely for brokerages, appointed brokers, or any entity that directly
                        quotes, sells and services Anthem individual or group plans without the using the services or
                        technology of a third party benefit administrator.
                      </Typography.Paragraph>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col  xs={24} sm={24} md={20} lg={8}>
                <Card
                  className="partnerType-card"
                >
                  <Row className="align-center"  gutter={[{ sm: 0, md: 16}, { xs: 8, sm: 16, md: 24 }]}>
                    <Col span={12}>
                      <Radio
                        className="partnerType_heading"
                        value="benadmin"
                      >
                        Benefit Administrator
                      </Radio>
                    </Col>
                    <Col  md={{ span: 6, offset: 6 }}  sm={{ span: 4, offset: 8 }}>
                      <img
                        src={getPublicSiteImageSrc("benfit.svg")}
                        alt="Avatar"
                        className="image-partnerType"
                      />
                    </Col>
                    <Col span={24}>
                      <Typography.Paragraph className="partnerType-desc" ellipsis={ isMobile ? { rows: 3, expandable: false } : false}>
                      The benefit administrator profile is for firms or companies that provide overall administrative
                      support for broker clients through their technology platform. Allows brokers to connect directly
                      to carriers for quoting, eligibility, enrollment and other client management functions.
                      </Typography.Paragraph>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col  xs={24} sm={24} md={20} lg={8}>
                <Card
                  className="partnerType-card"
                >
                  <Row className="align-center"  gutter={[{ sm: 0, md: 16}, { xs: 8, sm: 16, md: 24 }]}>
                    <Col span={12}>
                      <Radio
                        className="partnerType_heading"
                        value="techplatform"
                      >
                        Technology Partner
                      </Radio>
                    </Col>
                    <Col md={{ span: 6, offset: 6 }}  sm={{ span: 4, offset: 8 }}>
                      <img
                          src={getPublicSiteImageSrc("techplatform.svg")}
                      alt="Avatar"
                      className="image-partnerType"
                      />
                    </Col>
                    <Col span={24}>
                      <Typography.Paragraph className="partnerType-desc" ellipsis={ isMobile ? { rows: 3, expandable: false } : false}>
                        The technology partner profile applies to companies that create the direct-to-carrier connection
                        technology used by benefit administrators and other vendors.
                      </Typography.Paragraph>
                    </Col>
                  </Row>
                </Card>
              </Col>                
            </Row>
          </Radio.Group>
        </div>
        <div css={footerSection}>
          <Card bordered={false} className="nextButton-card">
              <Row gutter={16} justify="end">
                <Col>
                  <Button className="nextButton" onClick={handleNext}>
                    Next
                  </Button>
                </Col>
              </Row>
          </Card>
        </div>
      </section>
    </div>
  );
};

export default PartnerTypeSelection;
