import React, { Fragment, useEffect, useState } from 'react';
import { Typography, Space, Row, Col, Radio, Avatar, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { getPublicSiteImageSrc } from 'Client/common/utils/AppUtils';



const CardsThree = ({ anthemAppointedBroker, handleRadioSelection, handleContactPersonInput }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <div className="ant-result-title">
        <Fragment>
          <Typography.Paragraph className="cards-heading">
            <Space>
              <Avatar style={{ backgroundColor: '#074079', size: 'large' }} size={{ xs: 32, sm: 32, md: 36, lg: 36, xl: 36, xxl: 36}}>3</Avatar> 
              <span className="avatar-text">Do you use a third-party administrator, or do you want to connect directly to Anthem?</span>
            </Space>
          </Typography.Paragraph>
        </Fragment>
      </div>
      <Row gutter={{sm: 16, md: 24}}>
          <Col span={24}>
            <Radio.Group name="anthemAppointedBroker" onChange={handleRadioSelection} value={anthemAppointedBroker} style={{width: '100%'}}>
                <Space size="large">
                  <Row gutter={[{xs:8, md:16},16]} className="qna-radiobutton qna-big-card" type="flex" justify="space-around" align="middle">
                    <Col offset={4} span={12} className="qna_image_container">
                      <img
                          src={getPublicSiteImageSrc('third_party_admin.svg')}
                          alt="Avatar"
                          className="qna_image"
                        />
                    </Col>
                    <Col xs={{span: 24}} md={{offset: 4, span: 20}}>
                      <Radio value={true}>
                          <span className='qna-value'>Third-party administrator</span>
                      </Radio>
                    </Col>
                  </Row>
                  <Col md={12} lg={6}>
                    <Row gutter={[8,8]} className="qna-radiobutton qna-big-card" type="flex" justify="space-around" align="middle">
                      <Col gutter={[{xs:8, md:16},16]} className="qna_image_container">
                        <img
                            src={getPublicSiteImageSrc('not_third_party_admin.svg')}
                            alt="Avatar"
                            className="qna_image"
                          />
                      </Col>
                      <Col offset={4} span={20}>
                        <Radio value={false}>
                            <span className='qna-value'>Connect directly to Anthem</span>
                        </Radio>
                      </Col>
                    </Row>
                 </Col>
              </Space>
            </Radio.Group>
          </Col>
      </Row>
      {
        anthemAppointedBroker === false && (
          <Row>
            <Col span={24}>
              <div className='thirdparty-admin-subtitle'>
                <p>
                  <span>Anthem Contact person name</span> <span>(Optional)</span>
                </p>
                <Input placeholder="Enter your Anthem contact person name"  onChange={handleContactPersonInput} />
              </div>
            </Col>
          </Row>
        )
      }
    </>
  );
};

export default CardsThree;
