import React from 'react';
import { Card, Button, Space, Row, Col } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { getPublicSiteImageSrc } from 'Client/common/utils/AppUtils';

const PreSelectApiList = ({ title, imageSrc, apis, onRequestAccess }) => (
  <Card
    className="preselectapi-card"
    hoverable
    cover={
      // <>
      //   <p className="tech-platform-images">{title}</p>
      //   <img alt="example" src={imageSrc} />
      // </>
      <div className='partner-cover' style={{ backgroundImage: `url(${imageSrc})`}}>
        <span className="partner-cover-text">{title}</span>
      </div>
    }
  >
    <Row className='requestapilist'>
      {apis.map((api, index) => (
        <Col span={24} key={index}>
          <Space size="middle">
            <img 
              src={getPublicSiteImageSrc('check_circle.svg')}
            />
            <span className="requestapi-title">
              {api}
            </span>
          </Space>
        </Col>
      ))}
    </Row>
    <div className="request_btn_container">
      <Button className="request_btn" onClick={onRequestAccess}>
        Request Access
      </Button>
    </div>
  </Card>
);

export default PreSelectApiList;
