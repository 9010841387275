import React, { Fragment, useEffect } from 'react';
import { Typography, Card, Divider, Row, Col } from 'antd';
import { css } from '@emotion/core';
import ErrorImg from './../../common/img/new-public-Ui/Error.svg';


export const cssContent = css`
  background-size: 100% 200px;
  padding: 120px;
  margin-bottom: 4em;
  @media (max-width: 1024px) {
    padding: 0 10px;
  }
`;

const thankyouCss = css`
  .ant-result-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 23px;
    line-height: 1.8;
    font-family: Elevance Sans;
    font-weight: 600;
  }

  .ant-result {
    padding: 32px;
  }

  .ant-result-extra {
    text-align: left;
    margin-top: 0;
    font-family: Elevance Sans;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0px;
  }

  .error-text-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .error-title {
    font-family: Elevance Sans;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0;
  }

  .ant-result-info {
    background: #FDF7F7;
  }

  .ant-result-info .ant-result-icon > .anticon {
    color: #286ce2 !important;
  }

  .ant-result-icon > .anticon {
    font-size: 65px;
  }

  .ant-divider {
    border: none;
  }

  .ant-typography + h1.ant-typography,
  .ant-typography + h2.ant-typography,
  .ant-typography + h3.ant-typography,
  .ant-typography + h4.ant-typography {
    margin-top: none !important;
  }

  h1.ant-typography,
  .ant-typography h1 {
    margin-bottom: -0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
    font-size: 20px;
    line-height: 1.23;
    font-family: Elevance Sans;
  }

  #resend-email-btn {
    border-radius: 10px;
  }

  .error-container {
    display: flex;
    gap: 20px;
  }

  @media (max-width: 768px) {
    .ant-result-icon span img {
      height: 65px;
    }

    h1.ant-typography,
    .ant-typography h1 {
      font-size: 16px;
    }
    .ant-result-extra .ant-typography {
      font-size: 12px;
    }
  }
`;

const ErrorPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <div css={cssContent}>
        <Card bordered={false} css={thankyouCss}>
          <div className="ant-result ant-result-info">
            {/* <div className="ant-result-icon">
              <span role="img" aria-label="check-circle" className="anticon anticon-check-circle">
                <img src={ErrorImg}></img>
              </span>
            </div> */}
            <div className="error-container">
              <div className="ant-result-icon">
                <img src={ErrorImg}></img>
              </div>
              <div className="error-text-container">
                <div className="error-title">
                  <Fragment>
                    <Typography.Text>Error</Typography.Text>
                  </Fragment>
                </div>
                <div className="ant-result-extra">
                  <Fragment>
                    <Row>
                      <Col md={24}>
                        It looks like our application is experiencing some difficulty verifying your email address at this
                        time. <br></br>Please check back in few minutes or intact Technical support via mail at &nbsp;
                        <a href="mailto:Partner_Support@anthem.com?subject = Feedback&body = Message">
                          Partner_Support@anthem.com
                        </a>
                      </Col>
                    </Row>
                  </Fragment>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

export default ErrorPage;
