import React, { Fragment, useContext, useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Card, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import * as _ from 'lodash';
import { ApiDetailCss, ApiSearchCss } from '../ApiService/ApiDetails.style';
import { AppContext } from './../../Contexts/AppContext';

const ApiDetails = () => {
  let { apiName } = useParams();
  const [state, setState] = useState({ data: {}, status: 'pending', error: null });
  const { wraSlotList, clientConfig } = useContext(AppContext);
  const getRelatedApisCalled = useRef(false);
  getRelatedApisCalled.current = false;
  useEffect(() => {
    window.scrollTo(0, 0);
    getOwcsContent();
  }, []);
  
  const selectApi = api => {
    apiName = api;
    if (api) {
      window.scrollTo(0, 0);
      history.push(`/explore/apiDetails/${api}`);
    }
  };

  const getOwcsContent = () => {
    const partner_all_api_list = wraSlotList?.find(data => data?.name === 'partner_all_api_list') ?? [];

    setState({
      data: {
        partner_all_api_list,
      },
      status: wraSlotList ? 'success' : 'error',
    });
  };

  useEffect(() => {
    if (state?.data?.partner_all_api_list?.ListAssoc.length > 0 && !getRelatedApisCalled.current) {
      getRelatedApis();
      getRelatedApisCalled.current = true;
    }
  }, [state.data.partner_all_api_list, apiName]);
  
  const getRelatedApis = () => {
    const relatedApis = clientConfig?.relatedApiList || [];
    const snakeCaseApiName =  _.snakeCase(apiName);
    const relatedApisList = relatedApis[snakeCaseApiName] || "";
    const apiList = state?.data?.partner_all_api_list?.ListAssoc || [];
    const relatedApi = relatedApisList ? relatedApisList.map(api => {
      const apiDetail = _.find(apiList, (ap) => { return ap.name == api });
      return {
        name: api,
        description: apiDetail?.description,
        title: apiDetail?.title
      }
    }) : [] ;
    
    setState((prevState) => ({
      data: {
        ...prevState?.data,
        relatedApis: ! _.isEmpty(relatedApi) ? relatedApi : [],
      }
    }));
    
  }

  const history = useHistory();

  const backToHome = () => {
    history.goBack();
  };

  const apiList = Array.isArray(state.data.partner_all_api_list?.ListAssoc)
    ? state.data.partner_all_api_list?.ListAssoc
    : [];
  const snakeCaseApiName =  _.snakeCase(apiName);
  const filteredApi = apiList.find(api => api?.title === apiName);
  const outcome = filteredApi?.ListAssoc?.find(api => api?.name === 'outcomes');
  const apiFlowImage = filteredApi?.ListAssoc?.find(api => api?.name === snakeCaseApiName + `_flow`);
  const apiFlowImageMobile = filteredApi?.ListAssoc?.find(api => api?.name === snakeCaseApiName + `_flow_mobile`);
  const overview = filteredApi?.ListAssoc?.find(api => api?.name === 'Overview');
  const prerequisite = filteredApi?.ListAssoc?.find(api => api?.name === 'prerequisite');

  return (
    <div css={ApiDetailCss} className="apiDetails">
      <section id="ApiSearchCss" css={ApiSearchCss}>
        <div className="apisearch">
          <div className="welcome_banner_content">
            <Button className="first_headline" onClick={() => backToHome()}>
              {' '}
              <LeftOutlined />
              <div className='first_headline_text'>
              Back
              </div>
              
            </Button>
            <h2 className="second_headline">{apiName}</h2>
          </div>
        </div>
      </section>

        {outcome ? (
          <div className="overviewSectionOutcome">
          <Row className="overview">
            <h3 className="h3-heading">WHY SHOULD YOU USE OUR APIs?</h3>
          </Row>
          <Row className='overViewContainer'>
          <Col sm={24} md={24} lg={14} xl={14}>
            <Row>
              <Card className="card overviewCard">
                <p className="cardHeading">Overview</p>
                {overview && (
                  <p className='cardBody' dangerouslySetInnerHTML={{ __html: overview?.copy }}></p>
                )}
              </Card>
            </Row>
            <div className="divide"></div>
            <Row>
              <Card className="card prereqCard">
                <p className="cardHeading">Prerequisites</p>
                {prerequisite && (
                  <p className='cardBody' dangerouslySetInnerHTML={{ __html: prerequisite?.copy }}></p>
                )}
              </Card>
            </Row>
          </Col>
          <Col sm={24} md={24} lg={1} xl={1}></Col>

          <Col sm={24} md={24} lg={9} xl={9}>
            {outcome && (
              <Fragment>
                <Col sm={24} md={24} lg={24} xl={24}>
                  <Card className="outcomes">
                    <p className="cardHeading">Outcomes</p>
                    <Row className="outcomeRow">
                      <Col sm={24} xs={24} md={24} lg={24} xl={24}>
                        <span className="list-items" dangerouslySetInnerHTML={{ __html: outcome?.copy }}></span>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Fragment>
            )}
          </Col>
        </Row> 
        </div>
        ): ( 
          <div className="overviewSection">
        <Row className="overview">
          <h3 className="h3-heading">WHY SHOULD YOU USE OUR APIs?</h3>
        </Row>
          <Row className='overViewContainer'>
          <Col sm={24} md={24} lg={24} xl={24}>
            <Row>
              <Card className="card overviewCard">
                <p className="cardHeading">Overview</p>
                {filteredApi?.ListAssoc && filteredApi?.ListAssoc[0]?.name === 'Overview' ? (
                  <p className='cardBody' dangerouslySetInnerHTML={{ __html: overview?.copy }}></p>
                ) : ''}
              </Card>
            </Row>
            <div className="divide"></div>
            <Row>
              <Card className="card prereqCard">
                <p className="cardHeading">Prerequisites</p>
                {filteredApi?.ListAssoc && filteredApi?.ListAssoc[1]?.name === 'prerequisite' ? (
                  <p className='cardBody' dangerouslySetInnerHTML={{ __html: prerequisite?.copy }}></p>
                ) : ''}
              </Card>
            </Row>
          </Col>
          </Row>
          </div>
          )
        }
       
      {apiFlowImageMobile && (
      <div className="whysection mobile-only">
        <Row>
          <Col sm={24} md={24} lg={24} xl={24}>
            <h3 className="centerText whyheading">HOW DOES API WORK?</h3>
          </Col>
          <Col sm={24} md={24} lg={24} xl={24}>
            <p className="centerText whydescription">Quoting to Enrollment with Partner integration APIs</p>
          </Col>
        </Row>
        <Row>
          <div>
          <img 
                      className="apiFlowImage" 
                      src={`/public/api/landing/fetch/${apiFlowImageMobile?.name}`}
                    />
          </div>
        </Row>
      </div>
      )}
       {apiFlowImage && (
      <div className="whysection hide-on-mobile">
        <Row>
          <Col sm={24} md={24} lg={24} xl={24}>
            <h3 className="centerText whyheading">HOW DOES API WORK?</h3>
          </Col>
          <Col sm={24} md={24} lg={24} xl={24}>
            <p className="centerText whydescription">Quoting to Enrollment with Partner integration APIs</p>
          </Col>
        </Row>
        <Row>
        <Col sm={24} md={24} lg={24} xl={24}>
          <img 
                      className="apiFlowImage" 
                      src={`/public/api/landing/fetch/${apiFlowImage.name}`}
                    />
          </Col>
        </Row>
      </div>
       )}
      <div className="relatedAPICss">
      {filteredApi?.ListAssoc && state?.data?.relatedApis && state?.data?.relatedApis?.length > 0 && <h1>
          <b>Related APIs</b>
        </h1>}
        {filteredApi?.ListAssoc && state?.data?.relatedApis && (
          <Row gutter={[24, 24]} justify="space-around" align="middle">
          {state?.data?.relatedApis?.map(api => {
            return (
              <Col sm={12} xs={12} md={12} lg={8} xl={8} key={api?.name}>
                <Card className="card__api_list">
                  <div className="container">
                    <div className="api_cards">
                      <img
                        src={require(`./../../common/img/new-public-Ui/${api?.name}.svg`).default}
                        alt="Avatar"
                        className="image"
                      />
                      <p className="api_title">{api?.title}</p>
                    </div>
                    <div className="overlay">
                      <div className="text">
                        <p className="api_title">{api?.title}</p>
                        {api?.description}
                        <p>
                          <a className="moreButton" onClick={() => selectApi(api?.title)}>
                            More
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
        )}
      </div>
    </div>
  );
};

export default ApiDetails;
