import React, { useContext, useState, useEffect } from 'react';
import { Layout, Card, Space, Typography, Row, Col, Button } from 'antd';
import { css } from '@emotion/core';
import FOOTER_BANNER from './../../common/img/footer-banner.png';
import FOOTER_MOBILE_BANNER from './../../common/img/footer-mobile-banner.png';
import images_getStarted from './../../common/img/new-public-Ui/background_getstarted.svg';
import { DangerButton } from 'Client/components/Buttons/DangerButton';
import { AppContext } from './../../Contexts/AppContext';
import { useLocation, useHistory } from 'react-router-dom';

export const FooterCss = css`
  z-index: 1;
  padding: 0px;
  .footer__container {
    width: 100vw;
    object-fit: cover;
  }

  .ant-card-head {
    border-bottom: none;
  }

  .footer__card_register {
    background: transparent;
    text-align: center;
    padding: 5% 0px;
  }

  .api-content {
    color: #ffffff;
  }

  .footer__bottom_section {
    width: 100%;
    height: 100%;
    background: #000000 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    opacity: 1;
    text-align: left;
    color: #fff;
  }

  .footer__card {
    background: transparent;
    padding: 30px;
  }

  .foot-img {
    float: left;
  }

  .link__terms {
    text-align: left;
    font: normal normal normal 14px Lato;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    text-decoration: underline;
  }
  .footer_desc {
    text-align: left;
    font: normal normal normal 14px Lato;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }

  @media (min-width: 320px) {
    .footer__container {
      background-image: url(${images_getStarted});
    }

    .footer__get_started {
      text-align: center;
      font: normal normal bold 28px/36px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }

    .api-content {
      text-align: center;
      font: normal normal normal 16px/22px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }

    .footer__card_register {
      height: 320px;
    }
  }

  @media (min-width: 480px) {
    .footer__container {
      background: url(${images_getStarted});
    }

    .footer__get_started {
      text-align: center;
      font: normal normal bold 28px/36px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }

    .api-content {
      text-align: center;
      font: normal normal normal 16px/22px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
    .footer__card_register {
      height: 320px;
    }
  }
  @media (min-width: 600px) {
    .footer__container {
      background: url(${images_getStarted}) repeat center;
    }

    .footer__get_started {
      text-align: center;
      font: normal normal bold 28px/36px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }

    .api-content {
      text-align: center;
      font: normal normal normal 16px/22px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
    .footer__card_register {
      height: 320px;
    }
  }
  @media (min-width: 801px) {
    .footer__container {
      background: url(${images_getStarted}) repeat;
    }

    .footer__get_started {
      text-align: center;
      font: normal normal bold 36px/44px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }

    .api-content {
      text-align: center;
      font: normal normal normal 22px/32px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
    .footer__card_register {
      height: 420px;
    }
  }
  @media (min-width: 1025px) {
    .footer__container {
      background: url(${images_getStarted}) repeat;
    }

    .footer__get_started {
      text-align: center;
      font: normal normal bold 36px/44px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }

    .api-content {
      text-align: center;
      font: normal normal normal 22px/32px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
    .footer__card_register {
      height: 380px;
    }
  }
  @media (min-width: 1400px) {
    .footer__container {
      background: url(${images_getStarted});
      background-size: 100%;
      height: 453px;
    }

    .footer__get_started {
      text-align: center;
      font: normal normal bold 36px/44px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }

    .api-content {
      text-align: center;
      font: normal normal normal 22px/32px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }

    .footer__card_register {
      height: 450px;
    }
  }

  .second_headline {
    color: white;
    font-family: Elevance Sans;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.25rem;
    text-align: left;

  }

  .first_headline {
    color: white;
    font-family: Lato;
    font-size: 1rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: left;
  }

  .get-started {
    color: #1A3773;
    font-family: Elevance Sans;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.625rem;
    width: 15.2306rem;
    height: 3rem;
    border-radius: 6.25rem;
    background: #FFF;
  }

  .footer__card_registerNew {
    background: transparent;
    text-align: left;
    padding-top: 2%;
    text-align: left;
    margin-left: 15%;
    margin-right: 40%;

    @media(max-width: 800px) {
      margin: 0;
      padding: 10px;
    }
  }
`;

const FooterComponent = () => {
  const [state, setState] = useState({ data: null, status: 'pending' });
  const [showBanner, setShowBanner] = useState(true);
  const { wraSlotList, clientConfig } = useContext(AppContext);
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const footerContent = wraSlotList?.find(data => data?.name === 'partner_register_block');
    setState({ data: { footerContent }, status: 'success' });
    const isRegistrationPathFound = pathname?.split('/')?.includes('register');
    isRegistrationPathFound ? setShowBanner(false) : setShowBanner(true);
  }, [pathname]);
  return (
    <Layout.Footer css={FooterCss}>
      {showBanner  ? (
        <div className="footer__container">
          <Card
            bordered={false}
            className="footer__card_registerNew"
          >
            <Space direction="vertical">
              <div className="first_headline">ANTHEM PARTNER APIs</div>
              <Typography.Paragraph className="second_headline">
                Become an Anthem API partner and simplify client eligibility, enrollment and management with real-time
                connections to Anthem
              </Typography.Paragraph>
              <Button className="get-started" onClick={() => history.push('/register')}>
                Get Started
              </Button>
            </Space>
          </Card>
        </div>
      ) : (
        ''
      )}
      <div className="footer__bottom_section">
        <Card bordered={false} className="footer__card">
          <Row justify="center" align="middle" className="footer__bottom_row">
            <Col span={24}>
              <p className="footer_desc">
                <b>Anthem Blue Cross and Blue Shield</b> is the trade name of: In Colorado: Rocky Mountain Hospital and
                Medical Service, Inc. HMO products underwritten by HMO Colorado, Inc. In Connecticut: Anthem Health
                Plans, Inc. In Indiana: Anthem Insurance Companies, Inc. In Georgia: Blue Cross Blue Shield Healthcare
                Plan of Georgia, Inc. and Community Care Health Plan of Georgia, Inc. In Kentucky: Anthem Health Plans
                of Kentucky, Inc. In Maine: Anthem Health Plans of Maine, Inc. In Missouri (excluding 30 counties in the
                Kansas City area): RightCHOICE® Managed Care, Inc. (RIT), Healthy Alliance® Life Insurance Company
                (HALIC), and HMO Missouri, Inc. RIT and certain affiliates administer non-HMO benefits underwritten by
                HALIC and HMO benefits underwritten by HMO Missouri, Inc. RIT and certain affiliates only provide
                administrative services for self-funded plans and do not underwrite benefits. In Nevada: Rocky Mountain
                Hospital and Medical Service, Inc. HMO products underwritten by HMO Colorado, Inc., dba HMO Nevada. In
                New Hampshire: Anthem Health Plans of New Hampshire, Inc. HMO plans are administered by Anthem Health
                Plans of New Hampshire, Inc. and underwritten by Matthew Thornton Health Plan, Inc. In 17 southeastern
                counties of New York: Anthem HealthChoice Assurance, Inc., and Anthem HealthChoice HMO, Inc. In these
                same counties Anthem Blue Cross and Blue Shield HP is the trade name of Anthem HP, LLC. In Ohio:
                Community Insurance Company. In Virginia: Anthem Health Plans of Virginia, Inc. trades as Anthem Blue
                Cross and Blue Shield, and its affiliate HealthKeepers, Inc. trades as Anthem HealthKeepers providing
                HMO coverage, and their service area is all of Virginia except for the City of Fairfax, the Town of
                Vienna, and the area east of State Route 123. In Wisconsin: Blue Cross Blue Shield of Wisconsin (BCBSWI)
                underwrites or administers PPO and indemnity policies and underwrites the out-of-network benefits in POS
                policies offered by Compcare Health Services Insurance Corporation (Compcare) or Wisconsin Collaborative
                Insurance Corporation (WCIC). Compcare underwrites or administers HMO or POS policies; WCIC underwrites
                or administers Well Priority HMO or POS policies. Independent licensees of the Blue Cross and Blue
                Shield Association. Anthem is a registered trademark of Anthem Insurance Companies, Inc.
                <br />
                <br />
                In California Anthem Blue Cross is the trade name of Blue Cross of California, Inc.Also serving
                California:Anthem Blue Cross Life and Health Insurance Company.In 11 northeastern counties of New York
                Anthem Blue Cross is the trade name of Anthem HealthChoiceAssurance, Inc., and Anthem HealthChoiceHMO,
                Inc., and Anthem Blue Cross HP is the trade name of Anthem HP, LLC.Independent licensees of the Blue
                Cross Association.Anthem is a registered trademark of Anthem Insurance Companies, Inc.
                <br />
                <br />
              </p>
              <a href={clientConfig?.termsAndCondition} target="_blank" rel="noreferrer" className="link__terms">
                {`Terms & Conditions`}
              </a>
            </Col>
          </Row>
        </Card>
      </div>
    </Layout.Footer>
  );
};

export default FooterComponent;
