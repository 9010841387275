import { css } from '@emotion/core';

export const ApiFilterCss = css`
  border: 1px solid #e8e8e8;
  padding: 5px;
  display: flex; 
  flex-Direction: column; 
  width: 350px;
  ul {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding-left: 4px;
  }

  .sectionHeading {
    padding: 10px;
    font-family: Elevance Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
    margin: 10px;
    background: #F9F9F9;
    margin-bottom: 10px;
  }

  .filterTypes {
    margin: 20px 22px;
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    width: 100%;

    .ant-space {
      width: 80%;

      .ant-space-item:nth-child(2) {
        width: 100%;
        .ant-row {
          width: inherit;
        }
      }
    }
  }

  .filterTypes {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .filterTypes input[type='checkbox'] {
    margin-right: 10px;
    transform: scale(1.8);
  }
  .filter-label {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    bottom: .125rem;
  }

  .filter-text {
    flex-grow: 1;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #666666;
  }

  .filter-count {
    ${'' /* margin-left: auto; */}
    font-family: Elevance Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.20000000298023224px;
    text-align: right;
    color: #737373;
  }

  .button-container,
  .link-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .tell_us_button {
    width: 80%;
    background-color: #1354e9; /* Primary color */
    color: white; /* Text color */
    border: none; /* Remove border */
    padding: 10px 20px; /* Padding */
    border-radius: 30px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 16px; /* Font size */
    transition: background-color 0.3s; /* Smooth transition for background color */
    margin: 0 10px;
  }

  .clear_all_button {
    color: #1354e9;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }
  .disabled-button {
  background-color: gray;
  cursor: not-allowed;
}

  .clear_all_button:hover {
    color: #0056b3;
  }
  @media (max-width: 1024px) {
       width: 250px;
  } 

  @media (max-width: 430px) {
       width: 100%;
  } 
`;
