import React, { Fragment, useEffect, useContext, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Card, Divider, Row, Col, Collapse, Space } from 'antd';
import { css } from '@emotion/core';
import { LeftOutlined, DownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import * as _ from 'lodash';

import { AppContext } from './../../Contexts/AppContext';
import headerBackground from './../../common/img/new-public-Ui/header_background.svg';
import Button from 'Client/components/Buttons/Button';

const Panel = Collapse.Panel;

export const greetingCardHeader = css`
  background: #1A3773 url(${headerBackground}) right no-repeat;
  background-position: left 130% center;
  padding: 3em 0 2em;

  @media(max-width: 768px) {
    padding: 4em 0 4em;
  }

  @media(max-width: 540px) {
    padding: 3em 0 3em;
  }
  
  @media(max-width: 430px) {
    padding: 2em 0 2em;
  }

  .ant-card-body {
    padding: 24px 7.5rem;

    @media(max-width: 768px) {
      padding: 15px 24px;
    }
  }

  h1 {
    margin-top: 20px;
    font-size: 30px;
    color: white;
  }

  h2.username {
    font-weight: 300;
    text-transform: capitalize;
    margin-top: 0.5em;
  }
  h3.description {
    font-weight: 300;
    margin-top: 35px;
    background: inherit;
    color: white;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }
  .backToHome {
    color: white;
    font-family: Elevance Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;

    @media(max-width: 768px) {
      font-size: 16px;
      font-weight: 500;
      line-height: 32px;
      text-align: left;
    }
  }
  .headerText {
    font-family: Elevance Sans;
    font-size: 48px;
    font-weight: 600;
    line-height: 60px;
    text-align: left;
    color: #ffffff;

    @media(max-width: 768px) {
      font-family: Elevance Sans;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      text-align: left;
    }
  }
`;

export const cssContent = css`
  background-size: 100% 200px;
  padding-bottom: 4em;
  background: #F7F7F7;


  @media(max-width: 540px) {
    padding: 0;
  }

  @media(max-width: 480px) {
    background: #FFFFFF;
  }
`;

const alreadyVerifiedCss = css`
  margin-top: 100px;

  @media(max-width: 600px) {
    margin-top: 50px;
  }

  .faqContent {
    padding: 0 15rem;
    width: 100vw;

    @media (max-width: 1200px) {
      padding: 0 8rem 4rem 8rem;
    }

    @media (max-width: 930px) {
      padding: 0 4rem 3rem 4rem;
    }

    @media (max-width: 600px) {
      padding: 0 3rem 2rem 3rem;
    }

    @media (max-width: 480px) {
      padding: 0 1rem 2rem 1rem;
    }
  }
  
  .ant-collapse {
    background-color: #fff;
    border: none;
  }

  .ant-card {
    background-color: #f7f7f7;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
    font-family: Elevance Sans;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    padding: 15px;

    @media(max-width: 768px) {
      font-size: 20px;
    }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    font-size: 18px;
    color: #1354e9;
    padding: 20px;
  }

  .ant-result-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 23px;
    line-height: 1.8;
    text-align: center;
    font-family: Montserrat;
    font-weight: 600;
  }

  span.ant-typography {
    font-family: Montserrat;
    font-weight: 500;
  }

  .ant-result-info .ant-result-icon > .anticon {
    color: #286ce2 !important;
  }

  .ant-result-icon > .anticon {
    font-size: 65px;
  }

  .ant-divider {
    border: none;
  }

  .faqDescription {
    margin-left: 6em;
    font-family: Elevance Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #333333;
  }

  .ant-typography + h1.ant-typography,
  .ant-typography + h2.ant-typography,
  .ant-typography + h3.ant-typography,
  .ant-typography + h4.ant-typography {
    margin-top: none !important;
  }

  h1.ant-typography,
  .ant-typography h1 {
    margin-bottom: -0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
    font-size: 20px;
    line-height: 1.23;
  }

  #resend-email-btn {
    border-radius: 10px;
  }

  .collapse-container {
    background: #fff;
    box-shadow: 0px 8px 16px 0px #00000014;

    @media(max-width: 480px) {
      box-shadow: none;
    }
  }

  .ant-collapse-content {
    border-top: none; 
  }

  .questionsCount {
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #333333;

    @media(max-width: 768px) {
      font-weight: 500;
    }

  }

  .expand-button {
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    text-align: right;
    color: #396CE8;
    cursor: pointer;
  }

  .backtoTopRow {
    display: flex;
    justify-content: flex-end;
  }

  .backtoTop{
    background: #37475A;
    width: 137px;
    border-radius: 100px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.8px;
    text-align: center;
    color: #FFFFFF;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    .ant-result-icon span img {
      height: 65px;
    }

    h1.ant-typography,
    .ant-typography h1 {
      font-size: 16px;
    }
    .ant-result-extra .ant-typography {
      font-size: 12px;
    }
  }

  .faq-collapse-header {
    @media(max-width: 540px) {
      .ant-row {
        flex-direction: column-reverse;
      }
    }
  }

  .ant-collapse > .ant-collapse-item:last-of-type {
    @media(max-width: 480px) {
      border-bottom: none;
    }
  }
`;

const FAQComponent = () => {
  const [state, setState] = useState({ data: {}, status: 'pending', error: null });
  const [expandList, setExpandList] = useState([]);
  const [expandAll, setExpandAll] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const { wraSlotList } = useContext(AppContext);
  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      window.scrollTo(0, 0);
      getOwcsContent();
    } 
  }, [expandList]);

  const history = useHistory();

  const backToHome = () => {
    history.push('/explore');
  };

  const getOwcsContent = () => {
    const frequently_asked_questions = wraSlotList?.find(data => data?.name === 'frequently_asked_questions') ?? [];

    setState({
      data: {
        frequently_asked_questions,
      },
      status: wraSlotList ? 'success' : 'error',
    });

    setFaqList(frequently_asked_questions?.ListAssoc || []);
  };

  const onExpandAll = (isExpandAll) => {
    const localExpandList = [];
    setExpandAll(!isExpandAll);
    if (!isExpandAll) {
      faqList.map((faq, index) => {
        localExpandList[index] = index + 1;
      })
    }
    setExpandList(localExpandList);
  }

  const onChange = (key) => {
    setExpandList([...key]);
  };


  return (
    <Fragment>
      <div css={cssContent}>
        <Card css={greetingCardHeader} bordered={false}>
          <div className="backToHome">
            <a onClick={() => backToHome()} style={{ color: '#fff' }}>
              <Space>
                <LeftOutlined />
                Back to Home
              </Space>
            </a>
          </div>
          <div>
            <Typography className="headerText">Frequently asked questions?</Typography>
          </div>
        </Card>
        <div css={alreadyVerifiedCss}>
          <Row gutter={[0,24]} justify="space-between" className="faqContent">
            <Col xs={24} sm={24} md={24} lg={24} className="faq-collapse-header">
              <Row>
                <Col span={20}>
                  <span className="questionsCount">
                    Showing {faqList.length} Questions and Answers
                    {/* <a>Expand All</a> */}
                  </span>
                </Col>
                <Col xs={{offset: 18, span: 6}} sm={{offset: 19, span: 5}} md={{offset: 0, span:3}} lg={3} xl={3} 
                  className="expand-button" 
                  onClick={() => onExpandAll(expandAll)} 
                >
                  {
                    !expandAll ?
                    <span>
                      Expand All
                    </span> :
                    <span>
                      Collapse All
                    </span>
                  }
                </Col>
              </Row>
            </Col>
            <Col span={24} className="collapse-container">
              <Collapse
                activeKey={expandList}
                onChange={onChange}
                expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
                className="faqCard"
              >
                {faqList.length > 0 ? (
                  faqList.map((faq, index) => (
                    <Panel header={faq.heading} key={index + 1}>
                      <p className="faqDescription">{faq.description}</p>
                    </Panel>
                  ))
                ) : (
                  <div>No FAQs available.</div>
                )}
              </Collapse>
            </Col>
            {
              faqList.length > 0 && (
              <Col offset={21} span={3} className="backtoTopRow">
                <Button className="backtoTop" onClick={() => window.scrollTo(0, 0)}>
                  <Space>
                    <ArrowUpOutlined />
                    <span>Back to Top</span>
                  </Space>
                </Button>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default FAQComponent;
