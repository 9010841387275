import { css } from '@emotion/core';
export const AppLayoutCss = css`
  .footer {
    position: absolute;
  }
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .ant-layout-header {
      padding: 5px 10px;
      height: 90px;
      box-shadow: 1px 1px lightgrey;
      box-sizing: border-box;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .ant-layout-header {
      padding: 12px 20px;
      height: 90px;
      box-shadow: 1px 1px lightgrey;
      box-sizing: border-box;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .ant-layout-header {
      padding: 12px 24px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 912px) {
    .ant-layout-header {
      padding: 12px 24px;
    }
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .ant-layout-header {
      padding: 10px 24px;
      height: 90px;
      box-shadow: 1px 1px lightgrey;
      box-sizing: border-box;
    }
  }
`;

export const AppContentCss = css`
  margin-top: 75px;
  background-color: #fff;
  
  @media(min-width: 1100px) {
    margin-top: 3em; 
  }

  @media(max-width: 900px) {
    margin-top: 5em; 
  }

  @media(max-width: 600px) {
    margin-top: 6em;
  }
`;
