import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query'
import { node } from 'prop-types';
import CSSCacheProvider from './CSSCacheProvider';
import { AppContentProvider } from './AppContext';

const basename = '/public';

const queryConfig = {
  useErrorBoundary: false,
  refetchAllOnWindowFocus: false,
  refetchOnMount: false,
  retry: false,
};


const queryClient = new QueryClient({
    defaultOptions: {
    queries: queryConfig
  }
});

function AppProviders({ children }) {
  return (
    <QueryClientProvider client={queryClient} config={queryConfig}>
      <CSSCacheProvider value={{ key: 'app' }}>
        <Router basename={basename}>
          <AppContentProvider>{children}</AppContentProvider>
        </Router>
      </CSSCacheProvider>
    </QueryClientProvider>
  );
}

AppProviders.propTypes = {
  children: node,
};

export { AppProviders };
